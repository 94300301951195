import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconUserTag: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.0013C6 3.71264 7.04467 2.66797 8.33333 2.66797C9.622 2.66797 10.6667 3.71264 10.6667 5.0013C10.6667 6.28997 9.622 7.33463 8.33333 7.33463C7.04467 7.33463 6 6.28997 6 5.0013ZM8.33333 0.667969C5.9401 0.667969 4 2.60807 4 5.0013C4 7.39454 5.9401 9.33463 8.33333 9.33463C10.7266 9.33463 12.6667 7.39454 12.6667 5.0013C12.6667 2.60807 10.7266 0.667969 8.33333 0.667969ZM6.66667 9.83463C3.8132 9.83463 1.5 12.1478 1.5 15.0013V15.8346C1.5 16.8472 2.32081 17.668 3.33333 17.668H11.1371L10.2327 16.7635C9.92591 16.4567 9.70706 16.0785 9.59232 15.668H3.5V15.0013C3.5 13.2524 4.91776 11.8346 6.66667 11.8346H9.70866C10.0949 10.9518 10.9761 10.335 12.0014 10.335H12.2208C11.5481 10.0142 10.795 9.83463 10 9.83463H6.66667Z"
        fill="#384157"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9308 18.9222L18.0892 16.7638C18.4152 16.4378 18.4152 15.9093 18.0892 15.5833L14.7503 12.2445C14.5938 12.0879 14.3815 12 14.1601 12H12.0017C11.5407 12 11.167 12.3737 11.167 12.8347V14.9931C11.167 15.2145 11.2549 15.4268 11.4115 15.5833L14.7503 18.9222C15.0763 19.2482 15.6048 19.2482 15.9308 18.9222ZM12.8364 14.2955C13.1822 14.2955 13.4625 14.0152 13.4625 13.6694C13.4625 13.3237 13.1822 13.0434 12.8364 13.0434C12.4907 13.0434 12.2104 13.3237 12.2104 13.6694C12.2104 14.0152 12.4907 14.2955 12.8364 14.2955Z"
        fill="#384157"
      />
    </svg>
  );
};
