import { RoundedBox } from "../../../components";
import { AssistantProfileHeader } from "./AssistantProfileHeader";
import { AssistantProfile } from "./AssistantProfile";
import { AssistantInstructions } from "./AssistantInstructions";
import { AssistantToolkit } from "./AssistantToolkit/index";

export const AssistantProfileInner = () => {
  return (
    <RoundedBox variant="default" className="h-full">
      <div className="mb-7">
        <AssistantProfileHeader />
      </div>
      <p className="body-1 mb-5">
        In this section, you're given keys to customize your AI assistant to
        best represent your business and process your clients. If you are
        curious,{" "}
        <a
          href="https://mychatbotapp.notion.site/Fundamentals-bd181032e5934f528b59e7ac69072c9c"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          here
        </a>{" "}
        is a short read on fundamentals.
      </p>
      <div className="mb-7">
        <AssistantProfile />
      </div>
      <div className="mb-7">
        <AssistantToolkit />
      </div>
      <div>
        <AssistantInstructions />
      </div>
    </RoundedBox>
  );
};
