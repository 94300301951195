import React, { FC, Dispatch, SetStateAction, ChangeEvent } from "react";
import { SITE_URL_REGEX } from "../../../constants/validationScheme";
import { Button, Modal } from "../../../redesign/components";
import { FcPlus } from "react-icons/fc";

interface SiteLinkModalProps {
  open: boolean;
  toggleOpen: () => void;
  siteLinks: string[];
  setSiteLinks: Dispatch<SetStateAction<string[]>>;
  onConnect: () => void;
  isButtonDisabled: boolean;
}

export const SiteLinkModal: FC<SiteLinkModalProps> = ({
  open,
  toggleOpen,
  siteLinks,
  setSiteLinks,
  onConnect,
  isButtonDisabled,
}) => {
  const isValid = siteLinks.some((link) => SITE_URL_REGEX.test(link));

  return (
    <Modal
      open={open}
      toggleOpen={toggleOpen}
      title="Connect Site link"
      actions={
        <div className="flex justify-center">
          <Button
            variant="primary"
            onClick={onConnect}
            disabled={!isValid || isButtonDisabled}
            className="w-1/3  mx-auto"
          >
            Connect
          </Button>
        </div>
      }
    >
      <div className="flex flex-col w-full gap-8">
        {siteLinks.map((link, index) => (
          <div key={index} className="w-full flex flex-col">
            <label className="text-xl font-semibold mb-3">{`Website URL ${
              index + 1
            }`}</label>
            <input
              type="url"
              value={link}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newLinks = [...siteLinks];
                newLinks[index] = e.target.value;
                setSiteLinks(newLinks);
              }}
              className="w-full border border-gray-300 rounded-lg p-3 text-lg"
              placeholder="https://yourwebsite.com"
            />
            {link !== "" && !SITE_URL_REGEX.test(link) && (
              <span className="text-red-500 text-sm mt-2">Invalid URL</span>
            )}
          </div>
        ))}
        {siteLinks.length < 5 && (
          <FcPlus
            onClick={() => setSiteLinks([...siteLinks, ""])}
            size={25}
            className="mx-auto cursor-pointer"
          />
        )}
      </div>
    </Modal>
  );
};
