import React from "react";
import { ClientStatus } from "./ClientStatus";
import { MessagesByChannel } from "./MessagesByChannel";
import { MessageUsage } from "./MessageUsage";
import { OrdersByChannel } from "./OrdersByChannel";
import { PlatformUsage } from "./PlatformUsage";
import { RevenueByChannel } from "./RevenueByChannel";

export const Charts = () => {
  return (
    <div className="column w-full gap-[1.5rem] md:flex-row md:[&>div]:w-[calc(50%-(1.5rem/2))]">
      <div className="column gap-[1.5rem]">
        <RevenueByChannel />
        <div className="column gap-[1.5rem] h-full [&>div]:h-auto [&>div]:min-h-full md:[&>div]:w-[calc(50%-(1.5rem/2))] md:flex-row">
          <OrdersByChannel />
          <MessagesByChannel />
        </div>
      </div>
      <div className="column gap-[1.5rem]">
        <ClientStatus />
        <div className="column gap-[1.5rem] h-full [&>div]:h-auto [&>div]:min-h-full md:[&>div]:w-[calc(50%-(1.5rem/2))] md:flex-row">
          <MessageUsage />
          <PlatformUsage />
        </div>
      </div>
    </div>
  );
};
