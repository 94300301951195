import React, { FC, memo } from "react";
import { hours } from "./utils";
import { Button } from "@material-tailwind/react";

type Props = {
  onClick: (hour: string) => void;
};

export const TimeBody: FC<Props> = memo(({ onClick }) => {
  return (
    <ul className="column gap-4 max-h-[21rem] overflow-y-auto pr-2">
      {hours.map((hour) => (
        <li key={hour} className="m-0 before:hidden">
          <Button
            variant="text"
            className="min-w-full flex items-start"
            color="blue-gray"
            onClick={() => onClick(hour)}
          >
            <span className="body-2 font-normal">{hour}</span>
          </Button>
        </li>
      ))}
    </ul>
  );
});
