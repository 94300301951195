import React, { FC, PropsWithChildren, ReactNode } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  DialogProps,
} from "@material-tailwind/react";
import classNames from "classnames";

import { IoClose } from "react-icons/io5";
import { useWindowSize } from "react-use";
import { screenSizes } from "../../constants";

type Props = {
  title?: ReactNode;
  actions?: ReactNode;
  open?: boolean;
  toggleOpen?: () => void;
  titleUnderline?: boolean;
  classNameBody?: string;
  classNameWrapper?: string;
} & Required<PropsWithChildren> &
  Omit<DialogProps, "title" | "handler"> &
  Required<PropsWithChildren>;

export const Modal: FC<Props> = ({
  title,
  actions,
  children,
  open = false,
  toggleOpen,
  titleUnderline = false,
  classNameBody = "",
  classNameWrapper = "",
  ...props
}) => {
  const { width: windowWidth } = useWindowSize();

  const handleToggleOpenOpen = () => {
    toggleOpen && toggleOpen();
  };

  const hasTitle = Boolean(title);
  const hasActions = Boolean(actions);

  return (
    <Dialog
      open={open}
      handler={handleToggleOpenOpen}
      size={props.size || (windowWidth > screenSizes.sm ? "lg" : "xxl")}
      className={classNames("rounded-[1.8rem] p-6", classNameWrapper)}
      animate={{
        mount: { y: 0 },
        unmount: { y: 40 },
      }}
    >
      <DialogHeader
        className={classNames("p-0 mb-5 items-center", {
          "pb-4 border-b border-gray-300": titleUnderline,
          "justify-between": hasTitle,
          "justify-end": !hasTitle,
        })}
      >
        {title && title}
        <IconButton
          color="blue-gray"
          size="lg"
          variant="text"
          onClick={handleToggleOpenOpen}
        >
          <IoClose size="2rem" className="text-gray-500" />
        </IconButton>
      </DialogHeader>
      <DialogBody
        className={classNames(
          "p-0",
          {
            "mb-16": hasActions,
          },
          classNameBody
        )}
      >
        {children}
      </DialogBody>
      {actions && <DialogFooter className="block p-0">{actions}</DialogFooter>}
    </Dialog>
  );
};
