import React, { FC, ReactNode, useEffect } from "react";
import {
  Tab,
  TabPanel,
  Tabs as MTTabs,
  TabsBody,
  TabsHeader,
  useTabs,
} from "@material-tailwind/react";
import classNames from "classnames";

type TabType = {
  label: string;
  value: string;
  body: ReactNode;
};

type Props = {
  data: TabType[];
  classNameTabsHeader?: string;
  classNameTabsBody?: string;
  onChange?: (id: string | number) => void;
};

export const Tabs: FC<Props> = ({
  data,
  classNameTabsHeader = "",
  classNameTabsBody = "",
  onChange,
}) => {
  return (
    <MTTabs className={classNames("")} value={data[0]?.value}>
      <div
        className={classNames(
          "flex flex-col gap-6 mb-5 [&>nav>ul]:border-b [&>nav>ul]:border-b-gray-300 [&>nav>ul]:rounded-none [&>nav>ul]:p-0"
        )}
      >
        <TabsHeaderComponent
          onChange={onChange}
          data={data}
          classNameTabsHeader={classNameTabsHeader}
        />
      </div>
      <TabsBody className={classNameTabsBody}>
        {data.map(({ value, body }) => (
          <TabPanel className="p-0" key={value} value={value}>
            {body}
          </TabPanel>
        ))}
      </TabsBody>
    </MTTabs>
  );
};

const TabsHeaderComponent: FC<{
  data: TabType[];
  classNameTabsHeader: string;
  onChange?: (id: string | number) => void;
}> = ({ data, classNameTabsHeader, onChange }) => {
  const { state } = useTabs();

  useEffect(() => {
    if (onChange) {
      onChange(state.active);
    }
  }, [onChange, state.active]);

  const baseTabsNavigationStyles = "mx-auto bg-transparent gap-3";

  const baseTabsStyle =
    "before:hidden m-0 [&>div.absolute]:top-[96%] [&>div.absolute]:shadow-none [&>div.absolute]:rounded-none transition-all duration-300 text-gray-500 text-[1rem] font-normal leading-[140%]";

  return (
    <TabsHeader
      className={classNames(baseTabsNavigationStyles, classNameTabsHeader)}
    >
      {data.map(({ label, value }) => (
        <Tab
          className={classNames(baseTabsStyle, {
            "[&>div.absolute]:bg-purple [&>div.absolute]:h-[2px] text-purple":
              state.active === value,
          })}
          key={value}
          value={value}
        >
          <span className="text-2">{label}</span>
        </Tab>
      ))}
    </TabsHeader>
  );
};
