import React, { FC, useState } from "react";
import { Checkbox } from "../../../components";
import { handleChangeCheckboxHelper } from "../../../utils";
import classNames from "classnames";
import { Button } from "@material-tailwind/react";
import { IconEdit, IconTrash } from "../../../assets";
import { CheckboxValues } from "../../../types";

type Props = {
  checkboxes: CheckboxValues[];
  withDropdown?: boolean;
};

export const CheckboxesBody: FC<Props> = ({
  checkboxes,
  withDropdown = false,
}) => {
  const [checked, setChecked] = useState<string[]>([]);

  return (
    <div className="column gap-4">
      {checkboxes.map(({ value, label }, index, parrentArray) => (
        <div key={value}>
          <Checkbox
            label={label}
            value={value}
            onChange={handleChangeCheckboxHelper(setChecked)}
            checked={checked.includes(value)}
            classNameMenu={classNames("!p-3", {
              "top-auto bottom-[110%]":
                index === parrentArray.length - 1 ||
                index === parrentArray.length - 2,
            })}
            dropdown={
              withDropdown && (
                <div className="w-full column gap-0">
                  <Button className="flex items-center justify-between gap-2 max-w-full p-2 normal-case shadow-none">
                    <span className="text-gray-400 text-[1rem] font-medium ">
                      Edit
                    </span>
                    <span>
                      {/* TODO: to change icon from react-icons package */}
                      <IconEdit size="1.5rem" />
                    </span>
                  </Button>
                  <Button className="flex items-center justify-between gap-2 max-w-full p-2 normal-case shadow-none">
                    <span className="text-gray-400 text-[1rem] font-medium ">
                      Delete
                    </span>
                    <span>
                      {/* TODO: to change icon from react-icons package */}
                      <IconTrash size="1.5rem" />
                    </span>
                  </Button>
                </div>
              )
            }
          />
        </div>
      ))}
    </div>
  );
};
