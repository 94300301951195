import React, { FC } from "react";
import { IconProps } from "../../types";
import { colors } from "../../constants";

export const IconAssistants: FC<IconProps> = ({
  width = 14,
  height = 18,
  color = colors["gray-500"],
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66663 10.6667H5.33329C3.03211 10.6667 1.16663 12.5321 1.16663 14.8333V15.6667C1.16663 16.1269 1.53972 16.5 1.99996 16.5H12C12.4602 16.5 12.8333 16.1269 12.8333 15.6667V14.8333C12.8333 12.5321 10.9678 10.6667 8.66663 10.6667Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M6.99996 8.16667C8.84091 8.16667 10.3333 6.67428 10.3333 4.83333C10.3333 2.99238 8.84091 1.5 6.99996 1.5C5.15901 1.5 3.66663 2.99238 3.66663 4.83333C3.66663 6.67428 5.15901 8.16667 6.99996 8.16667Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
