import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useUpdateFilters = ({
  appliedAssistants = [],
  appliedChannels = [],
  appliedFunnelStatuses = [],
  appliedTimeRange = null,
  appliedSearchValue = "",
  appliedManagers = [],
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateFiltersInQuery = useCallback(() => {
    const params = new URLSearchParams();

    // Assistants
    if (appliedAssistants.length > 0) {
      const ids = appliedAssistants.map((a) => a.value).join(",");
      params.set("assistants", ids);
    } else {
      params.delete("assistants");
    }

    // Channels
    if (appliedChannels.length > 0) {
      const channels = appliedChannels.map((c) => c.value).join(",");
      params.set("channels", channels);
    } else {
      params.delete("channels");
    }

    // Funnel statuses
    if (appliedFunnelStatuses.length > 0) {
      const statuses = appliedFunnelStatuses.map((f) => f.value).join(",");
      params.set("funnel", statuses);
    } else {
      params.delete("funnel");
    }

    // Managers statuses
    if (appliedManagers.length > 0) {
      const managers = appliedManagers.map((m) => m.value).join(",");
      params.set("managers", managers);
    } else {
      params.delete("managers");
    }

    // Time range
    if (appliedTimeRange) {
      if (appliedTimeRange.value === "custom") {
        const { startDate, endDate } = appliedTimeRange;
        params.set("timeRange", `custom:${startDate}_${endDate}`);
      } else {
        params.set("timeRange", `${appliedTimeRange.value}days`);
      }
    } else {
      params.delete("timeRange");
    }

    // Search
    if (appliedSearchValue) {
      params.set("search", appliedSearchValue);
    } else {
      params.delete("search");
    }

    setSearchParams(params, { replace: true });
  }, [
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedSearchValue,
    appliedManagers,
    setSearchParams,
  ]);

  return updateFiltersInQuery;
};
