import React, { useState, useRef, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { GoTrash } from "react-icons/go";
import { DeletePopup } from "../popups/DeletePopup";
import styles from "./CustomDropdown.module.scss";

export interface Option {
  value: string;
  label: string;
  createdAt?: string;
}

interface CustomDropdownProps {
  label?: string;
  initialOptions: Option[];
  value?: Option | null;
  onItemAdd?: (value: string) => void;
  onItemEdit?: (oldValue: string, newValue: string) => void;
  onItemDelete?: (value: string, createdAt: string) => Promise<void>;
  onChange?: (option: Option | null) => void;
  placeholder?: string;
}

export const CustomDropdown = ({
  label,
  initialOptions,
  value,
  onItemAdd,
  onItemEdit,
  onItemDelete,
  onChange,
  placeholder = "Select an option",
}: CustomDropdownProps) => {
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [selected, setSelected] = useState<Option | null>(value || null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showNewItemInput, setShowNewItemInput] = useState<boolean>(false);
  const [newItemValue, setNewItemValue] = useState<string>("");
  const [editingItemValue, setEditingItemValue] = useState<string>("");
  const [editingItemIndex, setEditingItemIndex] = useState<number | null>(null);

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState<Option | null>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    setSelected(value || null);
  }, [value]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
        setShowNewItemInput(false);
        setEditingItemIndex(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const handleOptionClick = (option: Option) => {
    if (editingItemIndex !== null) return;
    setSelected(option);
    onChange?.(option);
    setIsOpen(false);
    setShowNewItemInput(false);
  };

  const handleAddNewItem = () => {
    if (!newItemValue.trim()) return;
    const newOption: Option = {
      value: newItemValue.trim(),
      label: newItemValue.trim(),
      createdAt: new Date().toISOString(),
    };
    setOptions((prev) => [...prev, newOption]);
    onItemAdd?.(newItemValue.trim());
    setSelected(newOption);
    onChange?.(newOption);
    setNewItemValue("");
    setShowNewItemInput(false);
    setIsOpen(false);
  };

  const startEditItem = (index: number) => {
    setEditingItemIndex(index);
    setEditingItemValue(options[index].label);
  };

  const cancelEditItem = () => {
    setEditingItemIndex(null);
    setEditingItemValue("");
  };

  const confirmEditItem = async (index: number) => {
    const oldValue = options[index].value;
    const newValue = editingItemValue.trim();
    if (!newValue) return;
    onItemEdit?.(oldValue, newValue);
    setOptions((prev) => {
      const updated = [...prev];
      updated[index] = {
        value: newValue,
        label: newValue,
        createdAt: options[index].createdAt,
      };
      return updated;
    });
    if (selected?.value === oldValue) {
      const updatedOption = {
        value: newValue,
        label: newValue,
        createdAt: options[index].createdAt,
      };
      setSelected(updatedOption);
      onChange?.(updatedOption);
      setEditingItemIndex(null);
      setEditingItemValue("");
    }
  };

  const confirmDelete = (index: number) => {
    setDeleteItem(options[index]);
    setIsDeletePopupOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    if (!deleteItem) return;

    try {
      await onItemDelete?.(deleteItem.value, deleteItem.createdAt || "");
      setOptions((prev) =>
        prev.filter((item) => item.value !== deleteItem.value)
      );
      if (selected?.value === deleteItem.value) {
        setSelected(null);
        onChange?.(null);
      }
    } catch (err) {
      console.error("Error deleting item:", err);
    } finally {
      setIsDeletePopupOpen(false);
      setDeleteItem(null);
    }
  };

  return (
    <div className={styles.customDropdown} ref={wrapperRef}>
      <div className={styles.title} onClick={() => setIsOpen((prev) => !prev)}>
        {selected ? selected.label : placeholder}
      </div>
      {isOpen && (
        <div className={styles.optionsContainer}>
          {options.length === 0 && !showNewItemInput && (
            <p className={styles.noItemsText}>{`No ${label} yet`}</p>
          )}
          {options.map((opt, index) => {
            const isEditing = editingItemIndex === index;
            return (
              <div
                className={styles.customOption}
                key={opt.value + index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(opt);
                }}
              >
                {!isEditing ? (
                  <>
                    <input
                      type="checkbox"
                      checked={selected?.value === opt.value}
                      onChange={() => handleOptionClick(opt)}
                    />
                    <label>{opt.label}</label>
                    <div className={styles.iconsWrapper}>
                      <button
                        className={styles.editButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          startEditItem(index);
                        }}
                      >
                        <FiEdit size={20} color={"#7581a0"} />
                      </button>
                      <button
                        className={styles.deleteButton}
                        onClick={(e) => {
                          e.stopPropagation();

                          confirmDelete(index);
                        }}
                      >
                        <GoTrash size={20} color={"#7581a0"} />
                      </button>
                    </div>
                  </>
                ) : (
                  <div
                    className={styles.editBlock}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="text"
                      value={editingItemValue}
                      onChange={(e) => setEditingItemValue(e.target.value)}
                      autoFocus
                    />
                    <div className={styles.editActions}>
                      <button
                        className={styles.saveButton}
                        onClick={() => confirmEditItem(index)}
                        disabled={!editingItemValue.trim()}
                      >
                        Save
                      </button>
                      <button onClick={cancelEditItem}>Cancel</button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div
            className={styles.addNewBlock}
            onClick={(e) => e.stopPropagation()}
          >
            {!showNewItemInput && (
              <button
                className={styles.addNewButton}
                onClick={() => setShowNewItemInput(true)}
              >
                {`Add ${label}`}
              </button>
            )}
            {showNewItemInput && (
              <div className={styles.addNewItemWrapper}>
                <input
                  type="text"
                  value={newItemValue}
                  onChange={(e) => setNewItemValue(e.target.value)}
                  className={styles.addNewInput}
                />
                <button
                  className={styles.confirmAddButton}
                  disabled={!newItemValue.trim()}
                  onClick={handleAddNewItem}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <DeletePopup
        isOpen={isDeletePopupOpen}
        setIsOpen={setIsDeletePopupOpen}
        title={`Are you sure you want to delete ${label} "${deleteItem?.label}"?`}
        onDelete={handleDeleteConfirmed}
      />
    </div>
  );
};
