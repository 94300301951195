import { useState, useCallback, useEffect } from "react";
import supabase from "../../supabase";

interface ManagerItem {
  id: string;
  full_name?: string;
  phone?: string;
}

// Three interfaces that display the structure of tables
interface LabelItem {
  label_name: string; // in the `labels` table
  created_at: string;
}
interface FunnelStatusItem {
  status_name: string; // in the `funnel_statuses` table
  created_at: string;
}
interface ClientTypeItem {
  type_name: string; // in the `client_types` table
  created_at: string;
}

export interface Option {
  value: string; // label_name / status_name / type_name is now stored here
  label: string;
  createdAt?: string;
}

export function useReferenceData(accountId: string | undefined) {
  const [managersOptions, setManagersOptions] = useState<Option[]>([]);
  const [labelsOptions, setLabelsOptions] = useState<Option[]>([]);
  const [funnelStatusOptions, setFunnelStatusOptions] = useState<Option[]>([]);
  const [clientTypeOptions, setClientTypeOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadReferenceData = useCallback(async () => {
    if (!accountId) return;

    try {
      setIsLoading(true);

      const [managersResponse, labelsResponse, funnelResponse, typesResponse] =
        await Promise.all([
          supabase
            .from("users")
            .select("id, full_name, phone")
            .eq("account_id", accountId)
            .eq("role", "moderator"),
          supabase.from("labels").select("*").eq("account_id", accountId),
          supabase
            .from("funnel_statuses")
            .select("*")
            .eq("account_id", accountId),
          supabase.from("client_types").select("*").eq("account_id", accountId),
        ]);

      if (managersResponse.data) {
        const mappedManagers = managersResponse.data.map((m: ManagerItem) => ({
          value: m.id,
          label: m.full_name || m.phone || "Moderator",
        }));
        setManagersOptions(mappedManagers);
      }

      if (labelsResponse.data) {
        const mappedLabels = labelsResponse.data.map((l: LabelItem) => ({
          value: l.label_name,
          label: l.label_name,
          createdAt: l.created_at,
        }));
        setLabelsOptions(mappedLabels);
      }

      if (funnelResponse.data) {
        const mappedFunnel = funnelResponse.data.map((f: FunnelStatusItem) => ({
          value: f.status_name,
          label: f.status_name,
          createdAt: f.created_at,
        }));
        setFunnelStatusOptions(mappedFunnel);
      }

      if (typesResponse.data) {
        const mappedTypes = typesResponse.data.map((t: ClientTypeItem) => ({
          value: t.type_name,
          label: t.type_name,
          createdAt: t.created_at,
        }));
        setClientTypeOptions(mappedTypes);
      }
    } catch (error) {
      console.error("Error loading reference data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    loadReferenceData();
  }, [loadReferenceData, accountId]);

  return {
    managersOptions,
    labelsOptions,
    funnelStatusOptions,
    clientTypeOptions,
    isLoading,
    loadReferenceData,

    setLabelsOptions,
    setFunnelStatusOptions,
    setClientTypeOptions,
  };
}
