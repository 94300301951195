import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./ClientCard.module.scss";
import ActionMenu from "./components/ActionMenu";
import { IoIosRefresh } from "react-icons/io";
import { FaArrowLeft, FaChevronLeft } from "react-icons/fa6";
import company from "../../../../../assets/img/company.png";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../../store/slices/authSlice";
import { useResetChatHistoryMutation } from "../../../../../store/api";
import supabase from "../../../../../supabase";
import ClientCardInfo from "./components/ClientCardInfo";
import ClientCardForm from "./components/ClientCardForm";
import { useChatContext } from "../../context/ChatContextProvider";
import {
  fetchClients,
  listenToClientChanges,
} from "../../../../../store/slices/clientsSlice";

const ClientCard = ({ isShowClientCard, setIsShowClientCard }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { clientId, pageId } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  const clientsStatus = useSelector((state) => state.clients.status);

  const [clientCardData, setClientCardData] = useState(null);
  const { setIsNeedUpdateChatList } = useChatContext();

  const [resetChatHistory] = useResetChatHistoryMutation();
  const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);

  const getClientData = useCallback(async () => {
    if (!user?.account_id || !clientId) return;

    try {
      const clientData = clients.find(
        (client) => String(client.client_id) === String(clientId)
      );

      if (!clientData) {
        console.warn("ClientData not found for clientId:", clientId);
      }

      setClientCardData(clientData);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setClientCardData(null);
    }
  }, [clientId, clients, user?.account_id]);

  const getClientDataWithLoading = useCallback(async () => {
    setLoading(true);
    await getClientData();
    setLoading(false);
  }, [getClientData]);

  useEffect(() => {
    if (clientsStatus === "succeeded") {
      getClientDataWithLoading();

      const intervalId = setInterval(async () => {
        await getClientData();
      }, 20000); // 20 секунд інтервал

      return () => clearInterval(intervalId);
    }
  }, [clientsStatus, getClientDataWithLoading, getClientData, clients]);

  useEffect(() => {
    if (clientsStatus === "idle") {
      dispatch(fetchClients(user.account_id));
    }
  }, [clientsStatus, dispatch, user.account_id]);

  useEffect(() => {
    if (clientId && user) {
      // Підписка на зміни клієнтів
      const unsubscribeClients = dispatch(
        listenToClientChanges(user.account_id)
      );

      return () => {
        unsubscribeClients();
      };
    }
  }, [clientId, user.account_id, dispatch]);

  const options = [
    {
      label: "Reset user input flow",
      action: () => {
        if (clientCardData) {
          resetChatHistory({
            account_id: clientCardData.account_id,
            client_id: clientCardData.client_id,
            page_id: pageId || null,
          });
          setTimeout(() => {
            setIsNeedUpdateChatList(true);
          }, 500);
        }
      },
      icon: <IoIosRefresh size={16} className={styles.icon} />,
    },
  ];

  // Logic for changing the icon depending on the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderIcon = () => {
    if (windowWidth >= 667) {
      return <FaArrowLeft size={20} />;
    } else {
      return <FaChevronLeft size={30} />;
    }
  };

  return (
    <>
      <div className={styles.toggle}>
        <button
          className={classNames(styles.btn, {
            [styles.rotated]: isShowClientCard,
          })}
          onClick={() => {
            setIsShowClientCard(!isShowClientCard);
          }}
        >
          {renderIcon()}
        </button>
      </div>

      {loading ? (
        <div
          className={classNames(styles.container, {
            [styles.hide]: !isShowClientCard,
            [styles.loading]: true,
          })}
        >
          <p>Loading User Profile...</p>
        </div>
      ) : (
        clientCardData && (
          <div
            className={classNames(styles.container, {
              [styles.hide]: !isShowClientCard,
            })}
            ref={containerRef}
          >
            <div className={styles.header}>
              {/*  <h2>{clientCardData.full_name}</h2> */}
              <img src={company} alt="Client name icon" />
              <h2>Detailed Info</h2>
              <ActionMenu options={options} />
            </div>
            <div className={styles.content}>
              <ClientCardInfo clientCardData={clientCardData} />

              {/*          <div className={styles.divider}></div> */}
              <ClientCardForm
                clientCardData={clientCardData}
                setClientCardData={setClientCardData}
              />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ClientCard;
