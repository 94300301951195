import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Title } from "../../../../components";
import { useAppDispatch, useReduxStore } from "../../../../hooks";
import { fetchSubscriptionByEmail } from "../../../../../store/slices/subscription";
import { tokensPerMessage } from "../../../../../constants/message";
import { PaymentModal } from "./PaymentModal";

export const GeneralStatisticHeader = memo(() => {
  const { subscription, auth } = useReduxStore();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const toggleOpenPaymentModal = useCallback(
    () => setOpen((prev) => !prev),
    []
  );

  useEffect(() => {
    if (!auth.user?.email) return;

    dispatch(fetchSubscriptionByEmail(auth.user.email));
  }, [auth.user?.email, dispatch]);

  const messagesLeft = useMemo(
    () => Math.floor(subscription.data?.tokens_left / tokensPerMessage) || 0,
    [subscription.data?.tokens_left]
  );

  return (
    <>
      <div className="column md:flex-row justify-between md:items-center">
        <Title text="General statistic" component="h2" />
        <div className="flex items-center justify-between md:gap-4">
          <p className="text-[1.2rem] text-light-black2">
            {messagesLeft} Messages Left
          </p>
          <Button
            className="w-auto [&>span]:normal-case"
            onClick={toggleOpenPaymentModal}
          >
            TOP UP Messeges
          </Button>
        </div>
      </div>
      <PaymentModal open={open} toggleOpen={toggleOpenPaymentModal} />
    </>
  );
});
