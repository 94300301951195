import { FormikValues } from "formik";
import { DAY_OF_THE_WEEK } from "../../../../../../constants/configuration";
import { dequal } from "dequal";
import { getDisabledFromikRules } from "../../../../../utils";

export type InitialValues = {
  days: FormattedDays[];
  timezone: string;
  timeFrom: string;
  timeTo: string;
};

export type FormattedDays = {
  label: Days;
  value: {
    start: string | null;
    end: string | null;
  };
};

export const getFormattedDays = (days?: BotTimeDays) =>
  Object.entries(days || {}).map(([key, value]) => ({
    label: key,
    value: value,
  }));

export const getSelectedDays = (days: FormattedDays[]) =>
  days.filter((day) => day.value.start && day.value.end);

export const hours = Array.from(
  { length: 24 },
  (_, i) => `${i.toString().padStart(2, "0")}:00`
);

export const getBotTimeDays = ({
  formattedDays,
  end,
  start,
}: {
  formattedDays: FormattedDays[];
  end: string | null;
  start: string | null;
}) =>
  formattedDays.reduce((acc, day) => {
    acc[day.label] = { start, end };

    return acc;
  }, {} as BotTimeDays);

export const getRestBotTimeDays = (formattedDays: FormattedDays[]) => {
  return DAY_OF_THE_WEEK.filter(
    (day) =>
      !formattedDays.some((formattedDay) => formattedDay.label === day.label)
  ).reduce((acc, day) => {
    acc[day.label as Days] = { start: null, end: null };

    return acc;
  }, {} as BotTimeDays);
};

export const getRestDays = (values: InitialValues) => {
  const changedBotTimeDays = getBotTimeDays({
    formattedDays: values.days,
    start: values.timeFrom || null,
    end: values.timeTo || null,
  });

  const restBotTimeDays = getRestBotTimeDays(values.days);

  return { ...changedBotTimeDays, ...restBotTimeDays };
};

export const getDisabledSetBotTime = ({
  formik,
  assistant,
  selectedWeekDays,
}: {
  formik: FormikValues;
  assistant: Assistant;
  selectedWeekDays: FormattedDays[];
}) => {
  const restDays = getRestDays(formik.values);

  return (
    (dequal(restDays, assistant?.bot_time?.days) &&
      formik.values.timeFrom === selectedWeekDays[0]?.value?.start &&
      formik.values.timeTo === selectedWeekDays[0]?.value?.end &&
      formik.values.timezone === assistant?.bot_time?.timezone) ||
    getDisabledFromikRules(formik)
  );
};
