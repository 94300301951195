import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  RoundedBox,
  TextField,
  Title,
  Button,
} from "../../../../components";

import {
  COMMUNICATION_STYLE_EN,
  COMMUNICATION_STYLE_RU,
} from "../../../../../constants/configuration";
import { useSelectedLanguage } from "../../../../hooks";
import { useAIConfigurationState } from "../../context";
import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";

type InitialValues = {
  name: string;
  communicationStyle: number;
  welcomeMessage: string;
};

const initialValues: InitialValues = {
  name: "",
  communicationStyle: 0,
  welcomeMessage: "",
};

const getCommunicationStyle = (language: string) => {
  return language === "English"
    ? COMMUNICATION_STYLE_EN
    : COMMUNICATION_STYLE_RU;
};

export const AssistantProfile = memo(() => {
  const {
    selectedCommunicationStyle,
    setSelectedCommunicationStyle,
    assistant,
    handleSaveChanges,
  } = useAIConfigurationState();

  const selectedLanguage = useSelectedLanguage();

  const [openCommunicationStyle, setOpenCommunicationStyle] = useState(false);

  const communicationStyles = useMemo(
    () => getCommunicationStyle(selectedLanguage?.label || "English"),
    [selectedLanguage?.label]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
  });

  useEffect(() => {
    formik.setValues((prev) => ({
      ...prev,
      name: assistant?.bot_name || "",
      welcomeMessage: assistant?.welcome_message || "",
      communicationStyle: assistant?.communication_style || 0,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assistant?.bot_name,
    assistant?.welcome_message,
    assistant?.communication_style,
  ]);

  const handleBlurName = useCallback(() => {
    formik.setFieldTouched("name", true);

    if (formik.values.name === assistant?.bot_name || !formik.values.name)
      return;

    handleSaveChanges({ bot_name: formik.values.name });
  }, [assistant?.bot_name, formik, handleSaveChanges]);

  const handleBlurWelcomeMessage = useCallback(() => {
    formik.setFieldTouched("welcomeMessage", true);

    if (
      formik.values.welcomeMessage === assistant?.welcome_message ||
      !formik.values.welcomeMessage
    )
      return;

    handleSaveChanges({ welcome_message: formik.values.welcomeMessage });
  }, [assistant?.welcome_message, formik, handleSaveChanges]);

  const handleCkickCommunicationStyle = useCallback(
    (communication: Option) => {
      if (communication.value === formik.values.communicationStyle) return;

      setSelectedCommunicationStyle(communication);

      formik.setFieldValue("communicationStyle", communication.value);

      handleSaveChanges({
        communication_style: communication.value,
      });

      setOpenCommunicationStyle(false);
    },
    [formik, handleSaveChanges, setSelectedCommunicationStyle]
  );

  return (
    <RoundedBox>
      <Title text="Assistant Profile" className="mb-7" component="h2" />
      <div className="column gap-6">
        <div className="column md:flex-row md:items-start gap-6 [&>div]:w-full md:[&>div]:w-1/2">
          <TextField
            inputLabel="Name"
            placeholder="Assistant Name"
            className="!border !border-light-black2 !rounded-[0.9rem] !body-1 !bg-transparent !px-7"
            value={formik.values.name}
            onBlur={handleBlurName}
            classNameWrapper="h-full [&>div]:h-[3.46rem]"
            name="name"
            formik={formik}
          />
          <Dropdown
            open={openCommunicationStyle}
            onToggle={setOpenCommunicationStyle}
            placeholder={
              selectedCommunicationStyle?.label || "Communication style"
            }
            label="Communication style"
            className="!h-full grow [&>button]:px-7 [&>button]:!border-light-black2 [&>button]:h-[3.46rem] [&>button]:grow [&>button]:leading-[170%] [&>button]:hover:!border-purple [&>button]:hover:!text-purple"
            body={
              <div className="column gap-2">
                {communicationStyles.map((communication) => (
                  <Button
                    key={communication.value}
                    className="rounded-[0.7rem] hover:!bg-purple hover:!text-white"
                    variant="rounded"
                    onClick={() => handleCkickCommunicationStyle(communication)}
                  >
                    {communication.label}
                  </Button>
                ))}
              </div>
            }
            name="communicationStyle"
            formik={formik}
          />
        </div>
        <TextField
          inputLabel="Welcome message"
          placeholder="Type your message here..."
          className="!border !border-light-black2 !rounded-[0.9rem] !body-1 !bg-transparent !px-7"
          value={formik.values.welcomeMessage}
          onBlur={handleBlurWelcomeMessage}
          classNameWrapper="h-full [&>div]:h-[3.46rem]"
          name="welcomeMessage"
          formik={formik}
        />
      </div>
    </RoundedBox>
  );
});
