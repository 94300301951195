export const Archive = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5rem"
      height="5rem"
      viewBox="-4 0 64 64"
    >
      <path
        d="M5.15.012c-2.801 0-5.072 2.272-5.072 5.074v53.841c0 2.802 2.272 5.073 5.072 5.073h45.775c2.802 0 5.075-2.271 5.075-5.073v-38.606l-18.904-20.309h-31.946z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#4E74B7"
      />

      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M55.977 20.352v1h-12.799s-6.312-1.26-6.129-6.707c0 0 .208 5.707 6.004 5.707h12.924z"
          fill="#3A57A5"
        />

        <path
          d="M37.074 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.799l-18.903-20.352z"
          opacity=".5"
          fill="#ffffff"
        />
      </g>

      <path
        d="M29.187 40.872v11.917c0 .662-.529 1.191-1.191 1.191h-16.685c-.662 0-1.191-.529-1.191-1.191v-11.917c0-.133.021-.265.065-.375l1.59-4.768c.153-.485.617-.816 1.125-.816h13.507c.508 0 .971.331 1.125.816l1.59 4.768c.043.11.065.242.065.375zm-2.45 0l-1.191-3.575h-11.786l-1.191 3.575h14.168z"
        fill="#ffffff"
      />
    </svg>
  );
};
