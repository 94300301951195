import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconTotalCost: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 3H17C18.1046 3 19 3.89543 19 5V8M19 8V15C19 16.1046 18.1046 17 17 17H8M19 8H1M8 17L10 19M8 17L10 15M4 17H3C1.89543 17 1 16.1046 1 15V8M1 8V5C1 3.89543 1.89543 3 3 3H12M12 3L10 5M12 3L10 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
