import React from "react";
import { components } from "react-select";
import { FaTimes } from "react-icons/fa";
import styles from "./CustomStyles.module.scss";

export const SelectedValueWithRemoveCross = (props) => {
  const { data, selectProps, removeProps } = props;

  const handleRemove = (e) => {
    e.stopPropagation();

    // If handleRemoveAssistant is passed as a prop, call it
    if (selectProps.onRemoveAssistant) {
      selectProps.onRemoveAssistant(data);
    }

    // Call the original remove method
    removeProps.onClick(e);
  };

  return (
    <components.MultiValue {...props}>
      <span className={styles.selectedItem}>
        {props.data.label}
        <button className={styles.removeButton} onClick={handleRemove}>
          <FaTimes size={8} />
        </button>
      </span>
    </components.MultiValue>
  );
};
