import React, { useState, useEffect, ChangeEvent, FormEvent, FC } from "react";
import { Modal } from "../../../redesign/components/Modal";
import { Button } from "../../../redesign/components/Button";

interface AddKnowledgeBaseNameProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  saveKnowledgeBase: (value: string) => void;
}

export const AddKnowledgeBaseName: FC<AddKnowledgeBaseNameProps> = ({
  isOpen,
  setIsOpen,
  saveKnowledgeBase,
}) => {
  const MAXIMUM_LINE_LENGTH = 20;
  const [value, setValue] = useState<string>("");
  const [strLen, setStrLen] = useState<number>(MAXIMUM_LINE_LENGTH);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveKnowledgeBase(value);
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Replace all non-ASCII characters with an empty string
    const asciiValue = inputValue.replace(/[^\x00-\x7F]/g, "");
    // Replace spaces with dashes
    const valueWithHyphens = asciiValue.replace(/\s+/g, "-");
    if (valueWithHyphens.length <= MAXIMUM_LINE_LENGTH) {
      setValue(valueWithHyphens.toLowerCase());
    }
  };

  // When closing the modal, we clear the values
  useEffect(() => {
    if (!isOpen) {
      setValue("");
    }
  }, [isOpen]);

  // Updating the number of characters remaining
  useEffect(() => {
    setStrLen(MAXIMUM_LINE_LENGTH - value.length);
  }, [value]);

  return (
    <Modal
      open={isOpen}
      toggleOpen={closeModal}
      title="Create Knowledge Base"
      actions={
        <div className="flex justify-center">
          <Button
            variant="primary"
            onClick={() => saveKnowledgeBase(value)}
            className="w-1/3 mx-auto"
          >
            Continue
          </Button>
        </div>
      }
    >
      <form onSubmit={onSubmit} className="p-6">
        <div className="mb-4">
          <label className="block text-xl font-semibold mb-2">
            Enter the name of the knowledge base
          </label>
          <div className="flex items-center gap-4">
            <input
              type="text"
              value={value}
              onChange={onInput}
              placeholder="Enter a name"
              className="w-full border border-gray-300 rounded-lg p-2 text-lg focus:outline-none focus:ring-2 focus:ring-primary-gradient"
            />
            <div className="text-gray-500 text-lg">
              {strLen} characters left
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
