export const useChatFilters = ({
  selectedAssistants,
  setSelectedAssistants,
  setAppliedAssistants,
  selectedChannels,
  setSelectedChannels,
  setAppliedChannels,
  selectedFunnelStatuses,
  setSelectedFunnelStatuses,
  setAppliedFunnelStatuses,
  selectedTimeRange,
  setSelectedTimeRange,
  setAppliedTimeRange,
  selectedManagers,
  setSelectedManagers,
  setAppliedManagers,
  dispatch,
  setChats,
  setPage,
  loadChats,
  updateFiltersInQuery,
  isRestoringRef,
}) => {
  const handleApplyAssistants = () => {
    if (isRestoringRef.current) return;

    setAppliedAssistants(selectedAssistants);

    localStorage.setItem(
      "appliedAssistants",
      JSON.stringify(selectedAssistants)
    );
    //setShouldNavigateToFirstChat(true);

    updateFiltersInQuery();
  };

  const handleRemoveAssistant = (assistantToRemove) => {
    const updatedAssistants = selectedAssistants.filter(
      (assistant) => assistant.value !== assistantToRemove.value
    );
    dispatch(setSelectedAssistants(updatedAssistants));
    localStorage.removeItem("appliedAssistants");

    setAppliedAssistants(updatedAssistants);
  };

  const handleCleanAssistants = () => {
    dispatch(setSelectedAssistants([]));
    setAppliedAssistants([]);
    setChats([]);
    setPage(1);
    localStorage.removeItem("appliedAssistants");

    loadChats();
  };

  // Channels Handlers
  const handleSelectChannel = (selectedOptions) => {
    setSelectedChannels(selectedOptions);
  };

  const handleApplyChannels = (allSelected) => {
    if (allSelected === "all") {
      setAppliedChannels([]);
    } else {
      setAppliedChannels(selectedChannels);
    }
  };

  const handleCleanChannels = () => {
    setSelectedChannels([]);
    setAppliedChannels([]);
  };

  // Funnel Status Handlers
  const handleApplyFunnelStatus = () => {
    setAppliedFunnelStatuses(selectedFunnelStatuses);
  };

  const handleCleanFunnelStatuses = () => {
    setSelectedFunnelStatuses([]);
    setAppliedFunnelStatuses([]);
  };

  //   Managers Handlers
  const handleApplyManagers = () => {
    setAppliedManagers(selectedManagers);
  };

  const handleCleanManagers = () => {
    setSelectedManagers([]);
    setAppliedManagers([]);
  };

  // Time Range Handlers
  const handleApplyTimeRange = () => {
    if (selectedTimeRange && selectedTimeRange.value !== "custom") {
      setAppliedTimeRange(selectedTimeRange);
    }
  };

  const handleCleanTimeRange = () => {
    setSelectedTimeRange(null);
    setAppliedTimeRange(null);
  };

  return {
    handleApplyAssistants,
    handleRemoveAssistant,
    handleCleanAssistants,
    handleSelectChannel,
    handleApplyChannels,
    handleCleanChannels,
    handleApplyFunnelStatus,
    handleCleanFunnelStatuses,
    handleApplyTimeRange,
    handleCleanTimeRange,
    handleApplyManagers,
    handleCleanManagers,
  };
};
