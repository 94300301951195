import React, { FC } from "react";
import { IconProps } from "../../types";

type Props = { size?: number } & Omit<IconProps, "size">;

export const IconDialogMessage: FC<Props> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox={`0 0 ${size} ${size + 1}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.16663 6.5C9.16663 6.77614 8.94277 7 8.66663 7C8.39048 7 8.16663 6.77614 8.16663 6.5C8.16663 6.22386 8.39048 6 8.66663 6C8.94277 6 9.16663 6.22386 9.16663 6.5ZM13.1666 6.5C13.1666 6.77614 12.9428 7 12.6666 7C12.3905 7 12.1666 6.77614 12.1666 6.5C12.1666 6.22386 12.3905 6 12.6666 6C12.9428 6 13.1666 6.22386 13.1666 6.5Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M8.66663 9.5C9.66663 10.4956 11.6666 10.4956 12.6666 9.5M3.66663 1H17.6666C18.7712 1 19.6666 1.89147 19.6666 2.99115V12.9513C19.6666 14.051 18.7712 14.9424 17.6666 14.9424H6.14725C5.84347 14.9424 5.55615 15.0799 5.36638 15.3161L2.55706 18.8122C2.26181 19.1796 1.66663 18.9718 1.66663 18.5012V2.99115C1.66663 1.89147 2.56206 1 3.66663 1Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
