import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useReduxStore } from "../../redesign/hooks/useReduxStore";
import { toast } from "react-toastify";
import classNames from "classnames";
import supabase from "../../supabase";

import {
  useRevokeIntegrationMutation,
  useValidateIntegrationAssetsMutation,
} from "../../store/api/integrationApi";

import { SITE_URL_REGEX } from "../../constants/validationScheme";

import { Button } from "../../redesign/components";
import { Loader } from "../Loader";

import { SiteLinkModal } from "../popups/SiteLinkModal/SiteLinkModal";
import { DefaultIntegrationModal } from "../popups/DefaultIntegrationModal/DefaultIntegrationModal";
import { IntegrationWithKeysProps } from "../../types/knowledgeBase";
import {
  useIntegration,
  fetchIntegrationData,
} from "../../hooks/knowledgeBase/useIntegration";

import {
  OPENCART_CMS,
  WORDPRESS_CMS,
} from "../../constants/knowledgeBaseItems";
import { useCMSForm } from "../../hooks/knowledgeBase/useCMSForm";
import { useOpenCartLanguage } from "../../hooks/knowledgeBase/useOpenCartLanguage";
import { OpenCartLanguageModal } from "../popups/OpenCartLanguageModal/OpenCartLanguageModal";

export const IntegrationWithKeys: React.FC<IntegrationWithKeysProps> = (
  props
) => {
  const {
    id,
    title,
    metadata,
    icon,
    description,
    connected,
    fetchIntegrations,
    isLoadingRefreshData,
    children,
    knowledgeBase,
    isAnyCMSConnected,
    integrationInProgressId,
    docLink,
    accessToken: storedAccessToken,
    refreshToken: storedRefreshToken,
  } = props;

  const { auth } = useReduxStore();
  const user = auth.user;

  const siteLinkIntegration = useIntegration(
    id,
    title,
    storedAccessToken,
    storedRefreshToken,
    user,
    fetchIntegrations
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inventoryName, setInventoryName] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [siteLinks, setSiteLinks] = useState<string[]>([""]);

  // for OpenCart CMS
  const isOpenCartCMS = useMemo(
    () => title === OPENCART_CMS && !Number.isNaN(Number(id)),
    [id, title]
  );

  // CMS form hook
  const {
    domain,
    isDomainValid,
    pluginName,
    isPluginNameValid,
    handleDomainChange,
    handlePluginNameChange,
    setDomain,
    setPluginName,
  } = useCMSForm();

  // Hook for working with OpenCart languages
  const {
    languagesOptions,
    loadingLanguages,
    selectedLanguage,
    setSelectedLanguage,
    fetchLanguages,
  } = useOpenCartLanguage(knowledgeBase);

  const [integrationOpenCartCMSData, setIntegrationOpenCartCMSData] =
    useState<any>(null);
  const [isModalSelectLanguageOpen, setIsModalSelectLanguageOpen] =
    useState<boolean>(false);

  const [revokeIntegration] = useRevokeIntegrationMutation();
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();

  // If the integration is already connected, install tokens
  useEffect(() => {
    if (connected) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
    }
  }, [connected, storedAccessToken, storedRefreshToken]);

  useEffect(() => {
    if (title === WORDPRESS_CMS) {
      setInventoryName("wp-inventory");
      setPluginName("feedv1");
    } else if (title === OPENCART_CMS) {
      setInventoryName("oc-inventory");
      setPluginName("feedv1");
    } else if (title === "XML Product Feed") {
      setInventoryName("xml-inventory");

      setPluginName("feedv1");
    } else if (title === "YML Product Feed") {
      setInventoryName("yml-inventory");
      setPluginName("feedv1");
    } else {
      setInventoryName("");
      setPluginName("feedv1");
    }
  }, [title]);

  // Loading data for OpenCart
  const fetchOpenCartCMSIntegration = useCallback(async () => {
    try {
      const data = await fetchIntegrationData(id);
      setIntegrationOpenCartCMSData(data);
    } catch (error) {
      console.error("Error fetching OpenCart integration data", error);
    }
  }, [id]);

  useEffect(() => {
    if (!isOpenCartCMS) return;

    if (isOpenCartCMS) {
      fetchOpenCartCMSIntegration();
    }
  }, [fetchOpenCartCMSIntegration, isOpenCartCMS]);

  // When the language selection modal opens, we load the languages ​​and set the initial value
  useEffect(() => {
    if (!integrationOpenCartCMSData || !isModalSelectLanguageOpen) return;
    fetchLanguages();
    const setupLanguage = metadata?.oc_inventory_lang
      ? { value: metadata.oc_inventory_lang, label: metadata.oc_inventory_lang }
      : null;
    setSelectedLanguage(setupLanguage);
  }, [
    fetchLanguages,
    integrationOpenCartCMSData,
    isModalSelectLanguageOpen,
    metadata?.oc_inventory_lang,
    setSelectedLanguage,
  ]);

  // Updating integration data
  const updateIntegrationAssets = useCallback(async () => {
    try {
      if (title === "Site link") {
        if (siteLinks.length === 0 || siteLinks.length > 5) {
          toast.error("Please enter from 1 to 5 valid URLs");
          return;
        }
        for (const link of siteLinks) {
          if (!SITE_URL_REGEX.test(link)) {
            toast.error(`Wrong URL: ${link}`);
            return;
          }
        }
        const joinedLinks = siteLinks.join("|");
        const { data, error } = await supabase
          .from("integrations")
          .select("id")
          .eq("account_id", user.account_id)
          .eq("integration", "Site link");
        if (error) {
          toast.error(
            "Error checking existing Site link integration: " + error.message
          );
          return;
        }
        if (data && data.length > 0) {
          toast.error("You already have the 'Site link' integration connected");
          return;
        }
        const { error: insertErr } = await supabase
          .from("integrations")
          .insert({
            account_id: user.account_id,
            integration: "Site link",
            access_token: joinedLinks,
            type: "cms",
            is_knowledge_base: true,
            knowledge_base: knowledgeBase,
          });
        if (insertErr) {
          toast.error(
            "Error saving Site link integration: " + insertErr.message
          );
          return;
        }
        toast.success("Site link integration is enabled!");
        setIsModalOpen(false);
        return;
      }

      const parsedDomain = new URL(domain);
      const domainHostname = parsedDomain.hostname;
      const updatedPluginName = pluginName.trim().toLowerCase();

      let newAccessToken = "";
      if (title === WORDPRESS_CMS) {
        newAccessToken = `https://${domainHostname}/wp-json/mcb/v1/${user.account_id}-${updatedPluginName}/products`;
      } else if (title === OPENCART_CMS) {
        newAccessToken = `https://${domainHostname}/index.php?route=extension/module/mychatbot/products&app_id=${updatedPluginName}`;
      } else if (inventoryName === "xml-inventory") {
        newAccessToken = domain;
      } else {
        newAccessToken = domain;
      }

      const { error: insertErr } = await supabase.from("integrations").insert({
        account_id: user.account_id,
        integration: title,
        access_token: newAccessToken,
        type: "cms",
        is_knowledge_base: true,
        knowledge_base: knowledgeBase,
      });
      if (insertErr) {
        toast.error("Failed to insert record: " + insertErr.message);
        return;
      }
      toast.success("Integration assets updated successfully");

      if (inventoryName !== "xml-inventory") {
        window.open(
          `${process.env.REACT_APP_API_URL}/downloads/${inventoryName}/${user.account_id}/${updatedPluginName}`
        );
      } else {
        const resp = await validateIntegrationAssets({
          account_id: user.account_id,
          integration: title,
          access_token: newAccessToken,
        });
        if (resp.error) {
          toast.error(resp.error);
        }
      }
    } catch (err: any) {
      toast.error("Error in updateIntegrationAssets: " + err.message);
    } finally {
      setIsModalOpen(false);
      setIsLoading(false);
    }
  }, [
    siteLinks,
    domain,
    inventoryName,
    knowledgeBase,
    pluginName,
    title,
    user.account_id,
    validateIntegrationAssets,
  ]);

  const handleOnConnect = useCallback(async () => {
    if (isDomainValid && isPluginNameValid && !isButtonDisabled) {
      setIsButtonDisabled(true);
      setIsLoading(true);
      try {
        await updateIntegrationAssets();
      } catch ({ message }) {
        toast.error(message);
      } finally {
        fetchIntegrations();
        setIsLoading(false);
        setTimeout(() => setIsButtonDisabled(false), 5000);
      }
    }
  }, [
    fetchIntegrations,
    isDomainValid,
    isPluginNameValid,
    updateIntegrationAssets,
    isButtonDisabled,
  ]);

  const handleOnRevoke = useCallback(async () => {
    setIsLoading(true);
    try {
      if (title === "Site link" && siteLinkIntegration) {
        await siteLinkIntegration.handleOnRevoke();
      } else {
        const payload = {
          account_id: user.account_id,
          integration: title,
          access_token: accessToken,
          refresh_token: refreshToken,
        };
        try {
          await revokeIntegration(payload).unwrap();
        } catch (error: any) {
          toast.error("Failed to revoke integration");
        }
        const { error: errorSupabase } = await supabase
          .from("integrations")
          .delete()
          .eq("account_id", user.account_id)
          .eq("id", id)
          .eq("integration", title);
        if (errorSupabase) {
          toast.error(errorSupabase.message || errorSupabase);
        }
      }
      setDomain("");
      setPluginName("");
      setInventoryName("");
      setAccessToken("");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      fetchIntegrations();
    }
  }, [
    fetchIntegrations,
    id,
    title,
    user.account_id,
    accessToken,
    refreshToken,
    revokeIntegration,
    siteLinkIntegration,
  ]);

  const isIntegrationDisabled = useMemo(() => {
    if (integrationInProgressId && integrationInProgressId !== id) return true;
    return isAnyCMSConnected && !connected;
  }, [connected, isAnyCMSConnected, integrationInProgressId, id]);

  // Language update for OpenCart
  const handleSetOCLang = useCallback(async () => {
    if (!selectedLanguage) return;
    try {
      const { error } = await supabase
        .from("integrations")
        .update({ metadata: { oc_inventory_lang: selectedLanguage.value } })
        .match({ account_id: user.account_id, integration: title });
      if (error) {
        toast.error(`Failed to update language: ${error.message}`);
        return;
      }
      toast.success("Language updated successfully");
      setSelectedLanguage(null);
      setIsModalSelectLanguageOpen(false);
      fetchIntegrations();
    } catch (err: any) {
      toast.error(`Error updating language: ${err.message}`);
    }
  }, [selectedLanguage, title, user?.account_id, fetchIntegrations]);

  const integrationContent = useMemo(
    () => (
      <>
        {title === "Site link" && storedAccessToken && (
          <div className="flex flex-wrap justify-end">
            {storedAccessToken.split("|").map((link, idx) => {
              const cleanedLink = link
                .replace(/^https?:\/\//, "")
                .replace(/^www\./, "")
                .replace(/\/$/, "");
              return (
                <a
                  key={idx}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="pt-0 pr-1 pb-1 pl-2 text-accent overflow-hidden max-w-[10rem] text-ellipsis whitespace-nowrap"
                >
                  {cleanedLink}
                </a>
              );
            })}
          </div>
        )}

        <div className="flex flex-col items-center justify-evenly h-full gap-2">
          {icon ? (
            <svg className="flex items-center w-[100px] h-[50px] mb-4 sm:w-[60px] sm:h-[30px] md:w-[80px] md:h-[40px] lg:w-[100px] lg:h-[50px]">
              {icon}
            </svg>
          ) : (
            children
          )}

          {docLink && title !== "Site link" && (
            <a
              className="absolute top-3 right-3 text-accent"
              target="_blank"
              href={docLink}
              rel="noreferrer"
            >
              link
            </a>
          )}

          {!!title && (
            <div className="text-xl font-semibold mb-2 text-center md:text-lg">
              {title}
            </div>
          )}

          {!!description && (
            <div className="text-xl text-center mb-3 font-normal md:text-base">
              {description}
            </div>
          )}
        </div>

        {isOpenCartCMS && (
          <Button
            onClick={() => setIsModalSelectLanguageOpen(true)}
            className="w-full px-4 py-3 rounded-lg text-white uppercase font-semibold text-xl leading-7 transition-colors duration-300 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 mb-2 md:py-2.5 md:rounded-md"
            disabled={!isOpenCartCMS}
          >
            {isOpenCartCMS && metadata?.oc_inventory_lang
              ? metadata?.oc_inventory_lang
              : "Setup language"}
          </Button>
        )}

        <Button
          onClick={() => {
            connected ? handleOnRevoke() : setIsModalOpen(true);
          }}
          className={classNames(
            "w-full px-4 py-3 rounded-[0.6rem] text-white uppercase font-semibold text-xl leading-[1.6rem]",
            connected
              ? "bg-[#59d259] md:py-2.5 md:rounded-[0.5rem]"
              : "bg-gradient-blue-to-purple-angled"
          )}
          disabled={isIntegrationDisabled}
        >
          {connected ? "Revoke" : "Connect"}
        </Button>
      </>
    ),
    [
      title,
      storedAccessToken,
      icon,
      children,
      docLink,
      connected,
      isIntegrationDisabled,
      handleOnRevoke,
      isOpenCartCMS,
      metadata?.oc_inventory_lang,
    ]
  );

  return (
    <div className="w-full flex flex-col items-center justify-center p-4 bg-white shadow-md rounded-lg">
      {isLoading || isLoadingRefreshData ? (
        <div className="flex justify-center items-center w-full h-20">
          <Loader width={40} height={40} />
        </div>
      ) : (
        integrationContent
      )}

      {title === "Site link" ? (
        <SiteLinkModal
          open={isModalOpen}
          toggleOpen={() => setIsModalOpen((prev) => !prev)}
          siteLinks={siteLinks}
          setSiteLinks={setSiteLinks}
          onConnect={handleOnConnect}
          isButtonDisabled={isButtonDisabled}
        />
      ) : (
        <DefaultIntegrationModal
          open={isModalOpen}
          toggleOpen={() => setIsModalOpen(!isModalOpen)}
          title={title}
          domain={domain}
          onDomainChange={handleDomainChange}
          isDomainValid={isDomainValid}
          domainErrorText="Invalid URL"
          pluginName={pluginName}
          onPluginNameChange={handlePluginNameChange}
          isPluginNameValid={isPluginNameValid}
          inventoryName={inventoryName}
          onConnect={handleOnConnect}
          isButtonDisabled={isButtonDisabled}
        />
      )}

      <OpenCartLanguageModal
        open={isModalSelectLanguageOpen}
        toggleOpen={() => setIsModalSelectLanguageOpen((prev) => !prev)}
        languagesOptions={languagesOptions}
        loadingLanguages={loadingLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        onConfirm={handleSetOCLang}
      />
    </div>
  );
};
