import React from "react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { contentStyle } from "../../../constants/popups";
import dangerIcon from "../../../assets/img/danger.png";
import "../../../assets/scss/modal.scss";
import { HiOutlineTrash } from "react-icons/hi2";

interface DeletePopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onDelete: () => Promise<void>;
  title: string;
}

export const DeletePopup: React.FC<DeletePopupProps> = ({
  isOpen,
  setIsOpen,
  onDelete,
  title,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDeleteClick = async () => {
    try {
      await onDelete();
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while deleting.");
    } finally {
      closeModal();
    }
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      contentStyle={contentStyle}
    >
      <div className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <img src={dangerIcon} alt="danger icon" className="dangerIcon" />
        <h2 className="modalTitle">{title}</h2>
        <div className="btns">
          <button
            type="button"
            className="modalBtn saveBtn"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modalBtn btnDanger"
            onClick={handleDeleteClick}
          >
            Delete
            <HiOutlineTrash className="icon" size={18} />
          </button>
        </div>
      </div>
    </Popup>
  );
};
