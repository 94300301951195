import { Dispatch, SetStateAction } from "react";
import supabase from "../../../../../../../../supabase";

interface OptionItem {
  value: string;
  label: string;
  createdAt?: string;
}

interface HandlersConfig {
  tableMap: Record<string, string>;
  fieldMap: Record<string, string>;
  nameFieldMap: Record<string, string>;
}

const handlersConfig: HandlersConfig = {
  tableMap: {
    labels: "labels",
    funnel_statuses: "funnel_statuses",
    client_types: "client_types",
  },
  fieldMap: {
    labels: "labels",
    funnel_statuses: "funnel_status",
    client_types: "type",
  },
  nameFieldMap: {
    labels: "label_name",
    funnel_statuses: "status_name",
    client_types: "type_name",
  },
};

interface UniversalHandlersProps {
  accountId: string;
  clientId: string;
  clientCardData: any;
  setClientCardData: (data: any) => void;
  updateDataInSB: (table: string, data: any, id: string) => Promise<void>;
}

export const deleteEditFilters = ({
  accountId,
  clientId,
  clientCardData,
  setClientCardData,
  updateDataInSB,
}: UniversalHandlersProps) => {
  const handleUniversalDelete = async (
    itemType: string,
    value: string,
    createdAt: string,
    selectedValue: OptionItem | null,
    setSelected: Dispatch<SetStateAction<OptionItem | null>>,
    setOptions: Dispatch<SetStateAction<OptionItem[]>>
  ) => {
    const table = handlersConfig.tableMap[itemType];
    const field = handlersConfig.fieldMap[itemType];
    const nameField = handlersConfig.nameFieldMap[itemType];

    try {
      // delete the actual label/status/type
      const { error: deleteError } = await supabase
        .from(table)
        .delete()
        .match({
          [nameField]: value,
          account_id: accountId,
          created_at: createdAt,
        });

      if (deleteError) throw deleteError;

      // Update local state immediately after successful deletion
      setOptions((prev) =>
        prev.filter(
          (opt) => !(opt.value === value && opt.createdAt === createdAt)
        )
      );

      // If this was the selected value, clear it
      if (selectedValue?.value === value) {
        setSelected(null);
        setClientCardData((prev: any) => ({
          ...prev,
          [field]: "",
        }));
      }

      // If current client was using this value, update it
      if (clientCardData[field] === value) {
        await updateDataInSB("clients", { [field]: "" }, clientId);
      }
    } catch (err) {
      console.error(`Failed to delete ${itemType}:`, err);
      throw err;
    }
  };

  const handleUniversalEdit = async (
    itemType: string,
    oldValue: string,
    newValue: string,
    selectedValue: OptionItem | null,
    setSelected: Dispatch<SetStateAction<OptionItem | null>>,
    setOptions: Dispatch<SetStateAction<OptionItem[]>>
  ) => {
    const table = handlersConfig.tableMap[itemType];
    const field = handlersConfig.fieldMap[itemType];
    const nameField = handlersConfig.nameFieldMap[itemType];

    try {
      // Update the label/status/type record
      const { error: updateError } = await supabase
        .from(table)
        .update({ [nameField]: newValue })
        .eq(nameField, oldValue)
        .eq("account_id", accountId);

      if (updateError) throw updateError;

      // Update all clients using this value
      const { error: clientUpdateError } = await supabase
        .from("clients")
        .update({ [field]: newValue })
        .eq(field, oldValue)
        .eq("account_id", accountId);

      if (clientUpdateError) throw clientUpdateError;

      // Update local state
      setOptions((prev) =>
        prev.map((opt) =>
          opt.value === oldValue
            ? { ...opt, value: newValue, label: newValue }
            : opt
        )
      );

      // Update selected value if needed
      if (selectedValue?.value === oldValue) {
        const updatedOption = {
          ...selectedValue,
          value: newValue,
          label: newValue,
        };
        setSelected(updatedOption);
        setClientCardData({
          ...clientCardData,
          [field]: newValue,
        });
      }

      // Update current client if needed
      if (clientCardData[field] === oldValue) {
        await updateDataInSB("clients", { [field]: newValue }, clientId);
      }
    } catch (err) {
      console.error(`Failed to edit ${itemType}:`, err);
      throw err;
    }
  };

  return {
    handleUniversalDelete,
    handleUniversalEdit,
  };
};
