import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
  DoughnutControllerChartOptions,
  Plugin,
} from "chart.js";
import { AnyObject } from "chart.js/dist/types/basic";
import { Pie } from "react-chartjs-2";
import { useWindowSize } from "react-use";
import { screenSizes } from "../../../../constants";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { useReduxStore } from "../../../../hooks";
import { useMemo } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["Viber", "Widget", "OLX account", "Instagram", "Telegram"],
  datasets: [
    {
      data: [10, 20, 40, 20, 10],
      backgroundColor: ["#E8EAF6", "#777EF5", "#A7ABF9", "#BCC0FF", "#E2DAFF"],
      borderWidth: 0,
    },
  ],
};

const legendMargin: Plugin<"pie", AnyObject> = {
  id: "legendMargin",
  beforeInit(chart) {
    if (chart.legend) {
      const fitValue = chart.legend.fit;

      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 20);
      };
    }
  },
  afterLayout(chart) {
    const legend = chart.legend;
    if (legend) {
      const chartAreaBottom = chart.chartArea.bottom;
      legend.top = chartAreaBottom + 20;
    }
  },
};

const options: _DeepPartialObject<
  CoreChartOptions<"pie"> &
    ElementChartOptions<"pie"> &
    PluginChartOptions<"pie"> &
    DatasetChartOptions<"pie"> &
    ScaleChartOptions<"pie"> &
    DoughnutControllerChartOptions
> = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        usePointStyle: true,
        pointStyleWidth: 17,
        generateLabels: (chart) => {
          const { data } = chart;
          return (data.labels || []).map((label, index) => ({
            text: label as string,
            fillStyle: Array.isArray(data.datasets[0].backgroundColor)
              ? data.datasets[0].backgroundColor[index]
              : undefined,
            borderRadius: 6,
            pointStyle: "rectRounded",
            strokeStyle: "transparent",
            lineWidth: 0,
            textAlign: "left",
          }));
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: "#384157",
      backgroundColor: "white",
      borderRadius: 8,
      font: {
        size: 14,
        weight: "normal",
        lineHeight: "140%",
      },
      formatter: (value) => `${value}%`,
      anchor: "end",
      align: "end",
    },
  },
};

const getChartLabels = (data: DashboardMessagesByChannel[]) =>
  data.map(({ channel }) => channel);

const getChartDatasetsData = (data: DashboardMessagesByChannel[]) =>
  data.map(({ percentage }) => percentage);

export const Chart = () => {
  const { dashboard } = useReduxStore();
  const { width: windowWidth } = useWindowSize();

  const chartLabels = useMemo(
    () => getChartLabels(dashboard.messagesByChannel || []),
    [dashboard.messagesByChannel]
  );

  const chartDatasetsData = useMemo(
    () => getChartDatasetsData(dashboard.messagesByChannel || []),
    [dashboard.messagesByChannel]
  );

  return (
    <div className="center h-full mt-auto [&>canvas]:mt-auto">
      <Pie
        data={{
          labels: chartLabels,
          datasets: [
            {
              ...data.datasets[0],
              data: chartDatasetsData,
            },
          ],
        }}
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            datalabels: {
              ...options.plugins!.datalabels,
              offset: windowWidth > screenSizes.sm ? -25 : -20,
            },
          },
        }}
        plugins={[legendMargin]}
      />
    </div>
  );
};
