import React, { FC, useRef, useState, useEffect } from "react";
import { List, arrayMove } from "react-movable";
import classNames from "classnames";
import { IconButton } from "@material-tailwind/react";
import { IoClose } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import { CheckboxValues } from "../../../types";

type Props = {
  dataCheckboxes: CheckboxValues[];
  setDataCheckboxes: React.Dispatch<React.SetStateAction<CheckboxValues[]>>;
};

export const DndColumns: FC<Props> = ({
  dataCheckboxes,
  setDataCheckboxes,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainer(containerRef.current);
    }
  }, []);

  return (
    <div className="w-full h-full" ref={containerRef}>
      {container && (
        <List
          lockVertically
          container={container}
          values={dataCheckboxes}
          onChange={({ oldIndex, newIndex }) =>
            setDataCheckboxes(arrayMove(dataCheckboxes, oldIndex, newIndex))
          }
          renderList={({ children, props, isDragged }) => (
            <ul
              {...props}
              className={classNames("p-0", {
                "cursor-grabbing": isDragged,
              })}
            >
              {children}
            </ul>
          )}
          renderItem={({ value, props, isDragged, isSelected }) => (
            <li
              {...props}
              style={{
                ...props.style,
                margin: "0.5rem 0",
              }}
              className="mx-0 my-[0.5rem] before:hidden p-3 border-b border-b-gray-300"
              key={props.key}
            >
              <div className="flex items-center justify-between">
                <span className="flex items-center gap-4">
                  <span data-movable-handle>
                    <IoMdMenu
                      className={classNames(
                        "text-light-black2",
                        isDragged || isSelected
                          ? "cursor-grabbing"
                          : "cursor-grab"
                      )}
                      size="1.5rem"
                    />
                  </span>
                  <span className="text-gray-500 text-[1rem] leading-[140%]">
                    {value.label}
                  </span>
                </span>
                <IconButton color="blue-gray" size="sm" variant="text">
                  <IoClose className="text-gray-500" size="1.5rem" />
                </IconButton>
              </div>
            </li>
          )}
        />
      )}
    </div>
  );
};
