import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconArrowDropdown: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 7.5L9.29289 11.7929C9.68342 12.1834 10.3166 12.1834 10.7071 11.7929L15 7.5"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
