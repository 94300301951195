import React from "react";
import {
  getFileExtension,
  extensionMapping,
  previewMapping,
} from "../../constants/chats";

import { FcFile } from "react-icons/fc";

interface FilePreviewProps {
  fileUrl: string;
  fileType?: string;
}

export const FilePreview: React.FC<FilePreviewProps> = ({
  fileUrl,
  fileType,
}) => {
  // We use fileType if it is passed, otherwise we determine by extension
  const effectiveType =
    fileType || extensionMapping[getFileExtension(fileUrl)] || "";
  // if effectiveType contains "/" it is a MIME type
  const isMimeType = effectiveType.includes("/");

  // Image
  if (isMimeType && effectiveType.startsWith("image/")) {
    return (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={fileUrl}
          alt="Image preview"
          className="max-w-[15rem] max-h-[15rem] object-contain rounded border border-[#ddd]"
        />
      </a>
    );
  }

  // If the MIME type is found in previewMapping, we use the corresponding icon
  if (isMimeType && previewMapping[effectiveType]) {
    const { Icon, color } = previewMapping[effectiveType];
    return (
      <a
        href={fileUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-center"
      >
        <div className="text-center">
          <Icon style={{ color }} className="text-[40px]" />
        </div>
      </a>
    );
  }

  // Fallback: defined by extension
  const ext = getFileExtension(fileUrl);
  const fallbackType = extensionMapping[ext] || "";
  if (fallbackType.startsWith("image/")) {
    return (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={fileUrl}
          alt="Image preview"
          className="max-w-[15rem] max-h-[15rem] object-contain rounded border border-[#ddd]"
        />
      </a>
    );
  }
  if (previewMapping[fallbackType]) {
    const { Icon, color } = previewMapping[fallbackType];
    return (
      <a
        href={fileUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-center"
      >
        <div className="text-center">
          <Icon style={{ color }} className="text-[40px]" />
        </div>
      </a>
    );
  }

  // If nothing works, return the standard icon
  return (
    <a
      href={fileUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="text-center"
    >
      <div className="text-center">
        <FcFile />
      </div>
    </a>
  );
};
