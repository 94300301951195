import { useCallback, useEffect, useState } from "react";
import { Button, TextField } from "../../../components";
import { IconSend } from "../../../assets";
import {
  useSendMessageMutation,
  aiConfigurationApi,
} from "../../../../store/api";
import { useReduxStore, useAppDispatch } from "../../../hooks";
import { setOnboarding } from "../../../../store/slices/onboardingSlice";
import supabase from "../../../../supabase";
import { useAIConfigurationState } from "../context";
import { fetchSubscriptionByEmail } from "../../../../store/slices/subscription";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
});

type InitialValues = {
  message: string;
};

const initialValues: InitialValues = {
  message: "",
};

export const SendMessageAssistantForm = () => {
  const { onboarding, auth, aiConfiguration } = useReduxStore();
  const dispatch = useAppDispatch();
  const { assistant, isTyping, setIsTyping } = useAIConfigurationState();

  const [sendMessage] = useSendMessageMutation();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch(fetchSubscriptionByEmail(auth.user?.email))
      .unwrap()
      .then((data) => {
        setChecked(Boolean(data));
      });
  }, [auth.user?.email, dispatch]);

  // Basic logic for sending a message
  const onSubmit = useCallback(
    async (values: InitialValues, { resetForm }) => {
      try {
        if (!isTyping && values.message.trim() !== "") {
          setIsTyping(true);

          // Locally add messages from user
          const now = new Date().toISOString();
          const newMessage = {
            created_at: now,
            role: "user",
            content: values.message.trim(),
          };

          dispatch(
            aiConfigurationApi.util.updateQueryData(
              "messages",
              { id: auth.user?.account_id, assistantId: assistant?.id },
              (draft) => {
                draft.messages.push(newMessage);
              }
            )
          );

          const fullMessagesList = [
            ...aiConfiguration?.data?.messages,
            newMessage,
          ];

          // Preparing a request to the server
          const body = {
            account_id: auth.user?.account_id,
            assistant_id: assistant?.id,
            messages: fullMessagesList,
          };

          resetForm();

          const { error: errorApi } = await sendMessage(body);

          if (errorApi) {
            toast.error(errorApi?.data || "Error sending message");
          }

          if (onboarding.step === 2) {
            await supabase
              .from("onboardings")
              .update({ step: checked ? 4 : 3 })
              .match({ account_id: auth.user?.account_id });
            dispatch(setOnboarding(checked ? 4 : 3));
          }
        }
      } catch (error) {
        toast.error("Error sending message");
      } finally {
        setIsTyping(false);
      }
    },
    [
      aiConfiguration?.data?.messages,
      assistant?.id,
      auth.user?.account_id,
      checked,
      dispatch,
      isTyping,
      onboarding.step,
      sendMessage,
      setIsTyping,
    ]
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const disabled = isTyping || formik.values.message.trim() === "";

  return (
    <div className="px-6 pt-6 border-t border-gray-300 mt-auto relative">
      <form
        className="flex items-center gap-4 h-14"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          classNameWrapper="min-h-full [&>div]:h-14"
          placeholder="Type your message here..."
          className="!border !border-light-black2 !rounded-[0.9rem] h-full !text-[1.2rem]"
          formik={formik}
          name="message"
          showError={false}
        />
        <Button
          disabled={disabled}
          type="submit"
          className="w-auto !h-full !min-w-[4rem] py-0 [&>span:max-h-fit]"
        >
          <IconSend className="w-5 h-5 lg:w-10 lg:h-10" />
        </Button>
      </form>
    </div>
  );
};
