import { useState } from "react";
import { useDispatch } from "react-redux";
import supabase from "../../supabase";
import { fetchClients } from "../../store/slices/clientsSlice";

export const useClientCardData = (
  accountId: string,
  setClientCardData: (data: any) => void
) => {
  const [needUpdate, setNeedUpdate] = useState(true);
  const dispatch = useDispatch();

  // add new item labels / funnel_statuses / client_types
  const sendDataInSB = async (
    table: string,
    key: string,
    value: unknown
  ): Promise<void> => {
    const { error } = await supabase
      .from(table)
      .insert([{ account_id: accountId, [key]: value }]);

    if (error) {
      console.error(`Failed to add ${key}:`, error.message);
      throw error;
    }

    setNeedUpdate(true);
  };

  // update table clients (manager_id, labels, funnel_status, type)
  const updateDataInSB = async (
    table: string,
    dataObj: Record<string, unknown>,
    clientId: string
  ): Promise<void> => {
    const { data, error } = await supabase
      .from(table)
      .update(dataObj)
      .match({ client_id: clientId })
      .eq("account_id", accountId);

    if (error) {
      console.error("Update error:", error);
      throw error;
    }

    if (data) {
      setClientCardData((prev: any) => ({ ...prev, ...dataObj }));

      dispatch(fetchClients(accountId));
    }
  };

  return {
    needUpdate,
    setNeedUpdate,
    sendDataInSB,
    updateDataInSB,
  };
};
