import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { selectUser } from "../../../../../store/slices/authSlice";
import {
  fetchChatPartner,
  fetchKnowledgeHints,
  fetchPageContextData,
  updateNeedsOperatorStatus,
} from "../../supabaseRequests";
import { getData } from "../../../../../service/supabase.js";
import { Modal } from "../../../../../components/Modal";
import { Typography } from "../../../../../components";

import { useChatContext } from "../../context/ChatContextProvider";
import { Logo, Send } from "../../../../../assets/icons";
import MessageItem from "./components/MessageItem";
import {
  useSendInstagramMessageMutation,
  useSendOLXAccountMessageMutation,
  useSendTelegramMessageMutation,
  useSendTelegramAccountMessageMutation,
  useSendViberMessageMutation,
  useSendWpWidgetMessageMutation,
  useSendOcWidgetMessageMutation,
  useSendSwWidgetMessageMutation,
} from "../../../../../store/api";

import supabase from "../../../../../supabase";
import { HeaderChatSection } from "./components/HeaderChatSection/HeaderChatSection";
import { useWindowWidth } from "../../../../../hooks/useWindowWidth";
import { FileUploader } from "../../../../../components/FileUploader/FileUploader";
import { generateUniqueId } from "../../../../../helper";

const MessageList = () => {
  const windowWidth = useWindowWidth();
  const isSmallScreen = windowWidth < 1025;
  const user = useSelector(selectUser);

  const { clientId, pageId } = useParams();
  const { setActiveTab, setIsNeedUpdateChatList, isNeedUpdateChatList } =
    useChatContext();
  const navigate = useNavigate();

  const normalizedPageId = pageId ? pageId : null;

  const [chatPartner, setChatPartner] = useState(null);
  const [pageContext, setPageContext] = useState("");
  const [messagesList, setMessagesList] = useState(null);
  const [knowledgeHints, setKnowledgeHints] = useState([]);
  const [specMessageData, setSpecMessageData] = useState(null);

  const [isShowModal, setIsShowModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [attachment, setAttachment] = useState(null);

  const [activePulling, setActivePulling] = useState(true);
  const [botName, setBotName] = useState("");
  const [collapseStates, setCollapseStates] = useState({});

  const isDebugMode = useSelector((state) => state.debug.isDebugMode);

  const chatListRef = useRef(null);
  const intervalRef = useRef(null);
  const textareaRef = useRef(null);

  const [sendInstagramMessage] = useSendInstagramMessageMutation();
  const [sendWpWidgetMessage] = useSendWpWidgetMessageMutation();
  const [sendOcWidgetMessage] = useSendOcWidgetMessageMutation();
  const [sendSwWidgetMessage] = useSendSwWidgetMessageMutation();
  const [sendOLXAccountMessage] = useSendOLXAccountMessageMutation();
  const [sendTelegramMessage] = useSendTelegramMessageMutation();
  const [sendTelegramAccountMessage] = useSendTelegramAccountMessageMutation();
  const [sendViberMessage] = useSendViberMessageMutation();

  const channelsWithSendingMessages = {
    Instagram: {
      sendMessageFunction: sendInstagramMessage,
    },
    WpWidget: {
      sendMessageFunction: sendWpWidgetMessage,
    },
    OcWidget: {
      sendMessageFunction: sendOcWidgetMessage,
    },
    SwWidget: {
      sendMessageFunction: sendSwWidgetMessage,
    },
    OLXAccount: {
      sendMessageFunction: sendOLXAccountMessage,
    },
    Messenger: {
      sendMessageFunction: sendInstagramMessage,
    },
    WhatsApp: {
      sendMessageFunction: sendInstagramMessage,
    },
    Telegram: {
      sendMessageFunction: sendTelegramMessage,
    },
    TelegramAccount: {
      sendMessageFunction: sendTelegramAccountMessage,
    },
    Viber: {
      sendMessageFunction: sendViberMessage,
    },
  };

  const handleToggleCollapse = (key) => {
    setCollapseStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  const getChatPartner = useCallback(async () => {
    if (!clientId || !user) return;
    try {
      const { data: activeChatData, error: activeChatError } =
        await fetchChatPartner(user.account_id, clientId, normalizedPageId);

      if (activeChatError) {
        toast.error("Error fetching active chat: " + activeChatError.message);
        return;
      }

      if (activeChatData) {
        const { assistant_id, page_id, communication_channel } = activeChatData;

        // get bot name
        const { data: assistantsData, error: assistantsError } = await getData(
          "assistants",
          "bot_name",
          "id",
          assistant_id
        );

        if (assistantsError) {
          toast.error(
            "Error fetching assistant data: " + assistantsError.message
          );
          return;
        }
        const botName = assistantsData?.[0]?.bot_name || "";
        setBotName(botName);

        // Get channels data
        const { data: channelData, error: channelError } = await supabase
          .from("channels")
          .select("*")
          .eq("account_id", user.account_id)
          .eq("assistant_id", assistant_id);

        if (channelError) {
          toast.error("Error fetching channel data: " + channelError.message);
          return;
        }

        let pageDescription = page_id;
        if (channelData && channelData.page_description) {
          pageDescription = channelData.page_description;
        }
        setChatPartner({
          ...activeChatData,
          page_description: pageDescription,
        });
      } else {
        setChatPartner(null);
        setMessagesList(null);
      }
    } catch (error) {
      toast.error("Unexpected error: " + error.message);
    }
  }, [user, clientId, normalizedPageId]);

  useEffect(() => {
    getChatPartner();
  }, [getChatPartner, user, clientId, pageId]);

  useEffect(() => {
    const restoreScrollPosition = () => {
      if (chatListRef.current) {
        chatListRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    };
    setTimeout(restoreScrollPosition, 300);
  }, [messagesList?.length, isTyping]);
  const fetchChatMessages = useCallback(async () => {
    if (!chatPartner) return;
    try {
      const chatHistoryUrl = chatPartner?.chat_history_url;
      const chatId = chatPartner?.id;

      const [chatHistoryResponse, pageContextResponse] = await Promise.all([
        axios.get(chatHistoryUrl),
        fetchPageContextData(chatId),
      ]);

      const fetchedMessages = chatHistoryResponse?.data.length
        ? chatHistoryResponse.data
            .split("\n")
            .filter((line) => line.trim() !== "")
            .map((line) => JSON.parse(line))
            .filter((message) => {
              // Leave all assistant messages with function_call
              if (message.role === "assistant" && message.function_call) {
                return true;
              }

              // Leave a message if role = function
              if (message.role === "function") {
                return true;
              }

              // if content is not empty, is there MultiContent
              if (message.content !== "" || message.MultiContent) {
                return true;
              }

              return false;
            })
        : [chatHistoryResponse.data];
      const pageContextData = pageContextResponse.data;
      if (pageContextData) {
        setPageContext(pageContextData.page_context);
      }

      setMessagesList(fetchedMessages);
    } catch (error) {
      toast.error("Oops, some error. Please contact the administrator");
    }
  }, [chatPartner]);

  useEffect(() => {
    if (isNeedUpdateChatList) {
      fetchChatMessages();
      setIsNeedUpdateChatList(false);
    }
  }, [isNeedUpdateChatList, fetchChatMessages, setIsNeedUpdateChatList]);

  const fetchSpecialMessages = useCallback(async () => {
    try {
      const { data, status } = await fetchKnowledgeHints(user.account_id);
      if (data && status === 200) {
        setKnowledgeHints(data);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [user.account_id]);

  // auto-pull messages
  useEffect(() => {
    if (!activePulling) return clearInterval(intervalRef.current);
    if (chatPartner) {
      fetchChatMessages();
      fetchSpecialMessages();
      intervalRef.current = setInterval(() => {
        fetchChatMessages();
      }, 15000);
    }

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [
    activePulling,
    chatPartner,
    fetchChatMessages,
    fetchSpecialMessages,
    user,
  ]);

  const handleToggleSwitch = async () => {
    if (!chatPartner) return;
    try {
      setIsLoading(true);
      const needs_operator = !chatPartner?.needs_operator;
      const { error } = await updateNeedsOperatorStatus(
        chatPartner?.id,
        needs_operator
      );
      setIsNeedUpdateChatList(true);
      getChatPartner();
      setActiveTab(needs_operator ? 1 : 0);

      if (error) {
        throw new Error("Failed to turn off channel");
      }
    } catch (error) {
      setErrorText(error.message);
      setIsShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const sendSpecMessageData = async () => {
    if (!specMessageData || !chatPartner) return;
    try {
      setIsSending(true);
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/improve-knowledge?account=${user.account_id}`,
        specMessageData
      );

      if (data.status === 200) {
        fetchSpecialMessages();
        fetchChatMessages();
      }
      toast.success("Knowledge added, active immediately.");
    } catch (error) {
      toast.error(
        specMessageData.knowledge + " - this " + error.response.data.error
      );
    } finally {
      setIsSending(false);
    }
  };

  const handleChangeSpecMessageTextarea = (msgId, value) => {
    setSpecMessageData({
      message_id: msgId,
      knowledge: value.target.value.trim(),
      chat_id: chatPartner?.id,
    });
  };
  const handleClickSpecMessageSave = () => {
    if (!specMessageData || specMessageData?.knowledge?.length === 0) {
      setErrorText("Please enter text, the field must NOT be empty");
      setIsShowModal(true);
      return;
    }
    sendSpecMessageData();
  };
  const handleClickSpecMessageIcon = (messageId) => {
    setActivePulling(false);
    setSpecMessageData(null);

    const updateMessages = messagesList.map((msg) => {
      if (msg.id === messageId) {
        if (msg?.knowledge) {
          setActivePulling(true);
          return {
            ...msg,
            knowledge: false,
          };
        } else {
          return {
            ...msg,
            knowledge: true,
          };
        }
      } else {
        return {
          ...msg,
          knowledge: false,
        };
      }
    });

    setMessagesList(updateMessages);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleFileSelect = (file) => {
    // We save the file in a state for further processing when we click the Send button
    setAttachment(file);

    // Add the file name to the textarea value so the user can see that the file is attached
    setInputValue((prevValue) => `${prevValue} ${file.name}`);
  };

  const uploadAttachment = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const fileExtension = file.name.split(".").pop();

    const uniqueFileId = generateUniqueId() + "." + fileExtension;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/client_files/${uniqueFileId}`,
      { method: "POST", body: formData }
    );
    const text = await response.text();
    let data;
    try {
      data = JSON.parse(text);
    } catch (err) {
      throw new Error("Failed to parse server response");
    }
    if (!response.ok || !data.url) {
      throw new Error(data.message || "Failed to upload file");
    }
    return data.url;
  };

  const handleSend = async () => {
    if (!chatPartner) return;
    let finalContent = inputValue.trim();

    if (attachment) {
      try {
        const uploadedFileUrl = await uploadAttachment(attachment);
        // Add a URL to the message
        finalContent = finalContent + " " + uploadedFileUrl;
        setAttachment(null);
      } catch (err) {
        toast.error("Error uploading attachment: " + err.message);
        return;
      }
    }
    // Send a text message (with the file URL)
    if (!isTyping && finalContent !== "") {
      setIsTyping(true);
      const newMessage = {
        communicationChannel: chatPartner?.communication_channel,
        clientID: chatPartner?.client_id,
        accountID: chatPartner?.account_id,
        pageID: chatPartner?.page_id,
        content: finalContent,

        fileType: attachment?.type,
        manager: user?.full_name || user?.phone || user?.email,
      };
      const sendMessageFunction =
        channelsWithSendingMessages[chatPartner?.communication_channel]
          .sendMessageFunction;
      try {
        await sendMessageFunction(newMessage).unwrap();
        await fetchChatMessages();
        setIsTyping(false);
      } catch (error) {
        toast.error("Error sending message");
        setIsTyping(false);
      }
    }
    setInputValue("");
  };

  return (
    <>
      {chatPartner && messagesList && (
        <div className="flex flex-col w-full h-[calc(100vh-3.4rem)] ml-[0.75rem] p-[1.7rem] rounded-[2.5rem] bg-light transition-all duration-800 max-[666px]:h-[calc(100vh-2rem-50px)] max-[666px]:ml-0 max-[666px]:p-[1.2rem]">
          {/* HEADER */}
          <HeaderChatSection
            chatPartner={chatPartner}
            botName={botName}
            pageContext={pageContext}
            isDebugMode={isDebugMode}
            isLoading={isLoading}
            isSmallScreen={isSmallScreen}
            handleToggleSwitch={handleToggleSwitch}
          />

          {/* CHAT MESSAGES */}
          <div className="overflow-auto h-screen md:h-[calc(100vh-70px)]">
            <div className="flex flex-col" ref={chatListRef}>
              {(!messagesList ||
                messagesList.length === 0 ||
                !messagesList[0]) && (
                <div className="flex flex-col">
                  <div className="bg-white shadow p-4 rounded-md text-gray-700 m-2 text-base">
                    <Typography variant="h3" className="subTitle">
                      Messages will appear soon...
                    </Typography>
                  </div>
                </div>
              )}
              {messagesList &&
                messagesList[0] &&
                messagesList.map((message, index) => (
                  <MessageItem
                    key={`${message.id}-${message.created_at}-${index}`}
                    handleClickSpecMessageIcon={handleClickSpecMessageIcon}
                    knowledgeHints={knowledgeHints}
                    message={message}
                    isSending={isSending}
                    handleClickSpecMessageSave={handleClickSpecMessageSave}
                    handleChangeSpecMessageTextarea={
                      handleChangeSpecMessageTextarea
                    }
                    setActivePulling={setActivePulling}
                    collapseStates={collapseStates}
                    onToggleCollapse={handleToggleCollapse}
                    bottomListRef={null}
                  />
                ))}
              {isTyping && (
                <div className="flex items-center p-2">
                  <Logo className="w-5 h-5 mr-2 text-gray-500" />
                  <p className="text-sm text-gray-600">Sending...</p>
                </div>
              )}
            </div>
            <Modal
              title="Error"
              description={errorText}
              setIsShowModal={setIsShowModal}
              isShowModal={isShowModal}
            />
          </div>

          {/* MESSAGE INPUT */}
          {Object.keys(channelsWithSendingMessages).includes(
            chatPartner?.communication_channel
          ) && (
            <div className="sticky bottom-0 mt-4 pt-4 border-t border-gray-300 bg-gray-100 flex items-center gap-4">
              <div className="relative flex items-center w-full py-4 px-6 border border-light-black2 rounded-[1rem] text-xl">
                <textarea
                  ref={textareaRef}
                  rows={1}
                  value={inputValue}
                  placeholder="Type your message..."
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyPress}
                  className="flex-1 resize-none outline-none bg-transparent text-purple text-xl pr-16"
                />

                <FileUploader
                  onFileSelected={handleFileSelect}
                  clientId={user?.id}
                />
              </div>
              <div
                onClick={handleSend}
                className={classNames(
                  "w-[6rem] h-[4rem] rounded-[1rem] flex items-center justify-center bg-gradient-blue-to-purple-angled text-white cursor-pointer",
                  { "opacity-50": isTyping || inputValue.trim() === "" }
                )}
              >
                <Send />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MessageList;
