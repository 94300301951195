import React, { FC, useMemo, useState } from "react";
import { Button, Modal, RoundedBox, Tabs, Title } from "../../../components";
import { CheckboxesBody } from "./CheckboxesBody";
import { DndColumns } from "./DndColumns";
import { CiBookmark } from "react-icons/ci";

type Props = {
  open: boolean;
  toggleOpen: () => void;
};

const defaultCheckboxes = [
  {
    value: "shows",
    label: "Shows",
  },
  {
    value: "clicks",
    label: "Clicks",
  },
  {
    value: "ad-cost",
    label: "AD Cost",
  },
  {
    value: "cpc",
    label: "CPC",
  },
  {
    value: "cpa",
    label: "CPA",
  },
  {
    value: "conversion-bot",
    label: "Conversion Bot",
  },
  {
    value: "conversion-crm",
    label: "Conversion CRM",
  },
  {
    value: "profit",
    label: "Profit",
  },
  {
    value: "average-check",
    label: "Average Check",
  },
  {
    value: "roi",
    label: "ROI",
  },
  {
    value: "roas",
    label: "ROAS",
  },
];

const savedLayoutsCheckboxes = [
  {
    value: "campaign-analysis-1",
    label: "Campaign Analysis 1",
  },
  {
    value: "campaign-analysis-2",
    label: "Campaign Analysis 2",
  },
  {
    value: "campaign-analysis-3",
    label: "Campaign Analysis 3",
  },
  {
    value: "campaign-analysis-4",
    label: "Campaign Analysis 4",
  },
  {
    value: "campaign-analysis-5",
    label: "Campaign Analysis 5",
  },
  {
    value: "campaign-analysis-6",
    label: "Campaign Analysis 6",
  },
  {
    value: "campaign-analysis-7",
    label: "Campaign Analysis 7",
  },
  {
    value: "campaign-analysis-8",
    label: "Campaign Analysis 8",
  },
];

export const CustomizeTableModal: FC<Props> = ({ open, toggleOpen }) => {
  const [dataCheckboxes, setDataCheckboxes] = useState(defaultCheckboxes);

  const tabsData = useMemo(
    () => [
      {
        label: "Default",
        value: "default",
        body: <CheckboxesBody checkboxes={dataCheckboxes} />,
      },
      {
        label: "Saved Layouts",
        value: "saved-layouts",
        body: (
          <CheckboxesBody checkboxes={savedLayoutsCheckboxes} withDropdown />
        ),
      },
    ],
    [dataCheckboxes]
  );

  const [activeTab, setActiveTab] = useState(tabsData[0].value);

  return (
    <Modal
      titleUnderline
      open={open}
      toggleOpen={toggleOpen}
      title={<Title text={"Customize table"} component="h2" />}
      actions={
        <div className="flex items-center justify-between w-full">
          <Button variant="secondary" className="w-auto">
            <span className="flex items-center gap-4 normal-case">
              <CiBookmark size="1.5rem" className="text-purple" />
              <span>Save current layout</span>
            </span>
          </Button>
          <div className="flex items-center gap-6">
            <Button
              className="normal-case whitespace-nowrap"
              variant="secondary"
            >
              Reset to Default
            </Button>
            <Button className="normal-case whitespace-nowrap">Apply</Button>
          </div>
        </div>
      }
    >
      <div className="flex gap-[1.5rem] h-[50rem]">
        <RoundedBox className="basis-[calc(55%-1.5rem)] !h-auto min-h-full">
          <Tabs
            data={tabsData}
            onChange={(activeTab) => setActiveTab(activeTab as string)}
          />
        </RoundedBox>
        {activeTab === tabsData[0].value && (
          <RoundedBox className="basis-[calc(45%-1.5rem)] !h-auto min-h-full">
            <DndColumns
              dataCheckboxes={dataCheckboxes}
              setDataCheckboxes={setDataCheckboxes}
            />
          </RoundedBox>
        )}
      </div>
    </Modal>
  );
};
