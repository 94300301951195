import { createSlice } from "@reduxjs/toolkit";
import {
  fetchClientStatusInCrm,
  fetchGeneralStatistic,
  fetchMessagesByChannel,
  fetchMessageUsage,
  fetchOrdersByChannel,
  fetchPlatformUsage,
  fetchRevenueByChannel,
} from "./thunks";

const initialState: DashboardState = {
  // general-statistic
  fetchingGeneralStatistic: false,
  generalStatistic: {},
  generalStatisticError: null,

  // revenue-by-channel
  fetchingRevenueByChannel: false,
  revenueByChannel: null,
  revenueByChannelError: null,

  // client-status-in-crm
  fetchingClientStatusInCrm: false,
  clientStatusInCrm: null,
  clientStatusInCrmError: null,

  // orders-by-channel
  fetchingOrdersByChannel: false,
  ordersByChannel: null,
  ordersByChannelError: null,

  // messages-by-channel
  fetchingMessagesByChannel: false,
  messagesByChannel: null,
  messagesByChannelError: null,

  // message-usage
  fetchingMessageUsage: false,
  messageUsage: null,
  messageUsageError: null,

  // platform-usage
  fetchingPlatformUsage: false,
  platformUsage: null,
  platformUsageError: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // general-statistic
      .addCase(fetchGeneralStatistic.pending, (state) => {
        state.fetchingGeneralStatistic = true;
      })
      .addCase(fetchGeneralStatistic.fulfilled, (state, action) => {
        state.fetchingGeneralStatistic = false;
        state.generalStatisticError = null;
        state.generalStatistic = action.payload;
      })
      .addCase(fetchGeneralStatistic.rejected, (state, action) => {
        state.fetchingGeneralStatistic = false;
        state.generalStatisticError = action.error;
      })

      // revenue-by-channel
      .addCase(fetchRevenueByChannel.pending, (state) => {
        state.fetchingRevenueByChannel = true;
      })
      .addCase(fetchRevenueByChannel.fulfilled, (state, action) => {
        state.fetchingRevenueByChannel = false;
        state.revenueByChannelError = null;
        state.revenueByChannel = action.payload;
      })
      .addCase(fetchRevenueByChannel.rejected, (state, action) => {
        state.fetchingRevenueByChannel = false;
        state.revenueByChannelError = action.error;
      })

      // client-status-in-crm
      .addCase(fetchClientStatusInCrm.pending, (state) => {
        state.fetchingClientStatusInCrm = true;
      })
      .addCase(fetchClientStatusInCrm.fulfilled, (state, action) => {
        state.fetchingClientStatusInCrm = false;
        state.clientStatusInCrmError = null;
        state.clientStatusInCrm = action.payload;
      })
      .addCase(fetchClientStatusInCrm.rejected, (state, action) => {
        state.fetchingClientStatusInCrm = false;
        state.clientStatusInCrmError = action.error;
      })

      // orders-by-channel
      .addCase(fetchOrdersByChannel.pending, (state) => {
        state.fetchingOrdersByChannel = true;
      })
      .addCase(fetchOrdersByChannel.fulfilled, (state, action) => {
        state.fetchingOrdersByChannel = false;
        state.ordersByChannelError = null;
        state.ordersByChannel = action.payload;
      })
      .addCase(fetchOrdersByChannel.rejected, (state, action) => {
        state.fetchingOrdersByChannel = false;
        state.ordersByChannelError = action.error;
      })

      // messages-by-channel
      .addCase(fetchMessagesByChannel.pending, (state) => {
        state.fetchingMessagesByChannel = true;
      })
      .addCase(fetchMessagesByChannel.fulfilled, (state, action) => {
        state.fetchingMessagesByChannel = false;
        state.messagesByChannelError = null;
        state.messagesByChannel = action.payload;
      })
      .addCase(fetchMessagesByChannel.rejected, (state, action) => {
        state.fetchingMessagesByChannel = false;
        state.messagesByChannelError = action.error;
      })

      // message-usage
      .addCase(fetchMessageUsage.pending, (state) => {
        state.fetchingMessageUsage = true;
      })
      .addCase(fetchMessageUsage.fulfilled, (state, action) => {
        state.fetchingMessageUsage = false;
        state.messageUsageError = null;
        state.messageUsage = action.payload;
      })
      .addCase(fetchMessageUsage.rejected, (state, action) => {
        state.fetchingMessageUsage = false;
        state.messageUsageError = action.error;
      })

      // platform-usage
      .addCase(fetchPlatformUsage.pending, (state) => {
        state.fetchingPlatformUsage = true;
      })
      .addCase(fetchPlatformUsage.fulfilled, (state, action) => {
        state.fetchingPlatformUsage = false;
        state.platformUsageError = null;
        state.platformUsage = action.payload;
      })
      .addCase(fetchPlatformUsage.rejected, (state, action) => {
        state.fetchingPlatformUsage = false;
        state.platformUsageError = action.error;
      });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
