import React from "react";
import { components } from "react-select";
import styles from "./CustomStyles.module.scss";

export const CustomValueContainer = ({ children, ...props }) => {
  const { hasValue, getValue, selectProps } = props;
  const selectedItems = getValue();
  const count = selectedItems.length;

  if (hasValue && selectedItems.length > 0) {
    switch (selectProps.selectType) {
      case "assistants":
        return (
          <components.ValueContainer
            {...props}
            className={styles.selectedItems}
          >
            {children}
          </components.ValueContainer>
        );

      case "funnelStatus":
        return (
          <components.ValueContainer
            {...props}
            className={styles.valueContainer}
          >
            <div className={styles.selectedItems}>
              Funnel status<span>{count}</span>
            </div>
            {children[1]}
          </components.ValueContainer>
        );

      case "managers":
        return (
          <components.ValueContainer
            {...props}
            className={styles.valueContainer}
          >
            <div className={styles.selectedItems}>
              Managers<span>{count}</span>
            </div>
            {children[1]}
          </components.ValueContainer>
        );

      case "date":
        return (
          <components.ValueContainer
            {...props}
            className={styles.valueContainer}
          >
            <div className={styles.selectedItems}>
              {selectedItems[0]?.label || ""}
            </div>
            {children[1]}
          </components.ValueContainer>
        );

      default:
        return (
          <components.ValueContainer
            {...props}
            className={styles.valueContainer}
          >
            {children}
          </components.ValueContainer>
        );
    }
  }

  return (
    <components.ValueContainer {...props} className={styles.valueContainer}>
      {children}
    </components.ValueContainer>
  );
};
