import { GoogleDoc } from "../assets/icons/GoogleDoc";
import { GoogleSheets } from "../assets/icons/GoogleSheets";

import {
  SalesScript,
  ObjectionManagement,
  DialogExamples,
  ProductBase,
  Faq,
  RulesAndTerms,
  Delivery,
  SiteLink,
  ProductLink,
  GoogleDocsLink,
  GoogleSheetsLink,
  ChooseYourUnit,
  WordPress,
  OpenCart,
} from "../assets/icons/knowledgeBaseIcons";

import { XMLData } from "../assets/icons/knowledgeBaseIcons/XMLData";
import { KnowledgeBaseItemsDefaultList } from "../types/knowledgeBase";

export const WORDPRESS_CMS = "WordPress CMS";
export const OPENCART_CMS = "OpenCart CMS";

export const KNOWLEDGEBASE_DEFAULT: KnowledgeBaseItemsDefaultList[] = [
  {
    id: "2dssnd",
    integration: "Document Information",
    description: "For searching on information",
    icon: <GoogleDoc />,
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file",
    isWorking: true,
  },
  {
    id: "3dssnd",
    integration: "Spreadsheet Inventory",
    description: "For searching on shop inventory",
    icon: <GoogleSheets />,
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file",
    isWorking: true,
  },
  {
    id: "4dssnd",
    integration: "Sales script",
    description: "For creating consistent and effective sales communication.",
    icon: <SalesScript />,
    isWorking: true,
  },
  {
    id: "5dssnd",
    integration: "Objection management",
    description:
      "For handling and overcoming customer objections. Essential for sales.",
    icon: <ObjectionManagement />,
    isWorking: true,
  },
  {
    id: "6dssnd",
    integration: "Successful dialog examples",
    description:
      "For improving communication skills and customer satisfaction.",
    icon: <DialogExamples />,
    isWorking: true,
  },
  {
    id: "7dssnd",
    integration: "Product Base",
    description: "For managing inventory and tracking sales data.",
    icon: <ProductBase />,
    isWorking: false,
  },
  {
    id: "14dssnd",
    integration: WORDPRESS_CMS,
    description: "Plugin for searching products",
    icon: <WordPress />,
    isWorking: true,
    isGoogleAuth: false,
  },
  {
    id: "15dssnd",
    integration: OPENCART_CMS,
    description: "Plugin for searching products",
    icon: <OpenCart />,
    isWorking: true,
    isGoogleAuth: false,
  },
  {
    id: "105dssnd",
    integration: "XML Product Feed",
    description: "For sharing store products",
    icon: <XMLData />,
    isWorking: true,
    isGoogleAuth: false,
  },
  {
    id: "8dssnd",
    integration: "FAQ",
    description: "For answering common questions and providing information.",
    icon: <Faq />,
    isWorking: true,
  },
  {
    id: "9dssnd",
    integration: "Rules and Terms",
    description: "For setting guidelines and legal protection. For business.",
    icon: <RulesAndTerms />,
    isWorking: true,
  },
  {
    id: "91dssnd",
    integration: "Delivery",
    description:
      "For fast and efficient transportation of goods. Needed for business.",
    icon: <Delivery />,
    isWorking: true,
  },
  {
    id: "10dssnd",
    integration: "Site link",
    description: "For driving traffic and increasing online visibility.",
    icon: <SiteLink />,
    isWorking: true,
    isGoogleAuth: false,
  },
  {
    id: "11dssnd",
    integration: "Product link",
    description: "For sharing product information and driving sales online.",
    icon: <ProductLink />,
    isWorking: false,
  },
  {
    id: "12dssnd",
    integration: "Google docs Link",
    description:
      "For collaborative document editing and sharing. Needed for business.",
    icon: <GoogleDocsLink />,
    isWorking: false,
  },
  {
    id: "13dssnd",
    integration: "Google Sheets Link",
    description: "For collaborative data analysis and sharing.",
    icon: <GoogleSheetsLink />,
    isWorking: false,
  },
];

export const KNOWLEDGEBASE: (KnowledgeBaseItemsDefaultList & {
  connected: boolean;
})[] = KNOWLEDGEBASE_DEFAULT.map((item) => ({
  isGoogleAuth: true,
  connected: false,
  ...item,
}));

export const DOCUMENT_TYPES: string[] = [
  "document information",
  "sales script",
  "objection management",
  "successful dialog examples",
  "faq",
  "rules and terms",
];
