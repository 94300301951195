import React from "react";
import { components } from "react-select";
import styles from "./CustomFilterMenuList.module.scss";

// Custom MenuList with Apply та Clear all buttons
export const CustomFilterMenuList = ({
  children,
  onApply,
  onCleanAll,
  onCloseMenu,
  ...props
}) => {
  const handleApply = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onApply === "function") {
      onApply();
      onCloseMenu();
    }
  };

  const handleCleanAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onCleanAll === "function") {
      onCleanAll();
      onCloseMenu();
    }
  };

  return (
    <components.MenuList {...props}>
      <div className={styles.menuList}>
        {children}
        <div className={styles.buttonContainer}>
          <button
            type="button"
            className={styles.applyButton}
            onClick={handleApply}
            disabled={
              !props.selectProps.value ||
              (Array.isArray(props.selectProps.value) &&
                props.selectProps.value.length === 0)
            }
          >
            Apply
          </button>
          <button
            type="button"
            className={styles.cleanAllButton}
            onClick={handleCleanAll}
            disabled={
              !props.selectProps.value ||
              (Array.isArray(props.selectProps.value) &&
                props.selectProps.value.length === 0)
            }
          >
            Clear all
          </button>
        </div>
      </div>
    </components.MenuList>
  );
};
