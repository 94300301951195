import React, { FC, ReactNode } from "react";
import { Button } from "../../../redesign/components";
import { Loader } from "../../../components/Loader";
import { KnowledgeBaseCardProps } from "../../../types/knowledgeBase";

export const KnowledgeBaseCard: FC<KnowledgeBaseCardProps> = ({
  children,
  icon,
  title,
  description,
  selectedId,
  btnText,
  isLoading,
  clickHandler,
  id,
  subscriptionPlan,
}) => {
  return (
    <div
      className="
    flex flex-col items-center justify-between bg-white min-h-[22.7rem] w-[23.25rem] rounded-[1.25rem] pt-[3rem] pr-[1.5rem] pb-[1.5rem] pl-[1.5rem]
    md:pt-[4rem] md:pr-[1.2rem] md:pb-[1.2rem] md:pl-[1.2rem] md:h-[22.5rem] md:w-full"
    >
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader width={40} height={40} />
        </div>
      ) : (
        <>
          {/* Icon or children */}
          <div className="w-16 h-16 flex items-center justify-center">
            {icon ? icon : children}
          </div>
          {/* Title */}
          <div className="text-center font-semibold text-[1.5rem] sm:text-[1.25rem] leading-[1.6rem] mb-1">
            {title}
          </div>
          {/* Description */}
          {description && (
            <div className="text-center font-normal text-[1.5rem] sm:text-[1.25rem] leading-[1.6rem] mb-4">
              {description}
            </div>
          )}
          {/* Title Button */}
          <Button
            style={{ width: "100%", boxShadow: "none" }}
            className="
            m-0 px-12 py-3 rounded-[0.5rem]
            bg-gradient-blue-to-purple-angled
            text-white uppercase font-semibold
            text-xl leading-[1.7rem]
            shadow-md transition-all
            duration-200 hover:shadow-lg
          "
            onClick={() => clickHandler(id)}
            disabled={id === selectedId || subscriptionPlan === null}
          >
            {btnText}
          </Button>
        </>
      )}
    </div>
  );
};
