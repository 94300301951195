import { memo, useEffect } from "react";
import {
  Button,
  RoundedBox,
  Skeleton,
  SomethingWentWrong,
  Title,
} from "../../../../components";
import { LuArrowUpRight } from "react-icons/lu";

import { useReduxStore, useAppDispatch } from "../../../../hooks";
import { fetchMessagesByChannel } from "../../../../../store/slices/dashboard";
import { Chart } from "./Chart";

export const MessagesByChannel = memo(() => {
  const { dashboard } = useReduxStore();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMessagesByChannel());
  }, [dispatch]);

  return (
    <RoundedBox className="column">
      <div className="flex items-center justify-between mb-6">
        <Title text="Messages by Channel" component="h2" />
        <Button variant="rounded" className="w-fit p-2 rounded-xl">
          <LuArrowUpRight className="w-[1.5rem] h-[1.5rem]" />
        </Button>
      </div>

      {/* Loading view */}
      {dashboard.fetchingMessagesByChannel && (
        <Skeleton
          length={1}
          classNameWrapper="w-full h-full"
          classNameItem="!basis-full"
        />
      )}

      {/* Success data view */}
      {!dashboard.fetchingMessagesByChannel &&
        !dashboard.messagesByChannelError &&
        Boolean(dashboard.messagesByChannel) &&
        dashboard.messagesByChannel.length > 0 && <Chart />}

      {/* No data view */}
      {!dashboard.fetchingMessagesByChannel &&
        !dashboard.messagesByChannelError &&
        Boolean(dashboard.messagesByChannel) &&
        dashboard.messagesByChannel.length === 0 && (
          <div className="w-full h-full center">
            <p>No Data</p>
          </div>
        )}

      {/* Error view */}
      {!dashboard.fetchingMessagesByChannel &&
        dashboard.messagesByChannelError && (
          <SomethingWentWrong
            callback={() => dispatch(fetchMessagesByChannel())}
          />
        )}
    </RoundedBox>
  );
});
