import { useCallback, useState, ChangeEvent } from "react";
import {
  SITE_URL_REGEX,
  PLUGIN_NAME_REGEX,
} from "../../constants/validationScheme";

export const useCMSForm = (initialPluginName = "feedv1") => {
  const [domain, setDomain] = useState<string>("");
  const [isDomainValid, setIsDomainValid] = useState<boolean>(true);
  const [pluginName, setPluginName] = useState<string>(initialPluginName);
  const [isPluginNameValid, setIsPluginNameValid] = useState<boolean>(true);

  const handleDomainChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setDomain(val);
    setIsDomainValid(SITE_URL_REGEX.test(val));
  }, []);

  const handlePluginNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.trim() === "") {
        setIsPluginNameValid(false);
        setPluginName(value);
      } else if (PLUGIN_NAME_REGEX.test(value)) {
        // Replace spaces with dashes
        setPluginName(value.replaceAll(" ", "-"));
        setIsPluginNameValid(true);
      }
    },
    []
  );

  return {
    domain,
    isDomainValid,
    setDomain,
    pluginName,
    setPluginName,
    isPluginNameValid,
    handleDomainChange,
    handlePluginNameChange,
  };
};
