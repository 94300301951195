import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

import { getAFormOfPayment } from "../../../../../service/mychatbot";
import { Button, Modal, Title } from "../../../../components";
import { useAppDispatch, useReduxStore } from "../../../../hooks";
import { fetchAccountById } from "../../../../../store/slices/account";

const buttonsValues = [1, 2, 5, 10, 20];

type Props = {
  open: boolean;
  toggleOpen: () => void;
};

export const PaymentModal: FC<Props> = ({ open, toggleOpen }) => {
  const { auth, account } = useReduxStore();
  const dispatch = useAppDispatch();

  const [htmlString, setHtmlString] = useState("");
  const [quantityTokens, setQuantityTokens] = useState(1);
  const [disabled, setDisabled] = useState(false);

  const wFormRef = useRef(null);

  useEffect(() => {
    if (!auth.user?.account_id) return;

    dispatch(fetchAccountById(auth.user.account_id));
  }, [auth.user?.account_id, dispatch]);

  useEffect(() => {
    if (htmlString && wFormRef.current) {
      const form = wFormRef.current.querySelector(".wfpform");

      if (form) {
        form.submit();
      }
    }
  }, [htmlString]);

  useEffect(() => {
    if (!open) return setHtmlString("");
  }, [open]);

  const addAForm = useCallback(async () => {
    try {
      const formMarkup = await getAFormOfPayment({
        account: account?.data?.id,
        amount: quantityTokens,
      });

      setHtmlString(formMarkup);
    } catch (error) {
      toast.error(error);
      setDisabled(false);
    }
  }, [account?.data?.id, quantityTokens]);

  const onProceedToPaymentClick = useCallback(async () => {
    setDisabled(true);
    await addAForm();
  }, [addAForm]);

  const price = useMemo(() => {
    if (!account?.data) return 0;

    return account?.data?.token_price * quantityTokens;
  }, [account, quantityTokens]);

  return (
    <>
      <div
        className={"w-0 h-0 overflow-hidden opacity-0 absolute m-[-1px]"}
        dangerouslySetInnerHTML={{ __html: htmlString }}
        ref={wFormRef}
      />
      <Modal
        title={<Title text={"AI Messages"} component="h2" />}
        open={open}
        toggleOpen={toggleOpen}
        classNameBody="center"
      >
        <div className="column h-full w-1/2 gap-10">
          <div className="flex flex-nowrap gap-2">
            {buttonsValues.map((value) => (
              <Button
                variant="rounded"
                key={value}
                disabled={disabled}
                className={classNames(
                  "hover:bg-purple hover:text-light transition-all text-[1.2rem]",
                  {
                    "bg-purple text-light": quantityTokens === value,
                  }
                )}
                onClick={() => setQuantityTokens(value)}
              >
                {value * 100}
              </Button>
            ))}
          </div>
          <div className="text-center text-[1.5rem] text-gray-500 font-medium">
            Total cost for {quantityTokens * 100} AI Messages would be ${price}
          </div>
          <Button
            className="max-w-fit mx-auto px-8 py-4 text-[1.2rem] rounded-[1.5rem]"
            disabled={disabled}
            onClick={onProceedToPaymentClick}
          >
            Proceed to payment
          </Button>
        </div>
      </Modal>
    </>
  );
};
