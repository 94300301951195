import { useReduxStore } from ".";

import { LANGUAGE } from "../../constants/configuration";

export const useSelectedLanguage = () => {
  const { assistant } = useReduxStore();

  const foundAssistant = assistant.listOfAssistants.find(
    ({ id }: { id: string }) => id === assistant.selectedAssistant.value
  );

  return foundAssistant
    ? LANGUAGE.find(({ value }) => value === foundAssistant?.language)
    : { value: 1, label: "English" };
};
