import React, { memo, useEffect, useMemo, useState } from "react";
import { IconCalendar } from "../../../assets";
import {
  Checkbox,
  Dropdown,
  RoundedBox,
  Skeleton,
  SomethingWentWrong,
  Title,
} from "../../../components";

import {
  Instagram,
  Messenger,
  Telegram,
  Viber,
  WhatsApp,
} from "../../../../assets/icons";
import { handleChangeCheckboxHelper } from "../../../utils";
import { SocialHorizontalBar } from "./SocialHorizontalBar";
import { useAppDispatch, useReduxStore } from "../../../hooks";
import { fetchPlatformUsage } from "../../../../store/slices/dashboard";

const iconsMap = {
  viber: <Viber />,
  instagram: <Instagram />,
  whatsapp: <WhatsApp />,
  telegram: <Telegram />,
  messenger: <Messenger />,
};

const formatHorizontalBarData = (channels: DashboardPlatformUsage[]) =>
  channels.map((channel) => ({
    id: channel.platform.toLowerCase(),
    icon: iconsMap[channel.platform.toLowerCase()],
    name: channel.platform,
    percent: channel.percentage,
  }));

export const PlatformUsage = memo(() => {
  const { dashboard } = useReduxStore();
  const dispatch = useAppDispatch();

  const [dates, setDates] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchPlatformUsage());
  }, [dispatch]);

  const data = useMemo(
    () => formatHorizontalBarData(dashboard.platformUsage || []),
    [dashboard.platformUsage]
  );

  return (
    <RoundedBox className="column">
      <div className="flex items-center justify-between mb-6">
        <Title text="Platform Usage" component="h2" />
        <Dropdown
          icon={<IconCalendar />}
          placeholder="Date"
          body={
            <div className="column gap-4">
              <Checkbox
                label="3 days"
                value="platform-usage-3days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("platform-usage-3days")}
              />
              <Checkbox
                label="7 days"
                value="platform-usage-7days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("platform-usage-7days")}
              />
              <Checkbox
                label="30 days"
                value="platform-usage-30days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("platform-usage-30days")}
              />
              <Checkbox
                label="All time"
                value="platform-usage-all-time"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("platform-usage-all-time")}
              />
              <Checkbox
                label="Custom Range"
                value="platform-usage-custom-range"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("platform-usage-custom-range")}
                disabled
              />
            </div>
          }
          classNameMenu="min-w-[15.5rem] left-auto right-0"
        />
      </div>

      {/* Loading view */}
      {dashboard.fetchingPlatformUsage && (
        <Skeleton
          length={1}
          classNameWrapper="w-full h-full"
          classNameItem="!basis-full"
        />
      )}

      {/* Success data view */}
      {!dashboard.fetchingPlatformUsage &&
        !dashboard.platformUsageError &&
        dashboard.platformUsage &&
        dashboard.platformUsage.length > 0 && (
          <div className="mt-auto">
            <SocialHorizontalBar data={data} classNameList="gap-[1.3rem]" />
          </div>
        )}

      {/* No data view */}
      {!dashboard.fetchingPlatformUsage &&
        !dashboard.platformUsageError &&
        dashboard.platformUsage &&
        dashboard.platformUsage.length === 0 && (
          <div className="w-full h-full center">
            <p>No Data</p>
          </div>
        )}

      {/* Error view */}
      {!dashboard.fetchingPlatformUsage && dashboard.platformUsageError && (
        <SomethingWentWrong callback={() => dispatch(fetchPlatformUsage())} />
      )}
    </RoundedBox>
  );
});
