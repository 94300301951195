import { useReduxStore } from "../../../hooks";
import { useAIConfigurationState } from "../context";
import { useMessagesQuery } from "../../../../store/api";

import { RoundedBox, Title } from "../../../components";

import { Chat } from "./Chat";
import { SendMessageAssistantForm } from "./SendMessageAssistantForm";

export const BotAssistant = () => {
  const { auth } = useReduxStore();
  const { assistant } = useAIConfigurationState();

  const { refetch } = useMessagesQuery({
    id: auth.user?.account_id,
    assistantId: assistant?.id,
  });

  return (
    <RoundedBox
      variant="default"
      className="column px-0 h-full md:max-h-[calc(100vh-3rem)]"
    >
      <Title
        text="Test your assistant"
        component="h1"
        underline
        className="px-6 pb-6"
      />
      {auth.user?.account_id && assistant?.id && (
        <Chat refetch={refetch as () => void} />
      )}
      <SendMessageAssistantForm />
    </RoundedBox>
  );
};
