import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconTotalScale: FC<IconProps> = ({ size = 22, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4C1 2.34315 4.13401 1 8 1C11.866 1 15 2.34315 15 4M1 4C1 4.76835 1.67399 5.46924 2.78242 6C4.06417 6.61375 5.92683 7 8 7C10.0732 7 11.9358 6.61375 13.2176 6C14.326 5.46924 15 4.76835 15 4M1 4V8M15 4V7.03038M1 12C1 12.7684 1.67399 13.4692 2.78242 14C3.85425 14.5132 5.33232 14.8674 7 14.9696M1 12V8M1 12V16C1 17.5686 3.80917 18.8561 7.38908 18.9887M1 8C1 8.76835 1.67399 9.46924 2.78242 10C3.85425 10.5132 5.33232 10.8674 7 10.9696M7 10C7 8.34315 10.134 7 14 7C14.3395 7 14.6734 7.01036 15 7.03038M7 10C7 10.7684 7.67399 11.4692 8.78242 12C10.0642 12.6137 11.9268 13 14 13C16.0732 13 17.9358 12.6137 19.2176 12C20.326 11.4692 21 10.7684 21 10M7 10V10.9696M21 10V14M21 10C21 8.48865 18.3923 7.23834 15 7.03038M7 14C7 14.7684 7.67399 15.4692 8.78242 16C10.0642 16.6137 11.9268 17 14 17C16.0732 17 17.9358 16.6137 19.2176 16C20.326 15.4692 21 14.7684 21 14M7 14V14.9696M7 14V10.9696M21 14V18C21 18.7684 20.326 19.4692 19.2176 20C17.9358 20.6137 16.0732 21 14 21C11.9268 21 10.0642 20.6137 8.78242 20C8.17375 19.7085 7.69608 19.3658 7.38908 18.9887M7.38908 18.9887C7.13701 18.6791 7 18.3464 7 18V14.9696"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
