import React, {
  FC,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from "react";
import { Input, InputProps } from "@material-tailwind/react";
import { FormikValues } from "formik";
import classNames from "classnames";

type Props = {
  onChangeText?: (value: string) => void;
  icon?: ReactNode;
  ref?: Ref<HTMLInputElement>;
  inputLabel?: string;
  formik?: FormikValues;
  // showErrorText?: boolean;
  classNameWrapper?: string;
  showError?: boolean;
} & Omit<InputProps, "ref"> &
  Omit<InputHTMLAttributes<HTMLInputElement>, "color">;

export const TextField: FC<Props> = ({
  onChangeText,
  onChange,
  variant = "outlined",
  className = "",
  icon = null,
  inputLabel,
  formik = {},
  // showErrorText = true,
  classNameWrapper = "",
  showError = true,
  ...props
}) => {
  const errorMessage = useMemo(
    () =>
      props.name && formik?.errors ? formik?.errors[props.name as string] : "",
    [formik?.errors, props.name]
  );

  const isError = useMemo(
    () =>
      errorMessage && formik?.touched && props.name
        ? formik?.touched[props.name as string]
        : "",
    [errorMessage, formik?.touched, props.name]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeText) {
        onChangeText(event.target.value);
      }

      if (onChange) {
        onChange(event);
      }

      if (formik?.handleChange) {
        formik.handleChange(event);
      }
    },
    [onChange, onChangeText, formik]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (props.onBlur) {
        props.onBlur(event);
      }

      if (formik?.handleBlur) {
        formik.handleBlur(event);
      }
    },
    [formik, props]
  );

  return (
    <div
      className={classNames(
        "relative w-full",
        {
          "column gap-2": Boolean(inputLabel),
        },
        classNameWrapper
      )}
    >
      {inputLabel && (
        <p
          className={classNames("body-1 font-medium leading-[110%]", {
            "text-danger": isError && showError,
          })}
        >
          {inputLabel}
        </p>
      )}

      {icon && (
        <span className="absolute top-1/2 translate-y-[-50%] left-3 center z-10 pointer-events-none">
          {icon}
        </span>
      )}

      <Input
        className={classNames(
          "!bg-gray px-12 py-4 !rounded-[1.3rem] text-[1rem]",
          className,
          {
            "p-2": !Boolean(icon),
            "placeholder:text-gray-500 placeholder:opacity-100":
              props.placeholder !== "",
            "!border-danger placeholder:text-danger text-danger":
              isError && showError,
          }
        )}
        variant={variant}
        onChange={handleChange}
        onBlur={handleBlur}
        labelProps={{
          className:
            Boolean(props.placeholder) || Boolean(inputLabel)
              ? "hidden"
              : "left-12 text-gray-500 !text-[1rem] before:hidden after:hidden",
        }}
        value={props.value || formik?.values?.[props?.name as string]}
        {...props}
      />

      {isError && showError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};
