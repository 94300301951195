import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import classNames from "classnames";
import { toast } from "react-toastify";
import supabase from "../../../../supabase";
import {
  useSetupWebhookMutation,
  useValidateTokenMutation,
} from "../../../../store/api";
import { setOnboarding } from "../../../../store/slices/onboardingSlice";

import { Title } from "../../../../components/Title";
import { Input } from "../../../../components/Input";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";

import styles from "./Viber.module.scss";
import { EnableBotForm } from "../EnableBotForm";
import { ReplayDelayInput } from "../ReplyDelayInput";

export const Viber = ({ channelData, fetchChannels, assistantId }) => {
  const channel = channelData[0];

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [token, setToken] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [savedToken, setSavedToken] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [viberAccountData, setViberAccountData] = useState(null);

  const [validateToken] = useValidateTokenMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  const fetchViberAccount = useCallback(async (viberAuthToken) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `/viber-account-info?token=${viberAuthToken}`
      );

      return response.data;
    } catch (error) {
      toast.error(error?.message || "Failed to fetch viber account info");
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!(channel?.is_on && channel?.access_token)) return;

    fetchViberAccount(channel?.access_token).then(setViberAccountData);
  }, [fetchViberAccount, channel?.is_on, channel?.access_token]);

  const validateViberToken = (token) => {
    return true;
  };

  const handleViberChange = (e) => {
    const token = e.target.value;
    if (!validateViberToken(token)) {
      setIsError(true);
      setErrorText("Invalid token");
    } else {
      setIsError(false);
      setErrorText("");
    }
    if (!token) {
      setIsError(false);
      setErrorText("");
    }
    setToken(token);
  };

  const validateAndSetToken = async () => {
    const { data, error } = await validateToken({
      platform: "Viber",
      token: token,
    });
    if (error) {
      throw new Error("Failed to validate token");
    }
    if (data.status === "error") {
      throw new Error("Invalid access token");
    }
  };

  const setupChannelWebhook = async (channel) => {
    const { error } = await setupWebhook({
      channel: channel,
      account_id: user.account_id,
      page_id: token.slice(0, 15),
      assistant_id: assistantId,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const handleToken = async () => {
    try {
      setIsError(false);
      setErrorText("");
      setIsLoading(true);
      await validateAndSetToken();
      if (!savedToken) {
        const { error } = await supabase.from("channels").upsert([
          {
            account_id: user.account_id,
            access_token: token,
            page_id: token.slice(0, 15),
            is_on: false,
            communication_channel: "Viber",
            assistant_id: assistantId,
          },
        ]);
        if (error) {
          throw new Error("Failed to insert new channel");
        } else {
          if (step === 4) {
            const { error } = await supabase
              .from("onboardings")
              .update({ step: 5 })
              .match({ account_id: user.account_id });
            if (error) {
              throw new Error("Failed to update data");
            }
            dispatch(setOnboarding(5));
          }
        }
        setSavedToken(token);
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ access_token: token, is_on: false })
          .match({
            account_id: user.account_id,
            communication_channel: "Viber",
          });
        if (error) {
          throw new Error("Failed to update channel");
        }
      }
      await fetchChannels();
    } catch (error) {
      setErrorText(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsCheck(channel?.is_on ?? false);
    setToken(channel?.access_token ?? "");
    setSavedToken(channel?.access_token ?? "");
  }, [channel]);

  useEffect(() => {
    if (token !== savedToken) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [token, savedToken]);

  return (
    <>
      <Title title={"Viber"} />

      <EnableBotForm
        disabled={savedToken === ""}
        channel={channel}
        matchConfig={{
          account_id: user.account_id,
          communication_channel: "Viber",
          assistant_id: assistantId,
        }}
        setupChannelWebhook={async () => await setupChannelWebhook("Viber")}
        setIsLoading={setIsLoading}
        fetchChannels={fetchChannels}
      >
        <ReplayDelayInput
          disabled={savedToken === ""}
          channelName="Viber"
          channel={channel}
          user={user}
          fetchChannels={fetchChannels}
          assistantId={assistantId}
        />
      </EnableBotForm>

      <div className={styles.connectionBot}>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader height={40} width={40} />
          </div>
        ) : (
          <div className="column h-full">
            <div className={styles.connectionBotText}>
              Create viber bot using{" "}
              <a
                target="_blank"
                href={"https://partners.viber.com/account/create-bot-account"}
                rel="noreferrer"
              >
                create-bot-account page
              </a>{" "}
              and paste your access token.
            </div>
            <div className="column mb-6">
              <div className={styles.token}>Token</div>
              <Input
                typeInput={"connected"}
                placeholder={
                  "5191954777a7e47b-ee906023330c0798-fdb7ca33dbe46aaf"
                }
                value={token}
                onChange={handleViberChange}
                error={isError}
                errorText={errorText}
              />
            </div>
            {viberAccountData && channel?.is_on && (
              <div className="flex items-center gap-4">
                <img
                  className="w-12 h-12"
                  src={viberAccountData?.icon}
                  alt={viberAccountData?.name}
                />
                <p className="text-[1.4rem] font-medium">
                  {viberAccountData?.name}
                </p>
              </div>
            )}
            {token !== "" && (
              <div className={classNames(styles.saveChanges, "mt-auto")}>
                <Button
                  onClick={handleToken}
                  className={`${styles.saveOptimize} ${
                    isError || isDisabled ? styles.disabled : ""
                  }`}
                  title={"SAVE CHANGES"}
                  disabled={isError || isDisabled}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
