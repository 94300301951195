import React, { useEffect, useState } from "react";
import { Button, Title } from "../../../../../redesign/components";
import { toast } from "react-toastify";
import { AddWhatsAppTemplateModal } from "../../../../../components/popups/AddWhatsAppTemplateModal/AddWhatsAppTemplateModal";
import { DeletePopup } from "../../../../../components/popups/DeletePopup";
import { HiOutlineDocumentCheck, HiOutlineTrash } from "react-icons/hi2";
import { FaRegEdit } from "react-icons/fa";

export const WhatsAppTemplates = ({ channel, onSave }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // Mode (create/edit)
  const [editMode, setEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  // Form fields in a modal
  const [templateName, setTemplateName] = useState("");
  const [messageContent, setMessageContent] = useState("");

  // Extracting an array of templates from channel.metadata
  useEffect(() => {
    if (channel?.metadata?.templates?.length) {
      setTemplates(channel.metadata.templates);
    } else {
      setTemplates([]);
    }
    setSelectedTemplateId(null); // reset template selection when changing channel
  }, [channel]);

  const selectedTemplate = templates.find((t) => t.id === selectedTemplateId);

  const handleOpenCreateModal = () => {
    setEditMode(false);
    setTemplateName("");
    setMessageContent("");
    setIsModalOpen(true);
  };

  const handleOpenEditModal = (template) => {
    setEditMode(true);
    setTemplateName(template.templateName);
    setMessageContent(template.messageContent);
    setIsModalOpen(true);
  };

  // Saving a template (new or edited)
  const handleSaveTemplate = async () => {
    if (!templateName.trim()) {
      toast.error("Template Name is required");
      return;
    }
    if (!messageContent.trim()) {
      toast.error("Message Content is required");
      return;
    }

    let updatedTemplates;
    if (editMode && selectedTemplate) {
      updatedTemplates = templates.map((t) =>
        t.id === selectedTemplate.id
          ? { ...t, templateName, messageContent }
          : t
      );
    } else {
      const newTemplate = {
        id: Date.now(),
        templateName,
        messageContent,
      };
      updatedTemplates = [...templates, newTemplate];
    }

    setTemplates(updatedTemplates);
    setIsModalOpen(false);

    //
    const updatedMetadata = {
      ...channel.metadata,
      templates: updatedTemplates,
    };
    try {
      await onSave(updatedMetadata);
      toast.success("Template successfully saved to channel!");
    } catch (error) {
      toast.error("Failed to update channel metadata");
    }
  };

  // Open delete confirmation modal
  const handleOpenDeleteModal = (template) => {
    setTemplateToDelete(template);
    setIsDeleteModalOpen(true);
  };

  // Confirm delete and remove from database
  const handleConfirmDelete = async () => {
    if (!templateToDelete) return;

    const updatedTemplates = templates.filter(
      (t) => t.id !== templateToDelete.id
    );
    setTemplates(updatedTemplates);
    setSelectedTemplateId(null);
    setIsDeleteModalOpen(false);

    const updatedMetadata = {
      ...channel.metadata,
      templates: updatedTemplates,
    };
    try {
      await onSave(updatedMetadata);
      toast.success("Template deleted and saved!");
    } catch (error) {
      toast.error("Failed to update channel metadata after deleting template");
    }
  };

  return (
    <div className="flex gap-4 mt-4 flex-1">
      {/* Left column (template list) */}
      <div className="flex flex-1 flex-col border rounded-xl border-gray-300 p-4 h-full">
        {templates.length === 0 ? (
          <div className="text-center">
            <Title text="No Templates Created Yet" component="h2" />
            <Title
              className="mt-4"
              text="Click the button below to create your first template"
              component="h3"
            />

            <Button
              variant="primary"
              onClick={handleOpenCreateModal}
              className="w-auto mx-auto mt-4 py-3 px-6 normal-case"
            >
              Create template
            </Button>
          </div>
        ) : (
          <div className="flex flex-col h-full w-full">
            <div className="flex justify-between items-center w-full">
              <Title text="WhatsApp templates" component="h2" />
              <Button
                variant="primary"
                onClick={handleOpenCreateModal}
                className="w-auto py-3 px-6 normal-case"
              >
                Create template
              </Button>
            </div>
            <div className="mt-4 overflow-y-auto max-h-[300px] p-2">
              {templates?.map((t) => (
                <div
                  key={t.id}
                  className={`flex items-center gap-3 my-3 py-3 cursor-pointer ${
                    t.id === selectedTemplateId
                      ? "bg-gray rounded-xl"
                      : "bg-transparent"
                  }`}
                  onClick={() => setSelectedTemplateId(t.id)}
                >
                  <HiOutlineDocumentCheck size={24} />
                  <Title text={t.templateName} component="h3" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Right column (details of the selected template) */}
      <div className="flex flex-1 flex-col border rounded-xl border-gray-300 p-4 h-full">
        {selectedTemplate ? (
          <>
            <Title text="Template details" component="h2" />
            <div className="flex flex-col mt-8">
              <label>Template Name</label>
              <input
                type="text"
                disabled
                value={selectedTemplate.templateName}
                className="border border-gray-300 rounded-xl p-2 mt-2"
              />

              <label className="mt-8">Message Content</label>
              <textarea
                rows={3}
                disabled
                value={selectedTemplate.messageContent}
                className="border border-gray-300 rounded-xl p-2 mt-2"
              />
            </div>

            <div className="flex justify-between mt-8">
              <Button
                className="flex items-center justify-center gap-2 w-auto normal-case"
                onClick={() => handleOpenDeleteModal(selectedTemplate)}
              >
                {/* <HiOutlineTrash /> */}
                Delete
              </Button>

              <Button
                className="flex items-center justify-center gap-2 w-auto normal-case"
                onClick={() => handleOpenEditModal(selectedTemplate)}
              >
                {/*  <FaRegEdit /> */}
                Edit
              </Button>
            </div>
          </>
        ) : (
          <div className="text-center">
            <Title text="No template selected" component="h2" />
            <Title
              className="mt-4"
              text="Select a template from the list to view details and make changes"
              component="h3"
            />
          </div>
        )}
      </div>

      <AddWhatsAppTemplateModal
        open={isModalOpen}
        toggleOpen={() => setIsModalOpen((prev) => !prev)}
        editMode={editMode}
        templateName={templateName}
        onTemplateNameChange={(e) => setTemplateName(e.target.value)}
        messageContent={messageContent}
        onMessageContentChange={(e) => setMessageContent(e.target.value)}
        onSave={handleSaveTemplate}
      />

      <DeletePopup
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onDelete={handleConfirmDelete}
        title="Are you sure you want to delete this template?"
      />
    </div>
  );
};
