import React from "react";
import { MdToHTML } from "../MdToHTML";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MessageImage from "../Bot/MessageImage/MessageImage";
import { AssistantFunctionCallMessage } from "../AssistantFunctionCallMessage/AssistantFunctionCallMessage";
import { FunctionResultMessage } from "../FunctionResultMessage/FunctionResultMessage";
import { CollapsibleMessage } from "../CollapsibleMessage/CollapsibleMessage";
import styles from "./MessageRenderer.module.scss";

export const MessageRenderer = ({
  message,

  index,
  collapseStates,
  onToggleCollapse,
  bottomListRef,
}) => {
  const location = useLocation();
  const isConfigurationPage = location.pathname === "/configuration";
  const isChatsPage = location.pathname.startsWith("/chats");

  const isDebugMode = useSelector((state) => state.debug.isDebugMode);
  const showFunctionMessages =
    isConfigurationPage || (isChatsPage && isDebugMode);

  // 1) if message has function_call
  if (message.role === "assistant" && message.function_call) {
    if (!showFunctionMessages) return null;

    return (
      <AssistantFunctionCallMessage
        key={index}
        message={message}
        index={index}
        collapseStates={collapseStates}
        onToggleCollapse={onToggleCollapse}
      />
    );
  }
  // 2) if message role - "function"
  else if (message.role === "function") {
    if (!showFunctionMessages) return null;

    return (
      <FunctionResultMessage
        key={index}
        message={message}
        index={index}
        collapseStates={collapseStates}
        onToggleCollapse={onToggleCollapse}
      />
    );
  }
  // 3) other messages (user/assistant)
  else {
    const isLongMessage = message.content.length > 400;

    return message.content.length > 0 ? (
      <div
        key={index}
        className={`${styles.message} ${styles[message.role]} ${
          window.location.pathname.includes("chats") ? styles.chatPage : ""
        }`}
        ref={index === message.length - 1 ? bottomListRef : null}
      >
        {isLongMessage ? (
          <CollapsibleMessage content={message.content} maxLength={450} />
        ) : (
          <MdToHTML content={message?.content} />
        )}
        {/*  <MessageImage message={message.content} /> */}
      </div>
    ) : null;
  }
};
