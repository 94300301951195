import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormikValues } from "formik";
import { IconButton } from "@material-tailwind/react";
import classNames from "classnames";
import { useClickAway } from "react-use";

import { IconArrowDropdown } from "../../assets";
import { RoundedBox } from "../RoundedBox";
import { TbDotsVertical } from "react-icons/tb";

import styles from "./Dropdown.module.scss";

type Variant = "bordered" | "default";

const defaultIconAdornmentMap = {
  bordered: <IconArrowDropdown />,
  default: <TbDotsVertical className="text-light-black2" size="1.3rem" />,
};

type Props = {
  icon?: ReactNode;
  body?: ReactNode;
  actions?: ReactNode;
  placeholder?: ReactNode;
  classNameMenu?: string;
  className?: string;
  endAdornment?: ReactNode;
  variant?: Variant;
  label?: string;
  disabled?: boolean;
  open?: boolean;
  onToggle?: (open: boolean) => void;
  onClickAway?: (open: boolean) => void;
  search?: boolean;
  value?: string;
  onChangeSearchText?: (text: string) => void;
  showBody?: boolean;
  name?: string;
  formik?: FormikValues;
  component?: "button" | "div" | "summary";
};

export const Dropdown: FC<Props> = ({
  icon,
  body,
  actions,
  placeholder = "",
  classNameMenu = "",
  className = "",
  endAdornment = null,
  variant = "bordered",
  label,
  disabled = false,
  onToggle,
  open = false,
  onClickAway,
  search = false,
  value,
  onChangeSearchText,
  showBody = true,
  name,
  formik = {},
  component: Component = "button",
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
    onToggle && onToggle(!isOpen);
  }, [isOpen, onToggle]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useClickAway(dropdownRef, () => {
    !disabled && isOpen && handleToggle();
    onClickAway && isOpen && onClickAway(isOpen);
  });

  const buttonVariant = useMemo(
    () => ({
      bordered: (
        <Component
          type="button"
          name={name}
          className={classNames(
            styles.dropdownButton,
            open ? styles.active : "",
            "min-w-full",
            {
              "gap-4": Boolean(placeholder) || Boolean(icon),
            }
          )}
          onClick={handleToggle}
          onBlur={() => {
            formik.setFieldTouched && formik.setFieldTouched(name, true);
          }}
        >
          <span className={styles.iconWrapper}>
            {icon}
            <span className={classNames(styles.placeholder, "center")}>
              {placeholder}
            </span>
          </span>
          {endAdornment ? (
            endAdornment
          ) : (
            <span
              className={classNames(
                "center [&>svg]:w-[1.4rem] [&>svg]:h-[1.4rem]",
                styles.dropdownIcon,
                isOpen ? styles.active : ""
              )}
            >
              {defaultIconAdornmentMap[variant]}
            </span>
          )}
        </Component>
      ),
      default: endAdornment || (
        <IconButton
          color="blue-gray"
          size="sm"
          variant="text"
          onClick={handleToggle}
          name={name}
          onBlur={() => {
            formik.setFieldTouched && formik.setFieldTouched(name, true);
          }}
        >
          {defaultIconAdornmentMap[variant]}
        </IconButton>
      ),
    }),
    [
      Component,
      endAdornment,
      formik,
      handleToggle,
      icon,
      isOpen,
      name,
      open,
      placeholder,
      variant,
    ]
  );

  const errorMessage =
    name && formik?.errors ? formik?.errors[name as string] : "";
  const isError =
    errorMessage && formik?.touched && name
      ? formik?.touched[name as string]
      : "";

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.dropdown, className, {
        "column gap-2": Boolean(label),
      })}
    >
      {label && <p className="body-1 font-medium leading-[110%]">{label}</p>}
      {search ? (
        <input
          ref={inputRef}
          className="w-full rounded-[0.9rem] border border-gray-300 py-3 px-4 text-[1.2rem] text-gray-500 focus:outline-purple focus:border-gray-300"
          type="text"
          value={value}
          placeholder={placeholder as string}
          onChange={(event) => {
            onChangeSearchText && onChangeSearchText(event.target.value);
            formik.handleChange && formik.handleChange(event);
          }}
          onFocus={() => setIsOpen(true)}
          onBlur={(event) => {
            setIsOpen(false);
            formik.handleBlur && formik.handleBlur(event);
          }}
          name={name}
        />
      ) : (
        buttonVariant[variant]
      )}
      <RoundedBox
        className={classNames(
          styles.menu,
          "drop-shadow-xs",
          isOpen && showBody ? styles.active : "",
          classNameMenu
        )}
      >
        <div className={styles.full}>{body}</div>
        {actions && <div className={styles.full}>{actions}</div>}
      </RoundedBox>
      {isError && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};
