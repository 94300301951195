import { memo, useEffect, useState } from "react";

import { IconCalendar } from "../../../../assets";
import {
  Checkbox,
  Dropdown,
  RoundedBox,
  Skeleton,
  Title,
  SomethingWentWrong,
} from "../../../../components";

import { handleChangeCheckboxHelper } from "../../../../utils";
import { useAppDispatch, useReduxStore } from "../../../../hooks";
import { fetchRevenueByChannel } from "../../../../../store/slices/dashboard";
import { ChannelsList } from "./ChannelsList";

export const RevenueByChannel = memo(() => {
  const { dashboard } = useReduxStore();
  const dispatch = useAppDispatch();

  const [dates, setDates] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchRevenueByChannel());
  }, [dispatch]);

  return (
    <RoundedBox className="column h-full">
      <div className="flex items-center justify-between mb-6">
        <Title text="Revenue by Channel" component="h2" />
        <Dropdown
          icon={<IconCalendar />}
          placeholder="Date"
          body={
            <div className="column gap-4">
              <Checkbox
                label="3 days"
                value="revenue-by-channel-3days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("revenue-by-channel-3days")}
              />
              <Checkbox
                label="7 days"
                value="revenue-by-channel-7days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("revenue-by-channel-7days")}
              />
              <Checkbox
                label="30 days"
                value="revenue-by-channel-30days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("revenue-by-channel-30days")}
              />
              <Checkbox
                label="All time"
                value="revenue-by-channel-all-time"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("revenue-by-channel-all-time")}
              />
              <Checkbox
                label="Custom Range"
                value="revenue-by-channel-custom-range"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("revenue-by-channel-custom-range")}
                disabled
              />
            </div>
          }
          classNameMenu="min-w-[15rem] left-auto right-0 md:left-0 md:right-auto"
        />
      </div>

      {/* Loading view */}
      {dashboard.fetchingRevenueByChannel && (
        <Skeleton
          length={6}
          classNameWrapper="flex flex-nowrap items-center justify-start gap-[0.9rem] !h-[11rem] overflow-y-auto md:overflow-hidden pb-3 md:pb-0"
          classNameItem="rounded-[0.6rem] basis-full min-w-[8rem] md:min-w-[4rem]"
        />
      )}

      {/* Success data view */}
      {!dashboard.fetchingRevenueByChannel &&
        !dashboard.revenueByChannelError &&
        Boolean(dashboard.revenueByChannel?.revenue_by_channel) &&
        dashboard.revenueByChannel?.revenue_by_channel.length > 0 && (
          <ChannelsList />
        )}

      {/* No data view */}
      {!dashboard.fetchingRevenueByChannel &&
        !dashboard.revenueByChannelError &&
        Boolean(dashboard.revenueByChannel?.revenue_by_channel) &&
        dashboard.revenueByChannel?.revenue_by_channel.length === 0 && (
          <div className="grow center">
            <p>No Data</p>
          </div>
        )}

      {/* Error view */}
      {!dashboard.fetchingRevenueByChannel &&
        dashboard.revenueByChannelError && (
          <SomethingWentWrong
            callback={() => dispatch(fetchRevenueByChannel())}
          />
        )}
    </RoundedBox>
  );
});
