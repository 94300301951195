import React, { memo, useCallback, useState } from "react";
import { Button, Modal, TextField, Title } from "../../../../../components";
import { useFormik } from "formik";

import { useAIConfigurationState } from "../../../context";

import { validationSchema } from "./validationSchema";

type InitialValues = {
  telegram: string;
  viber: string;
};

const initialValues: InitialValues = {
  telegram: "",
  viber: "",
};

export const SetNeedOperator = memo(() => {
  const [open, setOpen] = useState(false);

  const { assistant, handleSaveChanges } = useAIConfigurationState();

  const onSubmit = useCallback(
    (values: InitialValues) => {
      handleSaveChanges({
        operator_tg: values.telegram,
        operator_viber: values.viber,
      });

      setOpen(false);
    },
    [handleSaveChanges]
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      telegram: assistant?.operator_tg || "",
      viber: assistant?.operator_viber || "",
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const disabled =
    (formik.values.telegram.trim() === assistant?.operator_tg &&
      formik.values.viber.trim() === assistant?.operator_viber) ||
    Object.values(formik.errors).length > 0;

  return (
    <>
      <Button onClick={() => setOpen(true)}>Set need Operator</Button>
      <Modal
        title={<Title text="Set need Operator" />}
        open={open}
        size="sm"
        toggleOpen={() => setOpen(false)}
        classNameWrapper="p-9"
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-10">
              <div className="column gap-7 pb-6 border-b border-gray-300 mb-8">
                <TextField
                  inputLabel="Telegram"
                  placeholder="Name"
                  className="!border !border-light-black2 !placeholder:text-gray-400 !rounded-[0.9rem] h-full !text-[1.2rem] !bg-transparent !px-6"
                  formik={formik}
                  name="telegram"
                  classNameWrapper="h-full [&>div]:h-[3.46rem]"
                />

                <p className="text-[1.2rem] font-medium text-gray-500">
                  Add @mcbassistant_bot to your group, then press "Save" and
                  then send a message /my_id @mcbassistant_bot
                </p>
              </div>

              <TextField
                inputLabel="Viber (coming soon)"
                placeholder="Name"
                className="!border !border-light-black2 !placeholder:text-gray-400 !rounded-[0.9rem] h-[3.5rem] !text-[1.2rem] !bg-transparent !px-6"
                formik={formik}
                name="viber"
                disabled
                classNameWrapper="h-full [&>div]:h-[3.46rem]"
              />
            </div>
            <div className="flex justify-end gap-6">
              <Button
                className="font-semibold text-[1.35rem]"
                variant="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="font-semibold text-[1.35rem]"
                disabled={disabled}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
});
