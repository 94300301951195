import { voiceOptions } from "../../constants";

export const getOlxAccountStatus = (olxAccounts) => {
  const connectedAccountsCount = olxAccounts.length;
  return `${connectedAccountsCount} Connected`;
};

export const getDefaultStatus = (matchedChannel, channel) => {
  const lowerValue = channel.value.toLowerCase();

  if (["ocwidget", "swwidget"].includes(lowerValue)) {
    return (
      <>
        {matchedChannel?.is_on ? "Connected" : "Connect"}
        <br />
        <span>⚠️ Deprecated</span>
      </>
    );
  }

  return matchedChannel?.is_on
    ? "Connected"
    : channel.value === "WpWidget"
    ? "Downloaded"
    : "Off";
};

export const getStatusColor = (matchedChannel, olxAccounts, channel) => {
  if (["OcWidget", "SwWidget"].includes(channel.value)) {
    return "#3588e9"; // for Deprecated
  }
  if (matchedChannel) {
    return matchedChannel.is_on ||
      (channel.value === "OLXAccount" && !!olxAccounts.length)
      ? "#7AE575"
      : "#d3d407";
  } else {
    return "#777EF5";
  }
};

export const getTelegramAccountStatus = (telegramAccounts) => {
  const connectedAccountsCount = telegramAccounts.length;
  return `${connectedAccountsCount} Connected`;
};

export const getLabelVoice = (value) => {
  return voiceOptions.find((option) => option.value === value)?.label;
};
