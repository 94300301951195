import { FC, useEffect, useRef, useState } from "react";
import { MessageType } from "./types";
import { TbFunction } from "react-icons/tb";
import { MdToHTML } from "./MdToHTML";
import { CollapsibleMessage } from "./CollapsibleMessage";
import classNames from "classnames";

type Props = {
  message: MessageType;
  index: number;
  collapseStates: Record<string, any>;
  onToggleCollapse: any;
};

export const FunctionResultMessage: FC<Props> = ({
  message,
  index,
  collapseStates,
  onToggleCollapse,
}) => {
  const resultCollapseKey = `msgIndex_${index}_function_result`;
  const isExpanded = Boolean(collapseStates[resultCollapseKey]);
  const isLongContent = message.content.length > 400;

  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isExpanded, message.content]);

  return (
    <div className="mx-auto p-2 border-l-4 border-green-500 bg-green-50 rounded-md transition-colors hover:bg-green-100 w-full">
      <div
        className="flex items-center gap-3 cursor-pointer"
        onClick={() => onToggleCollapse(resultCollapseKey)}
      >
        <span className="flex items-center text-green-500 font-semibold text-lg">
          <TbFunction size={18} className="mr-2" /> Result
        </span>
        <span className="italic text-lg">{message.name}</span>
        <button
          className={classNames(
            "text-lg transition-transform duration-500 ml-auto",
            { "rotate-180": isExpanded }
          )}
        >
          🔼
        </button>
      </div>
      <div
        ref={contentRef}
        className={classNames(
          "overflow-hidden transition-[height] duration-500 ease-in-out text-[1.2rem]"
        )}
        style={{ height }}
      >
        {isLongContent ? (
          <CollapsibleMessage content={message.content} maxLength={450} />
        ) : (
          <MdToHTML content={message.content} />
        )}
      </div>
    </div>
  );
};
