import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../../supabase";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../store/slices/authSlice";
import {
  fetchInstructions,
  setSelectedAssistants,
  _selectedAssistantId,
  _updateListOfAssistants,
} from "../../../../../store/slices/assistantSlice";
import { useChatContext } from "../../context/ChatContextProvider";
import { Typography } from "../../../../../components";
import styles from "./ChatList.styles.module.scss";
import { Title } from "../../../../../components/Title";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { ChatTabs } from "../../../../../components/ChatTabs/ChatTabs";
import { ChatListItems } from "./ChatListItems/ChatListItems";
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { FiltersPanel } from "./FiltersPanel/FiltersPanel";
import filterToggle from "../../../../../assets/icons/chats/FilterToggle.png";
import { useScrollRestoration } from "../../../../../hooks/useScrollRestoration";
import { CHANNELS } from "../../../../../constants/channels";
import { useChatLoader } from "../../../../../hooks/chats/useChatLoader";
import { useChatFilters } from "../../../../../hooks/chats/useChatFilters";
import { useRestoreFilters } from "../../../../../hooks/chats/useRestoreFilters";
import { useUpdateFilters } from "../../../../../hooks/chats/useUpdateFilters";

const ChatList = ({
  isShowClientCard,
  setIsShowClientCard,

  onIsCurrentChatInListChange,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientId, pageId } = useParams();
  const normalizedPageId = pageId && pageId !== "null" ? pageId : null;
  const { activeTab, setActiveTab } = useChatContext();

  const chatListRef = useRef(null);
  const hasRestoredScroll = useRef(false);
  const isRestoringRef = useRef(false);
  const [didRestore, setDidRestore] = useState(false);

  const channelOptions = useMemo(
    () =>
      CHANNELS.map((channel) => ({
        value: channel.value,
        label: channel.name,
        icon: channel.icon,
        isDisabled: channel.isDisabled || false,
      })),
    []
  );

  // Redux state
  const user = useSelector(selectUser);
  const selectedAssistants = useSelector(
    (state) => state.assistant.selectedAssistants
  );
  const selectedAssistantId = useSelector(_selectedAssistantId);
  const updateListOfAssistants = useSelector(_updateListOfAssistants);

  // Local states
  const [searchValue, setSearchValue] = useState("");
  const [appliedSearchValue, setAppliedSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isChatsLoading, setIsChatsLoading] = useState(false);

  const debouncedSetSearchValue = useCallback(
    debounce((value) => {
      setAppliedSearchValue(value);
    }, 700),
    []
  );

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedSetSearchValue(value);
  };

  useEffect(() => {
    return () => {
      debouncedSetSearchValue.cancel();
    };
  }, [debouncedSetSearchValue]);

  // Channels state
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [appliedChannels, setAppliedChannels] = useState([]);

  // Assistants state
  const [appliedAssistants, setAppliedAssistants] = useState([]);
  const [assistantsLoaded, setAssistantsLoaded] = useState(false);

  // Funnel status state
  const [funnelStatuses, setFunnelStatuses] = useState([]);
  const [selectedFunnelStatuses, setSelectedFunnelStatuses] = useState([]);
  const [appliedFunnelStatuses, setAppliedFunnelStatuses] = useState([]);

  // Time range state
  const [selectedTimeRange, setSelectedTimeRange] = useState(null);
  const [appliedTimeRange, setAppliedTimeRange] = useState(null);

  // Managers state
  const [managers, setManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [appliedManagers, setAppliedManagers] = useState([]);

  // Chats state
  const [chats, setChats] = useState([]);
  const [totalChatCount, setTotalChatCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Additional state
  const [clientFunnelStatusMap, setClientFunnelStatusMap] = useState({});
  const [isShowChatsList, setIsShowChatsList] = useState(false);
  const [shouldNavigateToFirstChat, setShouldNavigateToFirstChat] =
    useState(false);

  const firstRender = useRef(true);

  // Scrolling
  const { restoreScrollPosition, saveScrollPosition } = useScrollRestoration(
    chatListRef,
    "chatScrollPosition"
  );

  useEffect(() => {
    if (updateListOfAssistants && updateListOfAssistants.length > 0) {
      setAssistantsLoaded(true);
    }
  }, [updateListOfAssistants]);

  // Clean all btn
  const activeCleanAll =
    (appliedAssistants && appliedAssistants.length > 0) ||
    (appliedChannels && appliedChannels.length > 0) ||
    (appliedManagers && appliedManagers.length > 0) ||
    (appliedFunnelStatuses && appliedFunnelStatuses.length > 0) ||
    appliedTimeRange !== null;

  // States for dropdown menus
  const [isAssistantMenuOpen, setIsAssistantMenuOpen] = useState(false);
  const [isFunnelMenuOpen, setIsFunnelMenuOpen] = useState(false);
  const [isTimeRangeMenuOpen, setIsTimeRangeMenuOpen] = useState(false);
  const [isManagersMenuOpen, setIsManagersMenuOpen] = useState(false);

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  // Creating a ref to save the current state of chats
  const chatsRef = useRef(chats);

  // Handler for toggling filter visibility
  const toggleFilters = () => {
    setIsFiltersVisible((prev) => !prev);
  };

  // Clean all filters
  const handleCleanAll = () => {
    dispatch(setSelectedAssistants([]));
    setAppliedAssistants([]);

    setSelectedChannels([]);
    setAppliedChannels([]);

    setSelectedFunnelStatuses([]);
    setAppliedFunnelStatuses([]);

    setSelectedManagers([]);
    setAppliedManagers([]);

    setSelectedTimeRange(null);
    setAppliedTimeRange(null);

    setSearchValue("");
    setShouldNavigateToFirstChat(true);

    setChats([]);
    setPage(1);
    setSearchParams({});
    loadChats();
    localStorage.removeItem("appliedAssistants");
  };

  useEffect(() => {
    if (isRestoringRef.current) return;
    updateFiltersInQuery();
  }, [
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedSearchValue,
    appliedManagers,
  ]);

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);

  // Chat loading
  const { fetchChatsPage, loadChats, loadMoreChats } = useChatLoader({
    user,
    appliedFunnelStatuses,
    appliedSearchValue,
    appliedAssistants,
    appliedChannels,
    appliedTimeRange,
    appliedManagers,
    activeTab,
    setHasMore,

    setUnreadCount,
    setTotalChatCount,
    setChats,
    clientFunnelStatusMap,
    shouldNavigateToFirstChat,
    setShouldNavigateToFirstChat,
    setIsShowClientCard,
    navigate,
  });

  // Update the existing updateFiltersInQuery function
  const updateFiltersInQuery = useUpdateFilters({
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedSearchValue,
    appliedManagers,
  });

  // Filters handlers
  const {
    handleApplyAssistants,
    handleRemoveAssistant,
    handleCleanAssistants,
    handleApplyChannels,
    handleCleanChannels,
    handleApplyFunnelStatus,
    handleCleanFunnelStatuses,
    handleApplyTimeRange,
    handleCleanTimeRange,
    handleApplyManagers,
    handleCleanManagers,
  } = useChatFilters({
    selectedAssistants,
    appliedAssistants,
    setSelectedAssistants,
    setAppliedAssistants,
    selectedChannels,
    setSelectedChannels,
    appliedChannels,
    setAppliedChannels,
    selectedFunnelStatuses,
    setSelectedFunnelStatuses,
    appliedFunnelStatuses,
    setAppliedFunnelStatuses,
    selectedTimeRange,
    setSelectedTimeRange,
    appliedTimeRange,
    setAppliedTimeRange,
    selectedManagers,
    setSelectedManagers,
    appliedManagers,
    setAppliedManagers,
    dispatch,
    setChats,
    setPage,
    loadChats,
    updateFiltersInQuery,
    isRestoringRef,
  });

  useEffect(() => {
    // If the filters from the URL have not yet been restored, we do not load
    if (!didRestore) return;

    setIsChatsLoading(true);
    setPage(1);
    setChats([]);
    loadChats().then(() => {
      setIsChatsLoading(false);
    });
  }, [
    didRestore,
    activeTab,
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedSearchValue,
    appliedManagers,
  ]);

  useEffect(() => {
    const fetchClientsAndFunnelStatuses = async () => {
      try {
        const { data: clientsData, error } = await supabase
          .from("clients")
          .select("client_id, funnel_status")
          .eq("account_id", user.account_id);

        if (error) throw error;

        const funnelStatusMap = {};
        clientsData.forEach((client) => {
          funnelStatusMap[client.client_id] = client.funnel_status;
        });
        setClientFunnelStatusMap(funnelStatusMap);

        const uniqueStatuses = [
          ...new Set(clientsData.map((c) => c.funnel_status).filter(Boolean)),
        ];

        setFunnelStatuses(
          uniqueStatuses.map((status) => ({
            value: status,
            label: status,
          }))
        );
      } catch (error) {
        toast.error("Error fetching clients: " + error.message);
      }
    };

    fetchClientsAndFunnelStatuses();
  }, [user.account_id]);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const { data: managersData, error } = await supabase
          .from("users")
          .select("id, full_name")
          .eq("account_id", user.account_id)
          .eq("role", "moderator");

        if (error) {
          toast.error("Error fetching managers: " + error.message);
          return;
        }

        setManagers(
          managersData.map((manager) => ({
            value: manager.id,
            label: manager.full_name,
          }))
        );
      } catch (error) {
        console.error("Unexpected error: " + error.message);
      }
    };

    fetchManagers();
  }, [user.account_id]);

  // Update funnel_status for existing chats when changing clientFunnelStatusMap
  useEffect(() => {
    setChats((prevChats) =>
      prevChats.map((chat) => ({
        ...chat,
        funnel_status:
          clientFunnelStatusMap[chat.client_id] || chat.funnel_status,
      }))
    );
  }, [clientFunnelStatusMap]);

  // Refresh chats every 60 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadChats();
    }, 60000); // 60 seconds

    return () => clearInterval(intervalId);
  }, [loadChats]);

  // select chat
  const handleClickChat = async (chatPartner) => {
    try {
      if (chatPartner.unread_count) {
        const { error } = await supabase
          .from("chats")
          .update({ unread_count: 0 })
          .match({ id: chatPartner.id });

        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatPartner.id ? { ...chat, unread_count: 0 } : chat
          )
        );

        setUnreadCount((prevCount) => prevCount - chatPartner.unread_count);
      }

      //setIsShowChatsList(false);

      if (chatPartner.page_id) {
        navigate(`/chats/${chatPartner.client_id}/${chatPartner.page_id}`);
      } else {
        navigate(`/chats/${chatPartner.client_id}`);
      }

      // setIsShowClientCard(true);
    } catch (error) {
      toast.error("Unexpected error: " + error.message);
    }
  };

  // lazy loading for upload chats
  const handleLoadMore = useCallback(async () => {
    const nextPage = page + 1;
    const { data: moreChats, count: totalCount } = await fetchChatsPage(
      nextPage
    );

    if (moreChats.length > 0) {
      const updatedMoreChats = moreChats.map((chat) => ({
        ...chat,
        funnel_status: clientFunnelStatusMap[chat.client_id] || null,
      }));

      setChats((prev) => [...prev, ...updatedMoreChats]);
      setPage(nextPage);
      setUnreadCount(
        (prevCount) =>
          prevCount +
          updatedMoreChats.reduce(
            (acc, { unread_count }) => acc + unread_count,
            0
          )
      );
      setTotalChatCount(totalCount);
    }
  }, [fetchChatsPage, page, clientFunnelStatusMap]);

  // chat scroll position
  const handleScroll = useCallback(
    debounce(() => {
      if (
        chatListRef.current &&
        chatListRef.current.scrollTop + chatListRef.current.clientHeight >=
          chatListRef.current.scrollHeight - 50
      ) {
        handleLoadMore(); // Upload new data
      }
      saveScrollPosition();
    }, 200),
    [handleLoadMore, saveScrollPosition]
  );

  useEffect(() => {
    if (chatListRef.current && !hasRestoredScroll.current) {
      restoreScrollPosition();
      hasRestoredScroll.current = true;
    }
  }, [chats, restoreScrollPosition]);

  const getColor = (id) => {
    return updateListOfAssistants.find(({ value }) => value === id)?.bgColor;
  };

  const unread_needs_operator = useMemo(
    () => chats?.filter((el) => el.needs_operator && el.unread_count).length,
    [chats]
  );

  useEffect(() => {
    if (chats && chats.length > 0) {
      setUnreadCount(
        chats.reduce((acc, { unread_count }) => acc + unread_count, 0)
      );

      if (shouldNavigateToFirstChat) {
        const firstChat = chats[0];

        if (firstChat.page_id) {
          navigate(`/chats/${firstChat.client_id}/${firstChat.page_id}`, {
            replace: true,
          });
        } else {
          navigate(`/chats/${firstChat.client_id}`, { replace: true });
        }
        setShouldNavigateToFirstChat(false);
      } else if (!clientId && firstRender.current && pathname === "/chats") {
        const firstChat = chats[0];

        if (firstChat.page_id) {
          navigate(`/chats/${firstChat.client_id}/${firstChat.page_id}`);
        } else {
          navigate(`/chats/${firstChat.client_id}`);
        }
        firstRender.current = false;
      }
    }
  }, [
    chats,
    clientId,
    normalizedPageId,
    pathname,
    selectedAssistantId,
    navigate,
    shouldNavigateToFirstChat,
  ]);

  useEffect(() => {
    dispatch(fetchInstructions(user.account_id));
  }, [dispatch, user.account_id]);

  const handleMarkAllRead = async () => {
    try {
      const { error } = await supabase
        .from("chats")
        .update({ unread_count: 0 })
        .match({ account_id: user.account_id });

      if (error) {
        toast.error("Error marking all as read: " + error.message);
        return;
      }

      setChats((prevChats) =>
        prevChats.map((chat) => ({ ...chat, unread_count: 0 }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.log("Unexpected error: " + error.message);
    }
  };

  const showMoreButton =
    !appliedSearchValue &&
    appliedFunnelStatuses.length === 0 &&
    chats.length > 0 &&
    chats.length < totalChatCount;

  const isCurrentChatInList = useMemo(() => {
    if (!clientId) return false;
    return chats.some((chat) => {
      if (normalizedPageId) {
        return (
          String(chat.client_id) === String(clientId) &&
          String(chat.page_id) === String(normalizedPageId)
        );
      } else {
        return String(chat.client_id) === String(clientId) && !chat.page_id;
      }
    });
  }, [chats, clientId, normalizedPageId]);

  useEffect(() => {
    onIsCurrentChatInListChange(isCurrentChatInList);
  }, [chats, onIsCurrentChatInListChange, isCurrentChatInList]);

  const filterState = {
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedManagers,
    selectedAssistants,
    selectedChannels,
    selectedFunnelStatuses,
    selectedTimeRange,
    selectedManagers,
    funnelStatuses,
    managers,
    updateListOfAssistants,
    isAssistantMenuOpen,
    isFunnelMenuOpen,
    isTimeRangeMenuOpen,
    isManagersMenuOpen,
  };

  const filterActions = {
    setSelectedAssistants,
    setSelectedChannels,
    setSelectedFunnelStatuses,
    setSelectedTimeRange,
    setSelectedManagers,
    setIsAssistantMenuOpen,
    setIsFunnelMenuOpen,
    setIsTimeRangeMenuOpen,
    setIsManagersMenuOpen,
    handleApplyAssistants,
    handleRemoveAssistant,
    handleCleanAssistants,
    handleApplyChannels,
    handleCleanChannels,
    handleApplyFunnelStatus,
    handleCleanFunnelStatuses,
    handleApplyTimeRange,
    handleCleanTimeRange,
    handleApplyManagers,
    handleCleanManagers,
    dispatch,
  };

  useEffect(() => {
    setPage(1);
    setChats([]);
    loadChats();
  }, [
    activeTab,
    appliedSearchValue,
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedManagers,
  ]);

  // Restore filters from URL after page reloading
  useRestoreFilters({
    didRestore,
    setDidRestore,
    searchParams,
    setAppliedAssistants,
    setSelectedAssistants,
    setAppliedChannels,
    setSelectedChannels,
    setAppliedFunnelStatuses,
    setSelectedFunnelStatuses,
    setAppliedTimeRange,
    setSelectedTimeRange,
    setAppliedManagers,
    setSelectedManagers,
    setSearchValue,
    setAppliedSearchValue,
    isRestoringRef,
    channelOptions,
    dispatch,
  });

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderIcon = () => {
    if (windowWidth >= 667) {
      return <FaArrowLeft size={20} />;
    } else {
      return <FaChevronRight size={30} />;
    }
  };

  return (
    <div className={styles.chatContainer}>
      <div
        className={classNames(
          styles.accountChats,
          isShowChatsList && styles.rotated
        )}
      >
        <Title title="Active chats" />

        <div className={styles.chatHeader}>
          <div className={styles.searchBarContainer}>
            <SearchBar
              searchValue={searchValue}
              setSearchValue={handleSearchChange}
              placeholder="Search chats"
            />
            <button
              className={classNames(styles.filtersToggle, {
                [styles.filterOpened]: !isFiltersVisible,
              })}
              onClick={toggleFilters}
            >
              <img
                src={filterToggle}
                alt="FilterToggle"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = filterToggle;
                }}
              />
            </button>
          </div>
          <div
            className={classNames(styles.filtersWrapper, {
              [styles.hidden]: !isFiltersVisible,
            })}
          >
            <div className={styles.filters}>
              <Typography>Select options for quick search</Typography>
              <button
                className={styles.cleanAllBtn}
                onClick={handleCleanAll}
                disabled={!activeCleanAll}
              >
                Clear all
              </button>
            </div>

            <FiltersPanel
              filterState={filterState}
              filterActions={filterActions}
            />
          </div>
        </div>

        <ChatTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          unreadCount={unreadCount}
          handleMarkAllRead={handleMarkAllRead}
          unreadNeedsOperator={unread_needs_operator}
        />
        <div
          className={styles.chatList}
          ref={chatListRef}
          onScroll={handleScroll}
        >
          <ChatListItems
            chats={chats}
            handleClickChat={handleClickChat}
            getColor={getColor}
            showMoreButton={showMoreButton}
            handleLoadMore={handleLoadMore}
            clientId={clientId}
            pageId={normalizedPageId}
            chatListRef={chatListRef}
            isChatsLoading={isChatsLoading}
          />
        </div>
      </div>

      {chats.length > 0 && (
        <div className={styles.toggle}>
          <button
            className={classNames(
              styles.btn,
              isShowChatsList && styles.rotated
            )}
            onClick={() => setIsShowChatsList(!isShowChatsList)}
          >
            {renderIcon()}
          </button>
        </div>
      )}

      {(!clientId || !isCurrentChatInList) && chats?.length > 0 && (
        <div className={styles.preloadMessageList}>
          <p>Please choose any chat</p>
        </div>
      )}

      {!isCurrentChatInList && chats?.length === 0 && (
        <div className={styles.preloadMessageList}>
          <p>Please clear all filters to continue</p>
        </div>
      )}
    </div>
  );
};

export default ChatList;
