import { MicrosoftWord } from "../assets/icons/";
import { PowerPoint } from "../assets/icons/";
import { MicrosoftExel } from "../assets/icons/";
import { PDF } from "../assets/icons/";
import { Archive } from "../assets/icons/";
import { IconType } from "react-icons";

export const allowedFileTypes: string[] = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/zip",
  "application/x-zip-compressed",
  "application/x-rar-compressed",
  "application/vnd.rar",
  "application/x-tar",
  "application/gzip",
  "application/x-7z-compressed",
];

export const getFileExtension = (url: string): string => {
  const parts = url.split(".");
  return parts[parts.length - 1].toLowerCase();
};

export const extensionMapping: Record<string, string> = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  webp: "image/webp",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  zip: "application/zip",
  rar: "application/zip",
  tar: "application/zip",
  gz: "application/zip",
  "7z": "application/zip",
};

interface PreviewMappingItem {
  Icon: IconType;
  color: string;
  label: string;
}

export const previewMapping: Record<string, PreviewMappingItem> = {
  "application/pdf": {
    Icon: PDF,
    color: "red",
    label: "PDF документ",
  },
  "application/msword": {
    Icon: MicrosoftWord,
    color: "blue",
    label: "Word документ",
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    Icon: MicrosoftWord,
    color: "blue",
    label: "Word документ",
  },
  "application/vnd.ms-powerpoint": {
    Icon: PowerPoint,
    color: "orange",
    label: "PowerPoint презентація",
  },
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
    Icon: PowerPoint,
    color: "orange",
    label: "PowerPoint презентація",
  },
  "application/vnd.ms-excel": {
    Icon: MicrosoftExel,
    color: "green",
    label: "Excel документ",
  },
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
    Icon: MicrosoftExel,
    color: "green",
    label: "Excel документ",
  },
  "application/zip": {
    Icon: Archive,
    color: "gray",
    label: "Архів",
  },
};
