import { createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../../supabase";

export const fetchKnowledgeBase = createAsyncThunk(
  "knowledgeBase/fetchKnowledgeBase",
  async (userId: string) => {
    const { data, error } = await supabase
      .from("integrations")
      .select("*")
      .eq("account_id", userId)
      .eq("is_knowledge_base", true);

    if (error) {
      throw new Error(error.message);
    }

    return data || [];
  }
);
