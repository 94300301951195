import React, { useState, useEffect, useMemo } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import supabase from "../../supabase";
import { useReduxStore } from "../../redesign/hooks/useReduxStore";

import {
  useIntegrationTokenMutation,
  useValidateIntegrationAssetsMutation,
  useRevokeIntegrationMutation,
} from "../../store/api/integrationApi";
import { Button } from "../../redesign/components";
import { Modal } from "../../redesign/components";
import { Loader } from "../Loader";
import { Input } from "../Input";

import { Plus } from "../../assets/icons/knowledgeBaseIcons";
import { validateIntegrationTimeOut } from "../../constants/message";
import { DOCUMENT_TYPES } from "../../constants/knowledgeBaseItems";
import {
  GooglePickerData,
  IntegrationGoogleAuthProps,
} from "../../types/knowledgeBase";
import { ConsentModalKnowledgeBase } from "../popups/ConsentModalKnowledgeBase/ConsentModalKnowledgeBase";

declare global {
  interface Window {
    google: any;
  }
}

interface UpdateIntegrationPayload {
  sheet_id?: string;
  [key: string]: any;
}

export const IntegrationGoogleAuth: React.FC<IntegrationGoogleAuthProps> = ({
  id,
  title,
  icon,
  scope,
  description,
  connected,
  fetchIntegrations,
  authType,
  isLoadingRefreshData,
  isWorking,
  sheetId,
  children,
  knowledgeBase,
  docLink = "",
  isAnyDocumentConnected,
  integrationInProgressId,
  setIntegrationInProgressId,
  accessToken: storedAccessToken,
  refreshToken: storedRefreshToken,
}) => {
  // Retrieve user from Redux
  const { auth } = useReduxStore();
  const user = auth.user;

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [isShowIntegrationForm, setIsShowIntegrationForm] =
    useState<boolean>(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState<string>("");
  const [accessTokenCrm, setAccessTokenCrm] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>("");

  const [revokeIntegration] = useRevokeIntegrationMutation();
  const [integrationToken] = useIntegrationTokenMutation();
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();

  useEffect(() => {
    // When the component mounts, set accessToken and refreshToken from props if connected
    if (connected) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
    }
  }, [connected, storedAccessToken, storedRefreshToken]);

  useEffect(() => {
    if (accessToken && !connected) {
      // Entering "Pick File" stage
      setIntegrationInProgressId(id.toString());
    } else {
      // Exiting "Pick File" stage
      if (integrationInProgressId === id.toString()) {
        setIntegrationInProgressId(null);
      }
    }
  }, [accessToken, connected]);

  const isIntegrationDisabled = useMemo(() => {
    if (integrationInProgressId && integrationInProgressId !== id.toString()) {
      return true;
    }

    if (!DOCUMENT_TYPES.includes(title.toLowerCase())) return false;

    return isAnyDocumentConnected && !connected;
  }, [connected, isAnyDocumentConnected, title, integrationInProgressId, id]);

  // Handle "Connect" button
  const handleConnectClick = (authType: string) => {
    if (authType === "apikey") {
      setIsShowIntegrationForm(true);
      return;
    }
    setIsConsentModalOpen(true);
  };

  // Processes the user's response in a modal window
  const handleUserConsent = (consent: boolean) => {
    setIsConsentModalOpen(false);
    if (consent) {
      // If connected, reconnect, otherwise login
      connected ? reconnectToken(title, id) : login();
    }
  };

  // Hook for getting Google OAuth code
  const login = useGoogleLogin({
    scope,
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      await getIntegrationToken(codeResponse.code);
    },
  });

  const updateIntegrationAssets = (updateObject: UpdateIntegrationPayload) => {
    try {
      return supabase
        .from("integrations")
        .update(updateObject)
        .match({ account_id: user.account_id, access_token: accessToken });
    } catch ({ message }) {
      toast.error(message);
    }
  };

  // Callback for Google Picker
  const pickerCallback = async (data: GooglePickerData) => {
    try {
      if (data.action === "picked") {
        const docId = data?.docs[0].id;
        const updateObject = { sheet_id: docId };
        await validateIntegration(docId);
        await updateIntegrationAssets(updateObject);
        setAccessToken("");

        // Reset integrationInProgressId
        setIntegrationInProgressId(null);
        fetchIntegrations();
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleOpenPicker = (integrationTitle: string) => {
    let view;
    switch (integrationTitle) {
      case "Spreadsheet Inventory":
      case "Spreadsheet Orders":
        view = window.google.picker.ViewId.SPREADSHEETS;
        break;
      default:
        view = window.google.picker.ViewId.DOCUMENTS;
    }

    const showPicker = () => {
      // TODO (developer): Replace with your API key
      const picker = new window.google.picker.PickerBuilder()
        .setAppId("14528528059-6fds545h4jtt5lr1tm88bp6i2bh73g1k")
        .addView(view)
        .setOAuthToken(accessToken)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_PICKER_API_KEY)
        .setCallback(pickerCallback)
        .build();

      picker.setVisible(true);
    };

    showPicker();
  };

  // Reconnect (first we "revoke")

  const reconnectToken = async (integrationTitle: string, id: number) => {
    try {
      setIsLoading(true);

      const payload = {
        account_id: user.account_id,
        integration: title,
        access_token: accessToken,
        refresh_token: refreshToken,
        sheet_id: sheetId,
      };

      // Send POST request to /revoke-integration
      try {
        await revokeIntegration(payload).unwrap();
      } catch (error) {
        toast.error("Failed to revoke integration");
      }

      // Proceed to delete the record from Supabase
      const { error: errorSupabase } = await supabase
        .from("integrations")
        .delete()
        .eq("account_id", user.account_id)
        .eq("id", id)
        .eq("integration", integrationTitle);

      if (errorSupabase) {
        toast.error(errorSupabase.message || errorSupabase);
        return;
      }

      if (authType !== "apikey") {
        setAccessToken("");
        setRefreshToken("");
      } else {
        setAccessTokenCrm("");
      }

      if (integrationInProgressId === id.toString()) {
        setIntegrationInProgressId(null);
      }
    } catch (error) {
      setIsError(true);
      setErrorText(error.message);
    } finally {
      fetchIntegrations();
      setIsLoading(false);
    }
  };

  const validateIntegration = async (sheetId: string) => {
    try {
      setIsLoading(true);
      const resp = await validateIntegrationAssets({
        account_id: user.account_id,
        integration: title,
        access_token: accessToken,
        sheet_id: sheetId,
      });

      if (resp.error) {
        toast.error(resp.error.data ? resp.error.data.error : resp.error);
        setIsError(true);
        setErrorText("" + resp.error);
      } else {
        toast.success(resp.data);
      }
    } catch (error) {
      setIsError(true);
      setErrorText(validateIntegrationTimeOut);
    } finally {
      fetchIntegrations();
      setIsLoading(false);
    }
  };

  // Calling the endpoint to obtain an integration token
  const getIntegrationToken = async (auth_code: string) => {
    try {
      setIsLoading(true);
      const resp = await integrationToken({
        account_id: user.account_id,
        integration: title,
        auth_code: auth_code,
        knowledge_base: knowledgeBase,
      });

      if (resp.error) {
        toast.error(resp.error.data ? resp.error.data.error : resp.error);
      } else {
        setAccessToken(resp.data.access_token);
      }
    } catch (error) {
      setIsError(true);
      setErrorText("" + error);
    } finally {
      setIsLoading(false);
    }
  };

  const setCrmIntegration = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const integrationRes = await integrationToken({
        account_id: user.account_id,
        integration: title,
        access_token: accessTokenCrm,
      });

      if (integrationRes.error) {
        toast.error(integrationRes.error);
        return;
      }

      const validateIntegrationRes = await validateIntegrationAssets({
        account_id: user.account_id,
        integration: title,
        access_token: accessTokenCrm,
      });

      if (validateIntegrationRes.error) {
        setAccessTokenCrm("");
        setIsShowIntegrationForm(false);
        await reconnectToken(title, id);
        toast.error(validateIntegrationRes.error);
      }
    } catch (error) {
      setIsError(true);
      setErrorText("" + error);
    } finally {
      fetchIntegrations();
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center p-4 bg-white shadow-md rounded-xl relative min-h-[22.5rem]">
      {isLoading || isLoadingRefreshData ? (
        <div className="flex justify-center items-center w-full h-20">
          <Loader width={40} height={40} />
        </div>
      ) : (
        <>
          {docLink !== "" && (
            <a
              target="_blank"
              rel="noreferrer"
              href={docLink}
              className="absolute top-3 right-3 text-accent"
            >
              link
            </a>
          )}

          <div className="flex flex-col items-center justify-evenly h-full gap-2 ">
            {icon ? (
              <svg className="flex items-center w-[100px] h-[50px] mb-4 sm:w-[60px] sm:h-[30px] md:w-[80px] md:h-[40px] lg:w-[100px] lg:h-[50px]">
                {icon}
              </svg>
            ) : (
              children
            )}

            {title && (
              <div className="text-xl font-semibold mb-2 text-center md:text-lg">
                {title}
              </div>
            )}

            {description && (
              <div className="text-xl leading-[1.5] text-center mb-3 font-normal md:text-base">
                {description}
              </div>
            )}
          </div>

          {connected ? (
            // If already connected -> "REVOKE" button
            <Button
              onClick={() => reconnectToken(title, id)}
              className="
              w-full px-4 py-3 rounded-[0.6rem]
              text-white uppercase font-semibold
              text-xl leading-[1.6rem]
              bg-[#59d259]
              md:py-2.5 md:rounded-[0.5rem]
            "
            >
              REVOKE
            </Button>
          ) : accessToken && isWorking ? (
            // If accessToken and you need to choose a file => "PICK FILE"
            <Button
              onClick={() => handleOpenPicker(title)}
              className="
                w-full px-4 py-3 rounded-[0.6rem]
                text-white uppercase font-semibold
                text-xl leading-[1.6rem]
                bg-[#d3d407]
                md:py-2.5 md:rounded-[0.5rem]
              "
            >
              PICK FILE
            </Button>
          ) : isShowIntegrationForm ? (
            <form
              onSubmit={setCrmIntegration}
              className="flex flex-col w-full gap-2"
            >
              <Input
                typeInput="connected"
                placeholder="Your Access Token"
                value={accessTokenCrm}
                onChange={(e) => setAccessTokenCrm(e.target.value)}
              />
              <Button
                type="submit"
                className="
                w-full px-4 py-3 rounded-[0.6rem]
                text-white uppercase font-semibold
                text-xl leading-[1.6rem]
                bg-[#d3d407]
                md:py-2.5 md:rounded-[0.5rem]
              "
              >
                SUBMIT
              </Button>
            </form>
          ) : (
            // btn "CONNECT"
            <Button
              onClick={() => handleConnectClick(authType)}
              className="
                w-full px-4 py-3 rounded-[0.5rem]
                bg-gradient-blue-to-purple-angled
                text-white uppercase font-semibold
                text-xl leading-[1.7rem]
                shadow-md transition-all
                duration-200 hover:shadow-lg
              "
              disabled={!isWorking || isIntegrationDisabled}
            >
              {title === "Choose your unit" ? (
                <Plus height={"1.25rem"} />
              ) : (
                "CONNECT"
              )}
            </Button>
          )}

          {/* error modal */}
          <Modal
            open={isError}
            toggleOpen={() => setIsError(false)}
            title="Error"
          >
            <p>{errorText}</p>
          </Modal>
        </>
      )}

      {/* Consent Modal */}
      <ConsentModalKnowledgeBase
        open={isConsentModalOpen}
        toggleOpen={() => setIsConsentModalOpen(false)}
        onConsent={handleUserConsent}
      />
    </div>
  );
};
