import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoClose } from "react-icons/io5";
import { IconButton } from "@material-tailwind/react";
import { dequal } from "dequal";

import { useGetAllFollowUpsQuery } from "../../../../../store/api";
import { useReduxStore } from "../../../../hooks";
import { Button, Dropdown } from "../../../../components";
import { useAIConfigurationState } from "../../context";

export const FollowUps = () => {
  const [selectedFollowUps, setSelectedFollowUps] = useState<Option[]>([]);
  const { assistant, setAssistant, handleSaveChanges } =
    useAIConfigurationState();

  const { auth } = useReduxStore();

  const [open, setOpen] = useState(false);

  const {
    data: followUps,
    isLoading,
    isError,
  } = useGetAllFollowUpsQuery({ userID: auth.user.account_id });

  useEffect(() => {
    if (assistant?.follow_ups.length) {
      setSelectedFollowUps(
        assistant.follow_ups.map((followUp) => ({
          value: followUp,
          label: followUps?.find((f) => f.id === followUp)?.name || "",
        }))
      );
    }
  }, [assistant?.follow_ups, followUps]);

  const handleRemoveFollowUp = useCallback(
    (value: number) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setSelectedFollowUps((prev) => prev.filter((f) => f.value !== value));
        setAssistant((prev) => {
          if (!prev) return null;

          const follow_ups = prev.follow_ups.filter((f) => f !== value);

          handleSaveChanges({ follow_ups });
          return {
            ...prev,
            follow_ups,
          };
        });
      },
    [handleSaveChanges, setAssistant]
  );

  const optimisedOptions = useMemo(() => {
    return followUps?.length > 0
      ? followUps?.map((followUp) => ({
          value: followUp.id,
          label:
            followUp.name.length > 20
              ? followUp.name.slice(0, 20) + "..."
              : followUp.name,
        }))
      : [];
  }, [followUps]);

  const handleSetFollowUps = (communication: Option) => {
    setSelectedFollowUps((prev) => [...prev, communication]);
  };

  const handleSaveFollowUps = (prevState: boolean) => {
    if (
      dequal(
        assistant?.follow_ups.toSorted((a, b) => a - b),
        selectedFollowUps.map(({ value }) => value).toSorted((a, b) => a - b)
      )
    )
      return;

    handleSaveChanges({ follow_ups: selectedFollowUps.map((f) => f.value) });
    setOpen(!prevState);
  };

  const handleCleanAll = () => {
    setSelectedFollowUps([]);

    setAssistant((prev) => {
      if (!prev) return null;

      if (assistant?.follow_ups.length) {
        handleSaveChanges({ follow_ups: [] });
      }

      return { ...prev, follow_ups: [] };
    });
  };

  return (
    <Dropdown
      component="summary"
      open={open}
      onToggle={setOpen}
      onClickAway={handleSaveFollowUps}
      disabled={isLoading || isError || followUps?.length === 0}
      placeholder={
        selectedFollowUps.length > 0 ? (
          <div className="flex flex-nowrap gap-4">
            {selectedFollowUps.map((followUp) => (
              <div
                key={followUp.value}
                className="flex items-center flex-wrap bg-light-gray rounded-lg px-2"
              >
                <span className="!text-gray-400">{followUp.label}</span>
                <IconButton
                  color="blue-gray"
                  size="sm"
                  variant="text"
                  onClick={handleRemoveFollowUp(followUp.value)}
                >
                  <IoClose className="text-gray-500" size="1.5rem" />
                </IconButton>
              </div>
            ))}
          </div>
        ) : (
          "Follow ups"
        )
      }
      label="Follow ups"
      className="min-h-full grow [&>summary]:!border-light-black2 [&>summary]:min-h-full [&>summary]:grow [&>summary]:leading-[170%] [&>summary]:hover:!border-purple [&>summary]:hover:!text-purple"
      body={
        optimisedOptions.length > 0 ? (
          <div className="column gap-2 mb-4">
            {optimisedOptions
              .filter(
                (communication) =>
                  !selectedFollowUps.some(
                    (selected) => selected.value === communication.value
                  )
              )
              .map((communication) => (
                <Button
                  key={communication.value}
                  className="rounded-[0.7rem] hover:!bg-purple hover:!text-white"
                  variant="rounded"
                  onClick={() => handleSetFollowUps(communication)}
                >
                  {communication.label}
                </Button>
              ))}
          </div>
        ) : (
          <div className="center">
            <p className="text-gray-500">No data</p>
          </div>
        )
      }
      actions={
        optimisedOptions.length > 0 && (
          <Button className="rounded-[0.7rem]" onClick={handleCleanAll}>
            Clean All
          </Button>
        )
      }
    />
  );
};
