import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconGlobe: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3975_7111)">
        <path
          d="M10.0003 18.3346C5.39795 18.3346 1.66699 14.6037 1.66699 10.0013M10.0003 18.3346C14.6027 18.3346 18.3337 14.6037 18.3337 10.0013M10.0003 18.3346C5.83366 14.3259 5.83366 5.67671 10.0003 1.66797M10.0003 18.3346C14.167 14.3259 14.167 5.67671 10.0003 1.66797M1.66699 10.0013C1.66699 5.39893 5.39795 1.66797 10.0003 1.66797M1.66699 10.0013H18.3337M18.3337 10.0013C18.3337 5.39893 14.6027 1.66797 10.0003 1.66797"
          stroke="#384157"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3975_7111">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
