import { memo, useCallback, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  BarControllerChartOptions,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
  TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { useReduxStore } from "../../../../hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend
);

const data = {
  datasets: [
    {
      backgroundColor: [
        "#A7ABF9",
        "#E2DAFF",
        "#E2DAFF",
        "#F0F2F4",
        "#777EF5",
        "#A7ABF9",
        "#777EF5",
      ],
      borderRadius: 8,
      borderSkipped: false,
      barPercentage: 0.7,
      categoryPercentage: 0.7,
    },
  ],
};

const options: _DeepPartialObject<
  CoreChartOptions<"bar"> &
    ElementChartOptions<"bar"> &
    PluginChartOptions<"bar"> &
    DatasetChartOptions<"bar"> &
    ScaleChartOptions<"bar"> &
    BarControllerChartOptions
> = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      backgroundColor: "#4C5876",
      caretPadding: 3,
      position: "nearest",
      yAlign: "bottom",
    },
  },
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 20,
      },
    },
  },
};

const getFormattedLabes = (data: DashboardMessageUsage[]) =>
  data.map((message) =>
    message.day.length > 3 ? message.day.slice(0, 3) : message.day
  );

const getDatasetsData = (data: DashboardMessageUsage[]) =>
  data.map((message) => message.messages);

export const Chart = memo(() => {
  const { dashboard } = useReduxStore();

  const [hoveredData, setHoveredData] = useState<{
    messages: number | null;
    day: string | null;
  }>({ messages: null, day: null });

  const labels = useMemo(
    () => getFormattedLabes(dashboard.messageUsage || []),
    [dashboard.messageUsage]
  );

  const datasetsData = useMemo(
    () => getDatasetsData(dashboard.messageUsage || []),
    [dashboard.messageUsage]
  );

  const maxValue = Math.max(...datasetsData);

  const maxValueLabel = dashboard.messageUsage.find(
    (message) => message.messages === maxValue
  )?.day;

  const handleHover = useCallback(
    (context: TooltipItem<"bar">[]) => {
      if (context.length > 0) {
        const { dataIndex } = context[0];
        setHoveredData({
          messages: datasetsData[dataIndex],
          day: dashboard.messageUsage[dataIndex].day,
        });
      }
    },
    [dashboard.messageUsage, datasetsData]
  );

  const barData = useMemo(
    () => ({
      ...data,
      labels,
      datasets: [
        {
          ...data.datasets[0],
          data: datasetsData,
        },
      ],
    }),
    [datasetsData, labels]
  );

  const barOptions = useMemo(
    () => ({
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.plugins?.tooltip,
          callbacks: {
            title: function (context) {
              handleHover(context);
              return "";
            },
            label: function (context: any) {
              return context.raw;
            },
          },
        },
      },
    }),
    [handleHover]
  );

  return (
    <>
      <p className="flex items-center gap-5 mb-6">
        <span className="text-purple font-medium text-[2.4rem] min-w-[5rem]">
          {hoveredData.messages || maxValue}
        </span>
        <span className="text-light-black2 font-normal text-[1.1rem] max-w-[12rem]">
          messages were used on {hoveredData.day || maxValueLabel}
        </span>
      </p>
      <div className="relative w-full h-full mt-auto">
        <Bar data={barData} options={barOptions} />
      </div>
    </>
  );
});
