import { Account } from "../../../redesign/types";
import { ThunkWrapper } from "../../helper";
import supabase from "../../../supabase";

export const fetchAccountById = ThunkWrapper<Account | null, string>(
  "fetch/account",
  async (accountId) => {
    const { data } = await supabase
      .from("accounts")
      .select("*")
      .eq("id", accountId);

    return (data[0] as Account) || null;
  }
);
