import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconTrash: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.6673 4.16797H12.084M3.33398 4.16797H7.91732M7.91732 4.16797V3.7513C7.91732 2.60071 8.85006 1.66797 10.0007 1.66797V1.66797C11.1512 1.66797 12.084 2.60071 12.084 3.7513V4.16797M7.91732 4.16797H12.084M8.33398 10.0013V15.0013M11.6673 10.0013V15.0013M8.43587 18.3346H11.5654C13.0584 18.3346 14.3241 17.2368 14.5353 15.7589L15.6709 7.80939C15.757 7.20696 15.2895 6.66797 14.681 6.66797H5.32033C4.71178 6.66797 4.24432 7.20696 4.33038 7.80939L5.46602 15.7589C5.67716 17.2368 6.94292 18.3346 8.43587 18.3346Z"
        stroke="#4C5876"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
