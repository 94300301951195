import React from "react";
import { UserChat } from "../../../../../../components/UserChat";
import { getChannelIcon } from "../../../../../../utils";
import { Loader } from "../../../../../../components";
import styles from "../ChatList.styles.module.scss";

export const ChatListItems = ({
  chats,
  handleClickChat,
  getColor,
  showMoreButton,
  handleLoadMore,
  clientId,
  pageId,
  chatListRef,
  isChatsLoading,
}) => {
  const onLoadMore = async () => {
    const listElem = chatListRef.current;
    if (!listElem) return;

    // store old scroll
    const prevScrollTop = listElem.scrollTop;

    // fetch next page
    await handleLoadMore();

    requestAnimationFrame(() => {
      setTimeout(() => {
        if (chatListRef.current) {
          chatListRef.current.scrollTop = prevScrollTop;
        }
      }, 0);
    });
  };

  return (
    <div className={styles.chatListItems}>
      {isChatsLoading ? (
        <div className={styles.loaderContainer}>
          <Loader width={40} height={40} />
        </div>
      ) : chats.length > 0 ? (
        chats.map((chatItem, index) => (
          <UserChat
            onClick={() => handleClickChat(chatItem)}
            key={`${chatItem.client_id}-${chatItem.page_id}-${index}`}
            pageID={chatItem.page_id}
            assistant_id={chatItem.assistant_id}
            bgColor={getColor(chatItem.assistant_id)}
            connection={getChannelIcon(chatItem?.communication_channel)}
            full_name={
              chatItem.client_name ||
              chatItem.client_username ||
              chatItem.client_id
            }
            avatar_url={chatItem?.client_avatar_url}
            last_active={chatItem?.last_active}
            is_selected={
              chatItem.client_id === clientId && chatItem.page_id === pageId
            }
            unread={chatItem.unread_count}
            status={chatItem.funnel_status}
          />
        ))
      ) : (
        <div className={styles.preloadMessageList}>
          <p>No Chats Available at the Moment</p>
        </div>
      )}
      {chats.length > 0 && showMoreButton && (
        <button className={styles.more} onClick={onLoadMore}>
          more...
        </button>
      )}
    </div>
  );
};
