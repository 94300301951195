import React, { createContext, useContext } from "react";

type AIConfigurationState = {
  assistant: Assistant | null;
  setAssistant: React.Dispatch<React.SetStateAction<Assistant | null>>;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLanguage: Option;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<Option>>;
  selectedCommunicationStyle: Option | null;
  setSelectedCommunicationStyle: React.Dispatch<
    React.SetStateAction<Option | null>
  >;
  originalData: OriginalData;
  setOriginalData: React.Dispatch<React.SetStateAction<OriginalData>>;
  handleSaveChanges: (data?: UpdatedAssistantConfigurationsData) => void;
  isTyping: boolean;
  setIsTyping: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AIConfigurationContext = createContext<AIConfigurationState>({
  checked: false,
  setChecked: () => {},
  assistant: null,
  setAssistant: () => {},
  selectedLanguage: { value: 1, label: "English" },
  setSelectedLanguage: () => {},
  selectedCommunicationStyle: null,
  setSelectedCommunicationStyle: () => {},
  originalData: {
    botName: "",
    roleAndInstructions: "",
    selectedLanguage: {
      value: 0,
      label: "",
    },
    selectedCommunicationStyle: {
      value: 0,
      label: "",
    },
    welcomeMessage: "",
    followUps: [],
    metadata: {},
  },
  setOriginalData: () => {},
  handleSaveChanges: () => {},
  isTyping: false,
  setIsTyping: () => {},
});

export const useAIConfigurationState = () => useContext(AIConfigurationContext);
