import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconCalendar: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66667 2.5013H5C3.61929 2.5013 2.5 3.62059 2.5 5.0013V7.5013M6.66667 2.5013V1.66797M6.66667 2.5013H13.3333M6.66667 2.5013V3.33464M13.3333 2.5013H15C16.3807 2.5013 17.5 3.62059 17.5 5.0013V7.5013M13.3333 2.5013V1.66797M13.3333 2.5013V3.33464M2.5 7.5013V15.8346C2.5 17.2153 3.61929 18.3346 5 18.3346H15C16.3807 18.3346 17.5 17.2153 17.5 15.8346V7.5013M2.5 7.5013H17.5"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
