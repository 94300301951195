import { FC } from "react";
import { Button } from "../index";

type Props = {
  callback: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SomethingWentWrong: FC<Props> = ({ callback }) => {
  return (
    <div className="center w-full h-full">
      <Button className="w-auto" onClick={callback}>
        Try again
      </Button>
    </div>
  );
};
