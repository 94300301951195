import { useCallback, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useReduxStore } from "../../redesign/hooks/useReduxStore";

export const useOpenCartLanguage = (knowledgeBase?: string) => {
  const { auth } = useReduxStore();
  const [languagesOptions, setLanguagesOptions] = useState<any[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);

  const fetchLanguages = useCallback(async () => {
    if (!auth?.user?.account_id || !knowledgeBase) return;
    setLoadingLanguages(true);
    try {
      const { data } = await axios.get("/oc-inventory/setup-language", {
        params: {
          account_id: auth.user.account_id,
          knowledge_base: knowledgeBase,
        },
      });
      const options = data?.map((lang: string) => ({
        value: lang,
        label: lang,
      }));
      setLanguagesOptions(options);
    } catch (error: any) {
      console.error("Error fetching languages", error);
      toast.error(error.message || "Languages cannot be displayed");
    } finally {
      setLoadingLanguages(false);
    }
  }, [auth?.user?.account_id, knowledgeBase]);

  return {
    languagesOptions,
    loadingLanguages,
    selectedLanguage,
    setSelectedLanguage,
    fetchLanguages,
  };
};
