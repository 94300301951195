import { useEffect } from "react";

import { fetchGeneralStatistic } from "../../../../../store/slices/dashboard";
import { useAppDispatch, useReduxStore } from "../../../../hooks";

import { Skeleton, SomethingWentWrong } from "../../../../components";

import { List } from "./List";

export const StatisticList = () => {
  const dispatch = useAppDispatch();
  const { dashboard } = useReduxStore();

  useEffect(() => {
    dispatch(fetchGeneralStatistic());
  }, [dispatch]);

  return (
    <>
      {/* Loading view */}
      {dashboard.fetchingGeneralStatistic && (
        <Skeleton classNameWrapper="!h-full" classNameItem="!h-[11rem]" />
      )}

      {/* Success data view */}
      {!dashboard.fetchingGeneralStatistic &&
        !dashboard.generalStatisticError && <List />}

      {/* Error view */}
      {!dashboard.fetchingGeneralStatistic &&
        dashboard.generalStatisticError && (
          <SomethingWentWrong
            callback={() => dispatch(fetchGeneralStatistic())}
          />
        )}
    </>
  );
};
