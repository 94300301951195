import React, { FC, memo, useEffect } from "react";
import { ITimezoneOption } from "react-timezone-select";
import { Button } from "@material-tailwind/react";

type Props = {
  onClick: (value: string) => void;
  searchValue: string;
  setTimeonesLength: (length: number) => void;
  options: ITimezoneOption[];
};

export const TimezoneBody: FC<Props> = memo(
  ({ onClick, searchValue, setTimeonesLength, options }) => {
    const filteredOptions = options.filter(({ label }) =>
      label.toLowerCase().includes(searchValue.toLocaleLowerCase())
    );

    useEffect(() => {
      setTimeonesLength(filteredOptions.length);
    }, [filteredOptions.length, setTimeonesLength]);

    return (
      <ul className="column max-h-[21rem] overflow-y-auto pr-2">
        {filteredOptions.map((option) => (
          <li className="m-0 before:hidden" key={option.value}>
            <Button
              variant="text"
              className="min-w-full flex items-start"
              color="blue-gray"
              onClick={() => onClick(option.value)}
            >
              <span className="body-2 font-normal">{option.label}</span>
            </Button>
          </li>
        ))}
      </ul>
    );
  }
);
