import React, { useState } from "react";
import classNames from "classnames";

import { Button, Checkbox, Dropdown } from "../../../components";
import {
  IconAssistants,
  IconCalendar,
  IconChannels,
  IconFunnelCRM,
  IconGlobe,
  IconTarget,
  IconUserTag,
} from "../../../assets";
import {
  Instagram,
  Logo,
  Messenger,
  Telegram,
  Viber,
  WhatsApp,
} from "../../../../assets/icons";
import {
  handleChangeCheckboxHelper,
  handleCleanAllHelper,
} from "../../../utils";

const channelsIcons = [
  { icon: <Telegram />, isActive: true },
  { icon: <Telegram /> },
  { icon: <Instagram /> },
  { icon: <Messenger /> },
  { icon: <Viber /> },
  { icon: <WhatsApp /> },
  { icon: <WhatsApp /> },
  { icon: <Messenger />, isActive: true },
  { icon: <Messenger /> },
  { icon: <Logo /> },
];

export const StatisticDropdowns = () => {
  const [checkedAssistants, setCheckedAssistants] = useState<string[]>([]);
  const [checkedCampaigns, setCheckedCampaigns] = useState<string[]>([]);
  const [checkedDates, setCheckedDates] = useState<string[]>([]);
  const [checkedPlatforms, setCheckedPlatforms] = useState<string[]>([]);
  const [checkedClientStatuses, setCheckedClientStatuses] = useState<string[]>(
    []
  );
  const [checkedFunnels, setCheckedFunnels] = useState<string[]>([]);

  return (
    <div className={classNames("flex justify-start gap-[1.2rem] flex-wrap")}>
      <Dropdown
        icon={<IconAssistants />}
        placeholder="Assistants"
        body={
          <div className="column gap-4 mb-4">
            <Checkbox
              label="Veronica"
              value="veronica"
              onChange={handleChangeCheckboxHelper(setCheckedAssistants)}
              checked={checkedAssistants.includes("veronica")}
            />
            <Checkbox
              label="Liza"
              value="liza"
              onChange={handleChangeCheckboxHelper(setCheckedAssistants)}
              checked={checkedAssistants.includes("liza")}
            />
          </div>
        }
        actions={
          <div className={classNames("column gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedAssistants)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        endAdornment={
          checkedAssistants && checkedAssistants.length > 0 ? (
            <div
              className={classNames(
                "center bg-purple rounded-sm min-w-[1.5rem] min-h-[1.5rem] rounded-[0.2rem] font-normal text-base leading-[120%] text-white"
              )}
            >
              <p>{checkedAssistants.length}</p>
            </div>
          ) : null
        }
        classNameMenu="min-w-[15rem]"
      />
      <Dropdown
        icon={<IconChannels />}
        placeholder="Channels"
        body={
          <div className={"flex flex-wrap gap-4 min-w-full mb-5"}>
            {channelsIcons.map(({ icon, isActive }, index) => (
              <button
                key={index}
                type="button"
                className={classNames(
                  "center basis-[calc((100%-1rem*2)/3)] md:basis-[calc((100%-1rem*4)/5)] border rounded-lg transition-all duration-300 ease-in-out p-2 rounded-[0.9rem]",
                  isActive ? "border-purple" : "border-transparent"
                )}
              >
                {icon}
              </button>
            ))}
          </div>
        }
        actions={
          <div className={classNames("row w-full gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedAssistants)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        classNameMenu="min-w-[15rem] md:min-w-[24.5rem]"
      />
      <Dropdown
        icon={<IconTarget />}
        placeholder="Campaign"
        body={
          <div className={classNames("column gap-4 mb-4")}>
            <Checkbox
              label="Campaign 1"
              value="campaign1"
              onChange={handleChangeCheckboxHelper(setCheckedCampaigns)}
              checked={checkedCampaigns?.includes("campaign1")}
            />
            <Checkbox
              label="Campaign 2"
              value="campaign2"
              onChange={handleChangeCheckboxHelper(setCheckedCampaigns)}
              checked={checkedCampaigns?.includes("campaign2")}
            />
            <Checkbox
              label="Campaign 3"
              value="campaign3"
              onChange={handleChangeCheckboxHelper(setCheckedCampaigns)}
              checked={checkedCampaigns?.includes("campaign3")}
            />
          </div>
        }
        actions={
          <div className={classNames("column gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedCampaigns)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        classNameMenu="min-w-[15rem]"
      />
      <Dropdown
        icon={<IconCalendar />}
        placeholder="Date"
        body={
          <div className={classNames("column gap-4 mb-4")}>
            <Checkbox
              label="3 days"
              value="3days"
              onChange={handleChangeCheckboxHelper(setCheckedDates)}
              checked={checkedDates.includes("3days")}
            />
            <Checkbox
              label="7 days"
              value="7days"
              onChange={handleChangeCheckboxHelper(setCheckedDates)}
              checked={checkedDates.includes("7days")}
            />
            <Checkbox
              label="30 days"
              value="30days"
              onChange={handleChangeCheckboxHelper(setCheckedDates)}
              checked={checkedDates.includes("30days")}
            />
            <Checkbox
              label="All time"
              value="all-time"
              onChange={handleChangeCheckboxHelper(setCheckedDates)}
              checked={checkedDates.includes("all-time")}
            />
            <Checkbox
              label="Custom Range"
              value="custom-range"
              disabled
              onChange={handleChangeCheckboxHelper(setCheckedDates)}
              checked={checkedDates.includes("custom-range")}
            />
          </div>
        }
        actions={
          <div className={classNames("column gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedDates)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        classNameMenu="min-w-[15rem]"
      />
      <Dropdown
        icon={<IconGlobe />}
        placeholder="Platform"
        body={
          <div className={classNames("column gap-4 mb-4")}>
            <Checkbox
              label="Facebook"
              value="facebook"
              onChange={handleChangeCheckboxHelper(setCheckedPlatforms)}
              checked={checkedPlatforms.includes("facebook")}
            />
            <Checkbox
              label="Instagram"
              value="instagram"
              onChange={handleChangeCheckboxHelper(setCheckedPlatforms)}
              checked={checkedPlatforms.includes("instagram")}
            />
            <Checkbox
              label="Google Ads"
              value="google-ads"
              onChange={handleChangeCheckboxHelper(setCheckedPlatforms)}
              checked={checkedPlatforms.includes("google-ads")}
            />
          </div>
        }
        actions={
          <div className={classNames("column gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedPlatforms)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        classNameMenu="min-w-[15rem]"
      />
      <Dropdown
        icon={<IconUserTag />}
        placeholder="Client status CRM"
        body={
          <div className={classNames("column gap-4 mb-4")}>
            <Checkbox
              label="Prospective Clients"
              value="prospective-clients"
              onChange={handleChangeCheckboxHelper(setCheckedClientStatuses)}
              checked={checkedClientStatuses.includes("prospective-clients")}
            />
            <Checkbox
              label="Active Clients"
              value="active-clients"
              onChange={handleChangeCheckboxHelper(setCheckedClientStatuses)}
              checked={checkedClientStatuses.includes("active-clients")}
            />
            <Checkbox
              label="Closed Clients"
              value="closed-clients"
              onChange={handleChangeCheckboxHelper(setCheckedClientStatuses)}
              checked={checkedClientStatuses.includes("closed-clients")}
            />
            <Checkbox
              label="Inactive Clients"
              value="inactive-clients"
              onChange={handleChangeCheckboxHelper(setCheckedClientStatuses)}
              checked={checkedClientStatuses.includes("inactive-clients")}
            />
            <Checkbox
              label="Lost Clients"
              value="lost-clients"
              onChange={handleChangeCheckboxHelper(setCheckedClientStatuses)}
              checked={checkedClientStatuses.includes("lost-clients")}
            />
          </div>
        }
        actions={
          <div className={classNames("flex gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedClientStatuses)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        classNameMenu="min-w-[22rem]"
      />
      <Dropdown
        icon={<IconFunnelCRM />}
        placeholder="Funnel CRM"
        body={
          <div className={classNames("column gap-4 mb-4")}>
            <Checkbox
              label="New"
              value="new"
              onChange={handleChangeCheckboxHelper(setCheckedFunnels)}
              checked={checkedFunnels.includes("new")}
            />
            <Checkbox
              label="In progress"
              value="in-progress"
              onChange={handleChangeCheckboxHelper(setCheckedFunnels)}
              checked={checkedFunnels.includes("in-progress")}
            />
            <Checkbox
              label="Ordered"
              value="ordered"
              onChange={handleChangeCheckboxHelper(setCheckedFunnels)}
              checked={checkedFunnels.includes("ordered")}
            />
          </div>
        }
        actions={
          <div className={classNames("flex gap-4")}>
            <Button>Apply</Button>
            <Button
              onClick={handleCleanAllHelper(setCheckedFunnels)}
              variant="secondary"
            >
              Clean all
            </Button>
          </div>
        }
        classNameMenu="min-w-[22rem]"
      />
    </div>
  );
};
