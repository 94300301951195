import React, { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useReduxStore } from "../../../../hooks";
import { updateData } from "../../../../../service/supabase";
import { Switcher } from "../../../../components";

import { useOptimizeInstructions } from "../../../../../hooks/useOptimizeInstructions";
import { createABot } from "../../../../../helper/create-data";

import {
  COMMUNICATION_STYLE_EN,
  COMMUNICATION_STYLE_RU,
  LANGUAGE,
} from "../../../../../constants/configuration";

import {
  getActiveAssistant,
  fetchInstructions,
  setSelectedAssistant,
} from "../../../../../store/slices/assistantSlice";
import { useAIConfigurationState } from "../../context";

export const AssistantSwitcher = memo(() => {
  const { auth, assistant: stroreAssistant } = useReduxStore();
  const dispatch = useDispatch();
  const {
    checked,
    setChecked,
    setSelectedLanguage,
    setSelectedCommunicationStyle,
    assistant,
    setAssistant,
    setOriginalData,
  } = useAIConfigurationState();

  const { setRoleAndInstructions } = useOptimizeInstructions(
    auth?.user.account_id,
    assistant?.id
  );

  const getCommunicationData = (language: number, val = 1) => {
    return language === val ? COMMUNICATION_STYLE_EN : COMMUNICATION_STYLE_RU;
  };

  useEffect(() => {
    if (
      !stroreAssistant?.selectedAssistant?.value ||
      !stroreAssistant?.listOfAssistants.length
    )
      return;

    const foundAssistant = stroreAssistant?.listOfAssistants.find(
      ({ id }: { id: string }) =>
        id === stroreAssistant?.selectedAssistant.value
    );

    if (!foundAssistant) {
      console.log("No matching assistant found");
      return; // Early return if no assistant is found
    }

    const {
      id,
      bot_name,
      language,
      communication_style,
      welcome_message,
      instructions,
      knowledge_base,
      follow_ups,
      metadata,
      ...other
    } = foundAssistant;

    setAssistant(foundAssistant);

    dispatch(
      getActiveAssistant({
        id,
        bot_name,
        language,
        communication_style,
        welcome_message,
        instructions,
        knowledge_base,
        follow_ups,
        ...other,
      })
    );

    const selectedLanguage = LANGUAGE.find(({ value }) => value === language);
    const selectedCommunicationStyle = getCommunicationData(language).find(
      ({ value }) => value === communication_style
    );

    setSelectedLanguage(selectedLanguage!);
    setSelectedCommunicationStyle(selectedCommunicationStyle!);
    setRoleAndInstructions(instructions);

    setChecked(stroreAssistant?.selectedAssistant?.status === 1);

    setOriginalData(
      createABot(
        bot_name,
        instructions,
        selectedLanguage,
        selectedCommunicationStyle,
        welcome_message,
        follow_ups ? follow_ups : []
      )
    );
  }, [
    dispatch,
    setAssistant,
    setChecked,
    setOriginalData,
    setRoleAndInstructions,
    setSelectedCommunicationStyle,
    setSelectedLanguage,
    stroreAssistant?.listOfAssistants,
    stroreAssistant?.selectedAssistant?.status,
    stroreAssistant?.selectedAssistant.value,
  ]);

  const handleChange = useCallback(async () => {
    if (!auth?.user?.account_id) return;

    try {
      const status = !checked ? 1 : 0;

      await updateData(
        "assistants",
        { status },
        { id: stroreAssistant?.selectedAssistant?.value }
      );

      setChecked((prev) => !prev);

      dispatch(fetchInstructions(auth?.user?.account_id));
    } catch (error: unknown) {
      if (error && typeof error === "object" && "message" in error) {
        toast.error(error.message as string);
      }
    }
  }, [
    auth?.user?.account_id,
    checked,
    dispatch,
    setChecked,
    stroreAssistant?.selectedAssistant?.value,
  ]);

  useEffect(() => {
    if (!stroreAssistant?.listOfAssistants.length) return;
    let item = null;
    if (!assistant?.bot_name) {
      item = stroreAssistant?.listOfAssistants[0];
    } else {
      item = stroreAssistant?.listOfAssistants.find(
        ({ bot_name }: { bot_name: string }) => bot_name === assistant?.bot_name
      );
      if (!item) item = stroreAssistant?.listOfAssistants[0];
    }

    dispatch(
      setSelectedAssistant({
        value: item.id,
        label: item.bot_name,
        status: item.status,
      })
    );

    setChecked(item.status === 1);
  }, [
    assistant?.bot_name,
    dispatch,
    setChecked,
    stroreAssistant?.listOfAssistants,
  ]);

  return <Switcher checked={checked} onChange={handleChange} />;
});
