import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconAverageDialog: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 19V11H7V19M13 19H7M13 19H17C18.1046 19 19 18.1046 19 17V8C19 6.89543 18.1046 6 17 6H15C13.8954 6 13 6.89543 13 8V19ZM7 19V3C7 1.89543 6.10457 1 5 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H7Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
