import React, { FC, Ref } from "react";

import { Switch, SwitchProps } from "@material-tailwind/react";

type Props = {
  ref?: Ref<HTMLInputElement>;
} & Omit<SwitchProps, "color" | "ref">;

export const Switcher: FC<Props> = ({ ...props }) => {
  return (
    <Switch
      ripple={false}
      className="h-full w-full checked:bg-purple"
      containerProps={{
        className: "w-16 h-9",
      }}
      circleProps={{
        className: "before:hidden h-7 w-7 left-0.5 border-none",
      }}
      {...props}
    />
  );
};
