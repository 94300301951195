import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconTarget: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3975_6200)">
        <path
          d="M9.99984 16.668C6.31794 16.668 3.33317 13.6832 3.33317 10.0013M9.99984 16.668C13.6817 16.668 16.6665 13.6832 16.6665 10.0013M9.99984 16.668V15.0013M9.99984 16.668V18.3346M3.33317 10.0013C3.33317 6.3194 6.31794 3.33464 9.99984 3.33464M3.33317 10.0013H1.6665M3.33317 10.0013H4.99984M16.6665 10.0013C16.6665 6.3194 13.6817 3.33464 9.99984 3.33464M16.6665 10.0013H18.3332M16.6665 10.0013H14.9998M9.99984 3.33464V1.66797M9.99984 3.33464V5.0013M11.6665 10.0013C11.6665 10.9218 10.9203 11.668 9.99984 11.668C9.07936 11.668 8.33317 10.9218 8.33317 10.0013C8.33317 9.08083 9.07936 8.33464 9.99984 8.33464C10.9203 8.33464 11.6665 9.08083 11.6665 10.0013Z"
          stroke="#384157"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3975_6200">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
