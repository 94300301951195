import styles from "./ClientCardInfo.module.scss";
import { FaRegClock } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { LuPhone } from "react-icons/lu";
import { TbWorldSearch } from "react-icons/tb";
import { getGMTOffset } from "../../../../helpers";
import { formatDate } from "../../../../../../../utils";

const ClientCardInfo = ({
  clientCardData: { last_active, phone_number, full_name },
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {full_name && (
          <div className={styles.item}>
            <FaRegUser size={"2rem"} className={styles.icon} />
            {full_name}
          </div>
        )}
        {phone_number && (
          <div className={styles.item}>
            <LuPhone size={"2rem"} className={styles.icon} />
            {phone_number}
          </div>
        )}
        {last_active && (
          <div className={styles.item}>
            <TbWorldSearch size={"2rem"} className={styles.icon} />
            {getGMTOffset(last_active)}
          </div>
        )}
        {last_active && (
          <div className={styles.item}>
            <FaRegClock size={"2rem"} className={styles.icon} />
            {formatDate(last_active)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientCardInfo;
