import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Title } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { addData } from "../../../../../service/supabase";

import { useReduxStore } from "../../../../hooks";

import { AssistantSwitcher } from "./AssistantSwitcher";

import { useSubscription } from "../../../../../pages/SubscriptionPage/useSubscription";
import { normalizeSubscriptionPlan } from "../../../../../helper";

import {
  fetchInstructions,
  _updateListOfAssistants,
  setSelectedAssistant,
} from "../../../../../store/slices/assistantSlice";
import { useAIConfigurationState } from "../../context";

export const AssistantProfileHeader = memo(() => {
  const { auth, assistant: stroreAssistant } = useReduxStore();
  const dispatch = useDispatch();

  const { subscriptionPlan } = useSubscription(auth?.user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  const { assistant, setAssistant } = useAIConfigurationState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!auth?.user?.account_id) return;

    dispatch(fetchInstructions(auth.user.account_id));
  }, [auth?.user?.account_id, dispatch]);

  const updateListOfAssistants = useSelector(
    _updateListOfAssistants
  ) as SelectedAssistant[];

  const handleSelectAssistant = useCallback(
    (assistant: SelectedAssistant) => {
      dispatch(setSelectedAssistant(assistant));
      setOpen(false);
    },
    [dispatch]
  );

  const handleAddNewAssistant = async () => {
    if (!auth?.user?.account_id) return;

    try {
      const newBotName = assistant?.bot_name
        ? assistant?.bot_name +
          " " +
          (stroreAssistant?.listOfAssistants.length + 1)
        : "Вероніка";
      const { ...other } = stroreAssistant?.activeAssistant || {};
      const cloneData = { ...other, bot_name: newBotName, id: undefined };

      await addData("assistants", cloneData);

      setAssistant((prev) => (prev ? { ...prev, bot_name: newBotName } : null));

      dispatch(fetchInstructions(auth.user.account_id));
      toast.success(
        `${newBotName} has been cloned from ${assistant?.bot_name}, now edit and save it`
      );
    } catch (error) {
      if (error && typeof error === "object" && "message" in error) {
        toast.error(error.message as string);
      }
    }
  };

  return (
    <div className="column gap-4 md:gap-0 md:flex-row justify-between md:items-center pb-3 border-b border-gray-300">
      <div className="flex items-center justify-between md:gap-4 md:justify-stretch">
        <Title text="Assistant configurations" />
        <AssistantSwitcher />
      </div>
      <div className="flex items-center justify-between md:justify-stretch md:gap-6 h-12">
        <Dropdown
          open={open}
          onToggle={setOpen}
          placeholder={
            stroreAssistant?.selectedAssistant?.label || "Assistants"
          }
          classNameMenu="min-w-[16rem] left-auto right-0"
          className="[&>button]:!border-light-black2 [&>button]:hover:!border-purple [&>button]:hover:!text-purple [&>button]:h-full"
          body={
            <div className="column gap-2">
              {updateListOfAssistants.map((assistant) => (
                <Button
                  key={assistant.value}
                  className="rounded-[0.7rem] hover:!bg-purple hover:!text-white text-nowrap"
                  variant="rounded"
                  onClick={() => handleSelectAssistant(assistant)}
                >
                  {assistant.label}
                </Button>
              ))}
            </div>
          }
        />
        <Button
          className="min-h-full w-auto md:w-full font-medium [&>span]:body-1 [&>span]:text-light max-h-full py-0"
          onClick={handleAddNewAssistant}
          title={"Add a new assistant"}
          disabled={normalizedPlan === null}
        >
          Add new
        </Button>
      </div>
    </div>
  );
});
