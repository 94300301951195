import React, { useState } from "react";
import { useFormik } from "formik";

import {
  Button,
  Modal,
  TextField,
  Title,
  Checkbox,
} from "../../../../../components";
import { useAIConfigurationState } from "../../../context";

import { validationSchema } from "./validationSchema";

type InitialValues = {
  switch_stop_word: string;
  silent_switch_to_operator: boolean;
};

const initialValues: InitialValues = {
  switch_stop_word: "",
  silent_switch_to_operator: false,
};

export const SwitchToOperator = () => {
  const [open, setOpen] = useState(false);

  const { assistant, handleSaveChanges } = useAIConfigurationState();

  const onSubmit = (values: InitialValues) => {
    handleSaveChanges({
      ...assistant,
      metadata: {
        ...assistant?.metadata,
        ...values,
      },
    });

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      switch_stop_word: assistant?.metadata?.switch_stop_word || "",
      silent_switch_to_operator:
        assistant?.metadata?.silent_switch_to_operator || false,
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const disabled =
    (formik.values.switch_stop_word.trim() ===
      assistant?.metadata?.switch_stop_word &&
      formik.values.silent_switch_to_operator ===
        assistant?.metadata?.silent_switch_to_operator) ||
    Object.values(formik.errors).length > 0;

  return (
    <>
      <Button onClick={() => setOpen(true)}>Switch to Operator</Button>
      <Modal
        title={<Title text="Switch to Operator" />}
        open={open}
        size="sm"
        toggleOpen={() => setOpen(false)}
        classNameWrapper="p-9"
      >
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="column gap-10 mb-10">
              <TextField
                inputLabel="Keyword to switch to operator"
                placeholder="Enter which stop word"
                className="!border !border-light-black2 !placeholder:text-gray-400 !rounded-[0.9rem] h-[3.5rem] !text-[1.2rem] !bg-transparent !px-6 !py-4"
                formik={formik}
                name="switch_stop_word"
                classNameWrapper="h-full [&>div]:h-[3.46rem]"
              />
              <Checkbox
                label={"Silent switching to the operator"}
                className="w-[1.4rem] h-[1.4rem]"
                labelProps={{
                  className: "text-gray-500 font-medium text-[1.2rem]",
                }}
                formik={formik}
                checked={formik.values.silent_switch_to_operator}
                name="silent_switch_to_operator"
              />
            </div>
            <div className="flex justify-end gap-6">
              <Button
                className="font-semibold text-[1.35rem]"
                variant="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="font-semibold text-[1.35rem]"
                disabled={disabled}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
