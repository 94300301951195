import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  RoundedBox,
  TextField,
  Title,
} from "../../../components";
import classNames from "classnames";
import {
  handleChangeCheckboxHelper,
  handleCleanAllHelper,
} from "../../../utils";
import { IconCalendar, IconGlobe } from "../../../assets";
import { GoGear } from "react-icons/go";
import { CustomizeTableModal } from "../CustomizeTableModal";
import { LuSearch } from "react-icons/lu";

export const AdvertisingAnalysis = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [checkedDates, setCheckedDates] = useState<string[]>([]);
  const [checkedPlatforms, setCheckedPlatforms] = useState<string[]>([]);

  return (
    <>
      <RoundedBox>
        <div className="mb-9">
          <Title text="Advertising analysis" />
        </div>
        <div className="column items-start mb-7 gap-4 md:gap-0 md:flex-row md:items-center justify-between">
          <TextField
            icon={<LuSearch />}
            label="Search campaign"
            value={searchValue}
            onChangeText={setSearchValue}
            className="border-none w-full md:max-w-[40%] min-h-[3rem] pl-10"
            classNameWrapper="min-h-[3rem]"
          />
          <div className="column w-full items-start gap-2 md:flex-row md:items-center md:w-auto">
            <Dropdown
              icon={<IconCalendar />}
              placeholder="Date"
              className="w-full md:w-auto"
              body={
                <div className={classNames("column gap-4")}>
                  <Checkbox
                    label="3 days"
                    value="3days"
                    onChange={handleChangeCheckboxHelper(setCheckedDates)}
                    checked={checkedDates.includes("3days")}
                  />
                  <Checkbox
                    label="7 days"
                    value="7days"
                    onChange={handleChangeCheckboxHelper(setCheckedDates)}
                    checked={checkedDates.includes("7days")}
                  />
                  <Checkbox
                    label="30 days"
                    value="30days"
                    onChange={handleChangeCheckboxHelper(setCheckedDates)}
                    checked={checkedDates.includes("30days")}
                  />
                  <Checkbox
                    label="All time"
                    value="all-time"
                    onChange={handleChangeCheckboxHelper(setCheckedDates)}
                    checked={checkedDates.includes("all-time")}
                  />
                  <Checkbox
                    label="Custom Range"
                    value="custom-range"
                    disabled
                    onChange={handleChangeCheckboxHelper(setCheckedDates)}
                    checked={checkedDates.includes("custom-range")}
                  />
                </div>
              }
              classNameMenu="min-w-[15rem]"
            />
            <Dropdown
              icon={<IconGlobe />}
              placeholder="Platform"
              className="w-full md:w-auto"
              body={
                <div className={classNames("column gap-4 mb-4")}>
                  <Checkbox
                    label="Facebook"
                    value="facebook"
                    onChange={handleChangeCheckboxHelper(setCheckedPlatforms)}
                    checked={checkedPlatforms.includes("facebook")}
                  />
                  <Checkbox
                    label="Instagram"
                    value="instagram"
                    onChange={handleChangeCheckboxHelper(setCheckedPlatforms)}
                    checked={checkedPlatforms.includes("instagram")}
                  />
                  <Checkbox
                    label="Google Ads"
                    value="google-ads"
                    onChange={handleChangeCheckboxHelper(setCheckedPlatforms)}
                    checked={checkedPlatforms.includes("google-ads")}
                  />
                </div>
              }
              actions={
                <div className={classNames("column gap-4")}>
                  <Button>Apply</Button>
                  <Button
                    onClick={handleCleanAllHelper(setCheckedPlatforms)}
                    variant="secondary"
                  >
                    Clean all
                  </Button>
                </div>
              }
              classNameMenu="min-w-[15rem]"
            />
            <Button>
              <span
                className="flex justify-center items-center gap-2"
                onClick={() => setIsOpen(true)}
              >
                <span>Customize</span>
                <GoGear className="text-white" size={"1.5rem"} />
              </span>
            </Button>
          </div>
        </div>
        <RoundedBox className="min-h-[30rem] !bg-light-gray center">
          <Title text="Coming soon..." component="h2" />
        </RoundedBox>
      </RoundedBox>
      <CustomizeTableModal open={isOpen} toggleOpen={() => setIsOpen(false)} />
    </>
  );
};
