import React, { FC } from "react";
import classNames from "classnames";

const styles = {
  h1: "title-1",
  h2: "title-2",
  h3: "title-3",
  h4: "title-4",
  h5: "title-5",
  h6: "title-6",
};

type Component = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type Props = {
  text: string;
  underline?: boolean;
  component?: Component;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export const Title: FC<Props> = ({
  text,
  className,
  component: Component = "h1",
  underline = false,
  ...props
}) => {
  return (
    <Component
      className={classNames(
        "text-gray-500 leading-tight",
        styles[Component],
        {
          "pb-4 border-b border-gray-300": underline,
        },
        className
      )}
      {...props}
    >
      {text}
    </Component>
  );
};
