import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./ClientCardForm.module.scss";
import Select, { GroupBase } from "react-select";
import { toast } from "react-toastify";
import { FaRegUser, FaRegBookmark } from "react-icons/fa";
import { PiFunnelBold } from "react-icons/pi";
import { LiaUserTagSolid } from "react-icons/lia";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Accordion } from "../../../../../../../components/Accordion/Accordion";
import { CustomDropdownIndicator } from "../../../../../../../components/CustomDropdownIndicator/CustomDropdownIndicator";
import { CustomValueContainer } from "../../../../../../../components/CustomDropdownIndicator/CustomValueContainer";
import { CustomOption } from "../../../../../../../components/CustomDropdownIndicator/CustomOption";
import { SelectedValueWithRemoveCross } from "../../../../../../../components/CustomDropdownIndicator/SelectedValueWithRemoveCross";
import { CustomFilterMenuList } from "../../../../../../../components/CustomFilterMenuList/CustomFilterMenuList";
import { customSelectStyles } from "../../../../../../../constants/general";

import { useReferenceData } from "../../../../../../../hooks/chats/useReferenceData";
import { useClientCardData } from "../../../../../../../hooks/chats/useClientCardData";
import { CustomDropdown } from "../../../../../../../components/CustomDropdown/CustomDropdown";
import { deleteEditFilters } from "./filters/deleteEditeFilters";

interface OptionItem {
  value: string;
  label: string;
  createdAt?: string;
}

interface ClientCardData {
  account_id: string;
  client_id: string;
  manager_id: string | string[];
  labels: string;
  funnel_status: string;
  type: string;
  [key: string]: any;
}

interface ClientCardFormProps {
  clientCardData: ClientCardData;
  setClientCardData: (data: ClientCardData) => void;
}

export default function ClientCardForm({
  clientCardData,
  setClientCardData,
}: ClientCardFormProps) {
  const accountId = clientCardData?.account_id;
  const clientId = clientCardData?.client_id;

  const { sendDataInSB, updateDataInSB } = useClientCardData(
    accountId,
    setClientCardData
  );

  const {
    managersOptions,
    labelsOptions,
    funnelStatusOptions,
    clientTypeOptions,
    loadReferenceData,
    setLabelsOptions,
    setFunnelStatusOptions,
    setClientTypeOptions,
  } = useReferenceData(accountId);

  // Update state types to match react-select's expected types
  const [selectedManagers, setSelectedManagers] = useState<OptionItem | null>(
    null
  );
  const [selectedLabel, setSelectedLabel] = useState<OptionItem | null>(null);
  const [selectedFunnelStatus, setSelectedFunnelStatus] =
    useState<OptionItem | null>(null);
  const [selectedClientType, setSelectedClientType] =
    useState<OptionItem | null>(null);
  const [selectedManagerId, setSelectedManagerId] = useState(
    clientCardData?.manager_id || null
  );

  // Menu states
  const [isManagerMenuOpen, setIsManagerMenuOpen] = useState<boolean>(false);

  const [isAccordionOpen, setAccordionOpen] = useState({
    managers: false,
    labels: false,
    funnelStatus: false,
    clientType: false,
  });

  const toggleAccordion = (section: string) => {
    setAccordionOpen((prev) => ({
      managers: section === "managers" ? !prev.managers : false,
      labels: section === "labels" ? !prev.labels : false,
      funnelStatus: section === "funnelStatus" ? !prev.funnelStatus : false,
      clientType: section === "clientType" ? !prev.clientType : false,
    }));
  };

  useEffect(() => {
    loadReferenceData();
  }, [loadReferenceData]);

  useEffect(() => {
    if (managersOptions.length > 0) {
      if (Array.isArray(clientCardData.manager_id)) {
        const preselected = managersOptions.find((opt) =>
          clientCardData.manager_id.includes(opt.value)
        );
        setSelectedManagers(preselected || null);
      } else if (clientCardData.manager_id) {
        const found = managersOptions.find(
          (opt) => opt.value === clientCardData.manager_id
        );
        setSelectedManagers(found || null);
      } else {
        setSelectedManagers(null);
      }
    }

    if (labelsOptions.length > 0 && clientCardData.labels) {
      const foundLabel = labelsOptions.find(
        (opt) => opt.value === clientCardData.labels
      );
      setSelectedLabel(foundLabel || null);
    }

    if (funnelStatusOptions.length > 0 && clientCardData.funnel_status) {
      const foundFunnel = funnelStatusOptions.find(
        (opt) => opt.value === clientCardData.funnel_status
      );
      setSelectedFunnelStatus(foundFunnel || null);
    }

    if (clientTypeOptions.length > 0 && clientCardData.type) {
      const foundType = clientTypeOptions.find(
        (opt) => opt.value === clientCardData.type
      );
      setSelectedClientType(foundType || null);
    }
  }, [
    clientCardData,
    managersOptions,
    labelsOptions,
    funnelStatusOptions,
    clientTypeOptions,
  ]);

  const { handleUniversalDelete, handleUniversalEdit } = deleteEditFilters({
    accountId,
    clientId,
    clientCardData,
    setClientCardData,
    updateDataInSB,
  });

  const handleManagersChange = (newValue: OptionItem | null) => {
    setSelectedManagers(newValue);
  };

  const handleApplyManagers = async () => {
    const managerId = selectedManagers?.value || null;
    await updateDataInSB("clients", { manager_id: managerId }, clientId);
    setIsManagerMenuOpen(false);
    setClientCardData({
      ...clientCardData,
      manager_id: managerId,
    });
  };

  const handleCleanManagers = () => {
    setSelectedManagers(null);
    updateDataInSB("clients", { manager_id: null }, clientId);
    setIsManagerMenuOpen(false);
    setClientCardData({
      ...clientCardData,
      manager_id: null,
    });
  };

  const handleChangeLabel = (newVal: OptionItem | null) => {
    setSelectedLabel(newVal);
    const labelsValue = newVal?.value || "";
    updateDataInSB("clients", { labels: labelsValue }, clientId);
    setClientCardData({
      ...clientCardData,
      labels: labelsValue,
    });
  };

  const handleChangeFunnelStatus = (newVal: OptionItem | null) => {
    setSelectedFunnelStatus(newVal);
    const statusValue = newVal?.value || "";
    updateDataInSB("clients", { funnel_status: statusValue }, clientId);
    setClientCardData({
      ...clientCardData,
      funnel_status: statusValue,
    });
  };

  const handleChangeClientType = (newVal: OptionItem | null) => {
    setSelectedClientType(newVal);
    const typeValue = newVal?.value || "";
    updateDataInSB("clients", { type: typeValue }, clientId);
    setClientCardData({
      ...clientCardData,
      type: typeValue,
    });
  };

  const handleAddNewLabel = (labelName: string) => {
    sendDataInSB("labels", "label_name", labelName);

    setLabelsOptions((prev) => [
      ...prev,
      { value: labelName, label: labelName },
    ]);
  };

  const handleAddNewFunnelStatus = (statusName: string) => {
    sendDataInSB("funnel_statuses", "status_name", statusName);
    setFunnelStatusOptions((prev) => [
      ...prev,
      { value: statusName, label: statusName },
    ]);
  };

  const handleAddNewClientType = (typeName: string) => {
    sendDataInSB("client_types", "type_name", typeName);
    setClientTypeOptions((prev) => [
      ...prev,
      { value: typeName, label: typeName },
    ]);
  };

  // Edit
  const handleEditLabel = async (oldValue: string, newValue: string) => {
    try {
      await handleUniversalEdit(
        "labels",
        oldValue,
        newValue,
        selectedLabel,
        setSelectedLabel,
        setLabelsOptions
      );

      toast.success("Label updated successfully");
    } catch (error) {
      console.error("Failed to edit label:", error);
      toast.error("Failed to update label");
    }
  };

  const handleEditFunnelStatus = async (oldValue: string, newValue: string) => {
    try {
      await handleUniversalEdit(
        "funnel_statuses",
        oldValue,
        newValue,
        selectedFunnelStatus,
        setSelectedFunnelStatus,
        setFunnelStatusOptions
      );

      toast.success("Label updated successfully");
    } catch (error) {
      console.error("Failed to edit funnel statuses:", error);
      toast.error("Failed to update funnel statuses");
    }
  };

  const handleEditClientType = async (oldValue: string, newValue: string) => {
    try {
      await handleUniversalEdit(
        "client_types",
        oldValue,
        newValue,
        selectedClientType,
        setSelectedClientType,
        setClientTypeOptions
      );

      toast.success("Label updated successfully");
    } catch (error) {
      console.error("Failed to edit client types:", error);
      toast.error("Failed to update client types");
    }
  };

  const handleDeleteLabel = async (value: string, createdAt: string) => {
    try {
      await handleUniversalDelete(
        "labels",
        value,
        createdAt,
        selectedLabel,
        setSelectedLabel,
        setLabelsOptions
      );
    } catch (err) {
      console.error("Failed to delete label:", err);
    }
  };

  const handleDeleteFunnelStatus = async (value: string, createdAt: string) => {
    try {
      await handleUniversalDelete(
        "funnel_statuses",
        value,
        createdAt,
        selectedFunnelStatus,
        setSelectedFunnelStatus,
        setFunnelStatusOptions
      );
    } catch (err) {
      console.error("Failed to delete funnel status:", err);
    }
  };

  const handleDeleteClientType = async (value: string, createdAt: string) => {
    try {
      await handleUniversalDelete(
        "client_types",
        value,
        createdAt,
        selectedClientType,
        setSelectedClientType,
        setClientTypeOptions
      );
    } catch (err) {
      console.error("Failed to delete client type:", err);
    }
  };

  return (
    <div className={styles.container}>
      <Accordion title="Labels" icon={<FaRegBookmark size={16} />}>
        {/* MANAGERS */}
        <div
          className={styles.filterItemWrapper}
          onClick={() => toggleAccordion("managers")}
        >
          <label htmlFor="manager" className={styles.title}>
            Assigned Manager
          </label>
          <div className={classNames(styles.filterItem, styles.managerItem)}>
            <FaRegUser size={16} />
            <Select<OptionItem, false, GroupBase<OptionItem>>
              placeholder="Choose manager"
              options={managersOptions}
              value={selectedManagers}
              isSearchable={false}
              components={{
                ValueContainer: CustomValueContainer,
                DropdownIndicator: CustomDropdownIndicator,
                MenuList: (props) => (
                  <CustomFilterMenuList
                    {...props}
                    onApply={handleApplyManagers}
                    onCleanAll={handleCleanManagers}
                    onCloseMenu={() => setIsManagerMenuOpen(false)}
                  />
                ),
                Option: CustomOption,
                MultiValue: SelectedValueWithRemoveCross,
              }}
              styles={customSelectStyles}
              onChange={handleManagersChange}
              menuIsOpen={isManagerMenuOpen}
              onMenuOpen={() => setIsManagerMenuOpen(true)}
              onMenuClose={() => setIsManagerMenuOpen(false)}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isClearable={false}
            />
            {isAccordionOpen.managers ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>

        {/* LABELS */}
        <div
          className={styles.filterItemWrapper}
          onClick={() => toggleAccordion("labels")}
        >
          <label htmlFor="labels" className={styles.title}>
            Labels
          </label>
          <div className={classNames(styles.filterItem)}>
            <FaRegBookmark size={15} />
            <CustomDropdown
              label="Labels"
              placeholder="Choose label"
              initialOptions={labelsOptions}
              value={selectedLabel}
              onItemAdd={handleAddNewLabel}
              onItemEdit={handleEditLabel}
              onItemDelete={handleDeleteLabel}
              onChange={handleChangeLabel}
            />
            {isAccordionOpen.labels ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>

        {/* FUNNEL STATUS */}
        <div
          className={styles.filterItemWrapper}
          onClick={() => toggleAccordion("funnelStatus")}
        >
          <label className={styles.title} htmlFor="funnel">
            Funnel status
          </label>
          <div className={classNames(styles.filterItem)}>
            <PiFunnelBold size={20} />
            <CustomDropdown
              label="Funnel status"
              placeholder="Choose funnel status"
              value={selectedFunnelStatus}
              initialOptions={funnelStatusOptions}
              onItemAdd={handleAddNewFunnelStatus}
              onItemEdit={handleEditFunnelStatus}
              onItemDelete={handleDeleteFunnelStatus}
              onChange={handleChangeFunnelStatus}
            />
            {isAccordionOpen.funnelStatus ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>

        {/* CLIENT TYPE */}
        <div
          className={styles.filterItemWrapper}
          onClick={() => toggleAccordion("clientType")}
        >
          <label htmlFor="clientType" className={styles.title}>
            Client type
          </label>
          <div className={classNames(styles.filterItem)}>
            <LiaUserTagSolid size={24} strokeWidth={1} />
            <CustomDropdown
              label="Client type"
              placeholder="Choose client type"
              value={selectedClientType}
              initialOptions={clientTypeOptions}
              onItemAdd={handleAddNewClientType}
              onItemEdit={handleEditClientType}
              onItemDelete={handleDeleteClientType}
              onChange={handleChangeClientType}
            />
            {isAccordionOpen.clientType ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </Accordion>
    </div>
  );
}
