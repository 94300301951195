import React, { useEffect, useMemo } from "react";
import { memo, useState } from "react";

import { IconCalendar } from "../../../assets";
import {
  Checkbox,
  Title,
  Dropdown,
  RoundedBox,
  Skeleton,
  SomethingWentWrong,
} from "../../../components";

import {
  Instagram,
  Messenger,
  Telegram,
  Viber,
  WhatsApp,
} from "../../../../assets/icons";
import { handleChangeCheckboxHelper } from "../../../../utils";
import { useAppDispatch, useReduxStore } from "../../../hooks";
import { fetchOrdersByChannel } from "../../../../store/slices/dashboard/thunks";
import { SocialHorizontalBar } from "./SocialHorizontalBar";

const iconsMap = {
  viber: <Viber />,
  instagram: <Instagram />,
  whatsapp: <WhatsApp />,
  telegram: <Telegram />,
  messenger: <Messenger />,
};

const formatHorizontalBarData = (channels: DashboardOrdersByChannel[]) =>
  channels.map((channel) => ({
    id: channel.channel.toLowerCase(),
    icon: iconsMap[channel.channel.toLowerCase()],
    name: channel.channel,
    value: channel.change,
    total: channel.orders,
  }));

export const OrdersByChannel = memo(() => {
  const { dashboard } = useReduxStore();
  const dispatch = useAppDispatch();

  const [dates, setDates] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchOrdersByChannel());
  }, [dispatch]);

  const data = useMemo(
    () => formatHorizontalBarData(dashboard.ordersByChannel || []),
    [dashboard.ordersByChannel]
  );

  return (
    <RoundedBox className="column">
      <div className="flex items-center justify-between mb-6">
        <Title text="Orders by Channel" component="h2" />
        <Dropdown
          icon={<IconCalendar />}
          placeholder="Date"
          body={
            <div className="column gap-4">
              <Checkbox
                label="3 days"
                value="orders-by-channel-3days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("orders-by-channel-3days")}
              />
              <Checkbox
                label="7 days"
                value="orders-by-channel-7days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("orders-by-channel-7days")}
              />
              <Checkbox
                label="30 days"
                value="orders-by-channel-30days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("orders-by-channel-30days")}
              />
              <Checkbox
                label="All time"
                value="orders-by-channel-all-time"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("orders-by-channel-all-time")}
              />
              <Checkbox
                label="Custom Range"
                value="orders-by-channel-custom-range"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("orders-by-channel-custom-range")}
                disabled
              />
            </div>
          }
          classNameMenu="min-w-[15rem] left-auto right-0 md:left-0 md:right-auto"
        />
      </div>

      {/* Loading view */}
      {dashboard.fetchingOrdersByChannel && (
        <Skeleton
          length={1}
          classNameWrapper="h-full w-full"
          classNameItem="!basis-full"
        />
      )}

      {/* Success data view */}
      {!dashboard.fetchingOrdersByChannel &&
        !dashboard.ordersByChannelError &&
        dashboard.ordersByChannel &&
        dashboard.ordersByChannel.length > 0 && (
          <div className="mt-auto">
            <SocialHorizontalBar data={data} showValue />
          </div>
        )}

      {/* No data view */}
      {!dashboard.fetchingOrdersByChannel &&
        !dashboard.ordersByChannelError &&
        dashboard.ordersByChannel &&
        dashboard.ordersByChannel.length === 0 && (
          <div className="w-full h-full center">
            <p>No Data</p>
          </div>
        )}

      {/* Error view */}
      {!dashboard.fetchingOrdersByChannel && dashboard.ordersByChannelError && (
        <SomethingWentWrong callback={() => dispatch(fetchOrdersByChannel())} />
      )}
    </RoundedBox>
  );
});
