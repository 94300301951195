import { HiArrowRight } from "react-icons/hi";
import {
  Instagram,
  Messenger,
  Telegram,
  Viber,
  WhatsApp,
} from "../../../../../assets/icons";
import { Button } from "../../../../components";
import { useReduxStore } from "../../../../hooks";

const iconsMap = {
  viber: <Viber />,
  instagram: <Instagram />,
  whatsapp: <WhatsApp />,
  telegram: <Telegram />,
  messenger: <Messenger />,
};

export const ChannelsList = () => {
  const { dashboard } = useReduxStore();

  if (!dashboard.revenueByChannel) return <></>;

  return (
    <>
      <div className="column justify-between gap-5 md:flex-row md:items-center">
        <div className="column gap-1">
          <p className="text-purple font-medium text-[2.4rem] leading-[140%]">
            {dashboard.revenueByChannel?.revenue_change}%
          </p>
          <span className="text-light-black2 font-normal text-[1.1rem] leading-[140%] w-[10rem]">
            from previous month
          </span>
        </div>
        <ul className="flex flex-nowrap items-center justify-start gap-[0.9rem] max-w-full md:basis-full overflow-y-auto md:overflow-hidden pb-3 md:pb-0">
          {dashboard.revenueByChannel?.revenue_by_channel.map((item) => (
            <li
              className="before:hidden m-0 column gap-[1.15rem] items-center rounded-[0.6rem] border border-gray-300 p-[0.9rem] basis-full min-w-[8rem] md:min-w-[4rem]"
              key={item.channel}
            >
              <div className="center w-[3.1rem] h-[3.1rem] rounded-[0.6rem] bg-gray [&>svg]:w-[1.9rem] [&>svg]:h-[1.9rem]">
                {iconsMap[item.channel.toLowerCase()]}
              </div>
              <p className="text-gray-500 font-normal text-[1.1rem] leading-[140%]">
                {item.channel}
              </p>
              <p className="text-purple font-semibold text-[1.5rem] leading-[120%]">
                ${Math.round(item.revenue)}
              </p>
            </li>
          ))}
        </ul>
        <Button
          variant="rounded"
          className="w-[3rem] h-[3rem] p-2 rounded-xl center md:min-w-[3rem]"
        >
          <HiArrowRight className="w-[1.5rem] h-[1.5rem]" />
        </Button>
      </div>
    </>
  );
};
