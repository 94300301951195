import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { AccountState } from "../../../redesign/types";
import { fetchAccountById } from "./thunks";

const initialState: AccountState = {
  fetching: false,
  data: null,
  error: null,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountById.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchAccountById.fulfilled, (state, action) => {
      state.error = null;
      state.data = action.payload;
    });
    builder
      .addCase(fetchAccountById.rejected, (state, action) => {
        state.error = action.error;
      })
      .addMatcher(
        isAnyOf(fetchAccountById.fulfilled, fetchAccountById.rejected),
        (state) => {
          state.fetching = false;
        }
      );
  },
});

export const accountReducer = accountSlice.reducer;
