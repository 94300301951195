import { useCallback, useState } from "react";
import supabase from "../../supabase";
import { toast } from "react-toastify";

const chatsPerPage = 50;

export const useChatLoader = ({
  user,
  appliedFunnelStatuses,
  appliedSearchValue,
  appliedAssistants,
  appliedChannels,
  appliedTimeRange,
  appliedManagers,
  activeTab,
  setHasMore,
  setUnreadCount,
  setTotalChatCount,
  setChats,
  clientFunnelStatusMap,
  shouldNavigateToFirstChat,
  setShouldNavigateToFirstChat,
  setIsShowClientCard,
  navigate,
}) => {
  const [isNoMoreChatsToastShown, setIsNoMoreChatsToastShown] = useState(false);

  const fetchChatsPage = useCallback(
    async (pageNumber) => {
      try {
        let matchingClientIds = null;

        // If there is filtering by funnelStatuses
        if (appliedFunnelStatuses && appliedFunnelStatuses.length > 0) {
          const { data: clientsData, error: clientsError } = await supabase
            .from("clients")
            .select("client_id")
            .eq("account_id", user.account_id)
            .in(
              "funnel_status",
              appliedFunnelStatuses.map((status) => status.value)
            )
            // we only take the first 500 customers to avoid generating too long IN(...)
            .range(0, 499);

          if (clientsError) throw clientsError;

          matchingClientIds = clientsData.map((client) => client.client_id);

          // If there are no customers at all, then return an empty list
          if (matchingClientIds.length === 0) {
            return { data: [], count: 0 };
          }
        }

        // Forming a request to "chats"
        let query = supabase
          .from("chats")
          .select("*", { count: "exact" })
          .eq("account_id", user.account_id);

        if (activeTab === 1) {
          query = query.eq("needs_operator", true);
        }

        // Search
        if (appliedSearchValue && appliedSearchValue.trim() !== "") {
          query = query.or(
            `client_name.ilike.*${appliedSearchValue}*,client_username.ilike.*${appliedSearchValue}*,page_id.ilike.*${appliedSearchValue}*`
          );
        }

        // Assistants
        if (appliedAssistants && appliedAssistants.length > 0) {
          query = query.in(
            "assistant_id",
            appliedAssistants.map((a) => a.value)
          );
        }

        // Channels
        if (appliedChannels && appliedChannels.length > 0) {
          query = query.in(
            "communication_channel",
            appliedChannels.map((c) => c.value)
          );
        }

        // Managers
        if (appliedManagers && appliedManagers.length > 0) {
          const { data: managerClientData, error: managerClientError } =
            await supabase
              .from("clients")
              .select("client_id")
              .eq("account_id", user.account_id)
              .in(
                "manager_id",
                appliedManagers.map((m) => m.value)
              );

          if (managerClientError) throw managerClientError;

          const managerClientIds = managerClientData.map(
            (record) => record.client_id
          );
          if (managerClientIds.length === 0) {
            // There are no customers with this manager_id — we return an empty result
            return { data: [], count: 0 };
          }
          // If another filter by client_id already exists (for example, by funnel_status), you can merge the lists:
          if (matchingClientIds) {
            matchingClientIds = matchingClientIds.filter((id) =>
              managerClientIds.includes(id)
            );
          } else {
            matchingClientIds = managerClientIds;
          }
        }

        // funnel_status --> if we get matchingClientIds, we do .in(...)
        if (matchingClientIds) {
          query = query.in("client_id", matchingClientIds);
        }

        // Time range
        if (appliedTimeRange?.value && appliedTimeRange.value !== "custom") {
          const days = parseInt(appliedTimeRange.value, 10);
          if (!isNaN(days)) {
            const cutoffDate = new Date();
            cutoffDate.setDate(cutoffDate.getDate() - days);
            const isoCutoffDate = cutoffDate.toISOString();

            if (appliedTimeRange.type === "inactive") {
              query = query.lte("last_active", isoCutoffDate);
            } else {
              query = query.gte("last_active", isoCutoffDate);
            }
          }
        }

        // Pagination
        query = query
          .order("last_active", { ascending: false })
          .range(
            (pageNumber - 1) * chatsPerPage,
            pageNumber * chatsPerPage - 1
          );

        const { data: chatsData, error: chatsError, count } = await query;

        if (chatsError) {
          if (chatsError.code === "PGRST103") {
            if (pageNumber > 1) {
              setHasMore(false);
              if (!isNoMoreChatsToastShown) {
                setIsNoMoreChatsToastShown(true);
                toast.info("No more chats");

                // isNoMoreChatsToastShown reset after 5 seconds
                setTimeout(() => {
                  setIsNoMoreChatsToastShown(false);
                }, 7000);
              }
            }
            return { data: [], count: 0 };
          }
          if (chatsError.message.includes("FB Error 10: Type OAuthException")) {
            // Implement retry mechanism or notification
            toast.warning(
              "Facebook Error #10. Apps can only send a message to a customer within 24 hours of receiving the customer's message!"
            );
          }
          throw chatsError;
        }

        if (!chatsData || chatsData.length === 0) {
          return { data: [], count: 0 };
        }

        // If there are chats, pull up their funnel_status from clients
        const uniqueClientIds = [...new Set(chatsData.map((c) => c.client_id))];
        const { data: cData, error: cError } = await supabase
          .from("clients")
          .select("client_id, funnel_status")
          .eq("account_id", user.account_id)
          .in("client_id", uniqueClientIds);

        if (cError) throw cError;

        const funnelStatusMap = cData.reduce((acc, client) => {
          acc[client.client_id] = client.funnel_status;
          return acc;
        }, {});

        const enrichedChats = chatsData.map((chat) => ({
          ...chat,
          funnel_status: funnelStatusMap[chat.client_id] || null,
        }));

        setHasMore(enrichedChats.length === chatsPerPage);
        return { data: enrichedChats, count };
      } catch (error) {
        toast.error("Error fetching chats: " + error.message);
        return { data: [], count: 0 };
      }
    },
    [
      user.account_id,
      activeTab,
      appliedSearchValue,
      appliedAssistants,
      appliedChannels,
      appliedManagers,
      appliedFunnelStatuses,
      appliedTimeRange,
      chatsPerPage,
      isNoMoreChatsToastShown,
    ]
  );

  const loadChats = useCallback(async () => {
    const {
      data: fetchedChats,
      count: totalCount,
      hasMore,
    } = await fetchChatsPage(1);

    if (!fetchedChats) {
      return;
    }

    if (fetchedChats.length > 0) {
      setChats(fetchedChats);

      setHasMore(hasMore);
      setUnreadCount(
        fetchedChats.reduce((acc, { unread_count }) => acc + unread_count, 0)
      );
      setTotalChatCount(totalCount);
    }

    if (shouldNavigateToFirstChat && fetchedChats.length > 0) {
      const firstChat = fetchedChats[0];

      let preservedParams = new URLSearchParams();

      // create URL for navigation
      let navigateUrl;
      if (firstChat.page_id) {
        navigateUrl = `/chats/${firstChat.client_id}/${firstChat.page_id}`;
      } else {
        navigateUrl = `/chats/${firstChat.client_id}`;
      }

      // add query params if they exist
      if (preservedParams.toString()) {
        navigateUrl += `?${preservedParams.toString()}`;
      }

      navigate(navigateUrl, { replace: true });

      setShouldNavigateToFirstChat(false);
      setIsShowClientCard(true);
    }
  }, [
    fetchChatsPage,
    clientFunnelStatusMap,
    shouldNavigateToFirstChat,
    navigate,
  ]);

  return { fetchChatsPage, loadChats };
};
