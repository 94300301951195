import React from "react";
import { RoundedBox } from "../../../../components";
import classNames from "classnames";
import {
  IconTotalScale,
  IconNumberOfSales,
  IconActiveDialogs,
  IconAverageDialog,
  IconStatisticArrowUp,
  IconStatisticArrowDown,
  IconTotalCost,
  IconArrowUpRight,
  IconDialogMessage,
} from "../../../../assets";
import { useReduxStore } from "../../../../hooks";

const iconsMap = {
  total_sale: <IconTotalScale />,
  number_of_sales: <IconNumberOfSales />,
  active_dialogs: <IconActiveDialogs />,
  average_dialog_cost: <IconAverageDialog />,
  total_cost: <IconTotalCost />,
  average_sale: <IconArrowUpRight />,
  average_dialogue_messages: <IconDialogMessage />,
  lvt: <IconArrowUpRight />,
};

function capitalizeFirstLetter(string: string) {
  return string.replace(/^./, string[0].toUpperCase());
}

const getGeneralStatistic = (
  statistic: Record<string, { value: number; change: number }>
) => {
  return Object.entries(statistic).map(([key, value]) => ({
    id: key,
    title: capitalizeFirstLetter(key.split("_").join(" ")),
    amount: value.value.toString(),
    percent: value.change,
    icon: iconsMap[key as keyof typeof iconsMap],
  }));
};

export const List = () => {
  const { dashboard } = useReduxStore();

  const list = getGeneralStatistic(dashboard.generalStatistic);

  return (
    <ul className="flex flex-wrap gap-[1.5rem]">
      {list.map((item) => {
        const isUpPercent = item.percent > 0;

        return (
          <li
            key={item.id}
            className="m-0 basis-[100%] sm:basis-[calc(50%-1.5rem/2)] md:basis-[calc((100%-1.5rem*3)/4)] min-h-full before:hidden group"
          >
            <RoundedBox className="column justify-between md:justify-start gap-6 p-[1.2rem] h-full md:h-auto rounded-[1.19rem]">
              <div className={"flex items-center gap-[0.7rem]"}>
                <div
                  className={classNames(
                    "center rounded-[0.6rem] p-[0.7rem] bg-purple [&>svg]:w-[1.6rem] [&>svg]:h-[1.6rem] "
                  )}
                >
                  {item.icon}
                </div>
                <p className="text-gray-500 font-semibold text-[1.4rem] leading-[140%]">
                  {item.title}
                </p>
              </div>
              <div className={"flex items-center justify-between"}>
                <p className="text-gray-500 font-semibold text-[2.3rem] leading-[140%]">
                  {item.amount}
                </p>
                <p className="column gap-[0.6rem] items-end md:items-start">
                  <span
                    className={classNames(
                      "px-[0.6rem] py-[0.2rem] rounded-[0.7rem] w-fit flex items-center gap-[0.4rem] relative translate-y-[100%] group-hover:translate-y-0 transition-all duration-300",
                      isUpPercent
                        ? "bg-light-green text-green"
                        : "bg-light-red text-danger"
                    )}
                  >
                    {isUpPercent ? (
                      <IconStatisticArrowUp className="w-[0.9rem] h-[0.9rem]" />
                    ) : (
                      <IconStatisticArrowDown className="w-[0.9rem] h-[0.9rem]" />
                    )}
                    <span className="font-normal text-[1.1rem] leading-[140%]">
                      {item.percent}%
                    </span>
                  </span>
                  <span className="text-gray-400 font-normal text-[1.1rem] leading-[140%] opacity-0 group-hover:opacity-100 transition-opacity">
                    from previous month
                  </span>
                </p>
              </div>
            </RoundedBox>
          </li>
        );
      })}
    </ul>
  );
};
