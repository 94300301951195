import { memo, useEffect, useState } from "react";

import {
  Checkbox,
  Dropdown,
  RoundedBox,
  Skeleton,
  SomethingWentWrong,
  Title,
} from "../../../../components";

import { IconCalendar } from "../../../../assets";
import { handleChangeCheckboxHelper } from "../../../../utils";
import { useReduxStore, useAppDispatch } from "../../../../hooks";
import { fetchMessageUsage } from "../../../../../store/slices/dashboard";

import { Chart } from "./Chart";

export const MessageUsage = memo(() => {
  const { dashboard } = useReduxStore();
  const dispatch = useAppDispatch();

  const [dates, setDates] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchMessageUsage());
  }, [dispatch]);

  return (
    <RoundedBox className="column">
      <div className="flex items-center justify-between mb-6">
        <Title text="Message Usage" component="h2" />
        <Dropdown
          icon={<IconCalendar />}
          placeholder="Date"
          body={
            <div className="column gap-4">
              <Checkbox
                label="3 days"
                value="message-usage-3days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("message-usage-3days")}
              />
              <Checkbox
                label="7 days"
                value="message-usage-7days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("message-usage-7days")}
              />
              <Checkbox
                label="30 days"
                value="message-usage-30days"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("message-usage-30days")}
              />
              <Checkbox
                label="All time"
                value="message-usage-all-times"
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("message-usage-all-times")}
              />
              <Checkbox
                label="Custom Range"
                value="message-usage-custom-ranges"
                disabled
                onChange={handleChangeCheckboxHelper(setDates)}
                checked={dates.includes("message-usage-custom-ranges")}
              />
            </div>
          }
          classNameMenu="min-w-[15.5rem] left-auto right-0 md:left-0 md:right-auto"
        />
      </div>

      {/* Loading view */}
      {dashboard.fetchingMessageUsage && (
        <Skeleton
          length={1}
          classNameWrapper="w-full h-full"
          classNameItem="!basis-full"
        />
      )}

      {/* Success data view */}
      {!dashboard.fetchingMessageUsage &&
        !dashboard.messageUsageError &&
        dashboard.messageUsage &&
        dashboard.messageUsage.length > 0 && <Chart />}

      {/* No data view */}
      {!dashboard.fetchingMessageUsage &&
        !dashboard.messageUsageError &&
        dashboard.messageUsage &&
        dashboard.messageUsage.length === 0 && (
          <div className="w-full h-full center">
            <p>No Data</p>
          </div>
        )}

      {/* Error view */}
      {!dashboard.fetchingMessageUsage && dashboard.messageUsageError && (
        <SomethingWentWrong callback={() => dispatch(fetchMessageUsage())} />
      )}
    </RoundedBox>
  );
});
