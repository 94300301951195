import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { RoundedBox } from "../../components";

import { GeneralStatistic } from "./GeneralStatistic";
import { Charts } from "./Charts";
import { AdvertisingAnalysis } from "./AdvertisingAnalysis";

export const DashboardPageRedesign = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const topup = searchParams.get("topup");

    if (topup === "success") {
      toast.success("Payment successful! Thank you for your purchase.");
    }
  }, [searchParams]);

  return (
    <div className="px-3 pt-7 pb-5 md:px-6 overflow-x-hidden">
      <RoundedBox variant="default">
        <div className="mb-6">
          <GeneralStatistic />
        </div>
        <div className="mb-5">
          <Charts />
        </div>
        <div>
          <AdvertisingAnalysis />
        </div>
      </RoundedBox>
    </div>
  );
};
