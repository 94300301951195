import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconEdit: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3333 3.60282L8.25801 8.67814C8.0774 8.85876 7.95773 9.0913 7.91574 9.34325L7.50857 11.7863C7.43944 12.201 7.799 12.5606 8.21374 12.4914L10.6567 12.0843C10.9087 12.0423 11.1412 11.9226 11.3219 11.742L16.3972 6.66667M13.3333 3.60282L13.8016 3.13454C14.6477 2.28849 16.0194 2.28848 16.8655 3.13454C17.7115 3.9806 17.7115 5.35233 16.8655 6.19839L16.3972 6.66667M13.3333 3.60282L16.3972 6.66667M17.5 10.8333L17.5 15C17.5 16.3807 16.3807 17.5 15 17.5H5C3.61929 17.5 2.5 16.3807 2.5 15V5C2.5 3.61929 3.61929 2.5 5 2.5H9.16667"
        stroke="#4C5876"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
