import React, { memo } from "react";
import { Button, RoundedBox, Title } from "../../../components";
import Image1 from "../../../assets/images/AIConfiguration/instruction-1.webp";
import Image2 from "../../../assets/images/AIConfiguration/instruction-2.webp";
import classNames from "classnames";
import { LuArrowUpRight } from "react-icons/lu";

const instructions = [
  {
    id: "1",
    title: "Businesses offering products",
    description: "Product-based businesses",
    link: "",
    img: Image1,
  },
  {
    id: "2",
    title: "Businesses providing services",
    description: "Service-based businesses",
    link: "",
    img: Image2,
  },
];

const imgStyles = new Map([
  ["1", "top-[-70%] rotate-[-30deg]"],
  ["2", "top-[-60%] rotate-[80deg]"],
]);

export const AssistantInstructions = memo(() => {
  return (
    <RoundedBox>
      <Title text="Assistant instructions" className="mb-7" component="h2" />
      <p className="text-[1.2rem] text-gray-500 mb-7">
        In this section you give instructions to your assistant, so it knows the
        purpose of customer interaction and what action it is expected to do.
        Answering questions is assumed by default in all options
      </p>
      <ul className="column gap-6 md:flex-row md:flex-wrap">
        {instructions.map(({ id, title, description, img }) => (
          <li
            key={id}
            className="m-0 bg-purple basis-full md:basis-[calc(50%-1.5rem/2)] relative rounded-[1.8rem] min-h-[17rem] md:min-h-[12.5rem] overflow-hidden group"
          >
            <div className="absolute top-6 right-6 z-10">
              <Button
                variant="rounded"
                className="w-[4rem] h-[4rem] p-2 rounded-xl border-none rounded-[1.5rem] [&>span]:flex [&>span]:items-center [&>span]:justify-center [&>span]:w-full [&>span]:h-full"
              >
                <LuArrowUpRight className="w-[2rem] h-[2rem]" />
              </Button>
            </div>
            <img
              src={img}
              alt={title}
              className={classNames(
                "w-[25rem] absolute left-4",
                imgStyles.get(id)
              )}
            />
            <div className="absolute bottom-0 p-6 w-full h-1/2 rounded-[1.8rem] bg-[#777EF599] backdrop-blur-[6px] group-hover:h-full transition-all duration-300">
              <p className="body-2 text-light">{title}</p>
              <p className="body-1 text-light">{description}</p>
            </div>
          </li>
        ))}
      </ul>
    </RoundedBox>
  );
});
