import React, { useEffect, useRef, useState } from "react";
import supabase from "../../supabase";
import { useDispatch, useSelector } from "react-redux";
import { setOnboarding } from "../../store/slices/onboardingSlice";
import {
  useClearChatMutation,
  useMessagesQuery,
  useSendMessageMutation,
} from "../../store/api";
import { Logo, Send } from "../../assets/icons";
import { Modal } from "../Modal";
import styles from "./Bot.module.scss";
import close from "../../assets/img/close.png";
import { MessageRenderer } from "../MessageRenderer/MessageRenderer";

export const Bot = ({ assistantId }) => {
  const windowWidth = window.innerWidth;

  const dispatch = useDispatch();

  const step = useSelector((state) => state.onboarding.step);
  const user = useSelector((state) => state.auth.user);

  const [isTyping, setIsTyping] = useState(false);

  const [checked, setChecked] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [messages, setMessages] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [collapseStates, setCollapseStates] = useState({});

  const handleToggleCollapse = (key) => {
    setCollapseStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const textareaRef = useRef();
  const bottomListRef = useRef();
  const chatContainerRef = useRef();
  const isUserScrolling = useRef(false);

  const [clearChat] = useClearChatMutation();
  const [sendMessage] = useSendMessageMutation();

  //  Request to load chat history from the backend
  const { data, refetch } = useMessagesQuery(
    {
      id: user.account_id,
      assistantId,
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  // When is focused – if step >= 2 – we update the chat
  useEffect(() => {
    const handleFocus = () => {
      if (assistantId && step >= 2) {
        refetch();
      }
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [assistantId, step, refetch]);

  // Clear the chat
  const clearChatHandle = async () => {
    try {
      await clearChat({ id: user.account_id, assistantId });
      setMessages([]);
    } catch (error) {
      console.error(error);
    }
  };

  // Sending a message on Enter (without Shift)
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  // Basic logic for sending a message
  const handleSend = async () => {
    try {
      if (!isTyping && inputValue.trim() !== "") {
        setIsTyping(true);

        // Locally add messages from user
        const now = new Date().toISOString();
        const newMessage = {
          created_at: now,
          role: "user",
          content: inputValue.trim(),
        };
        setMessages((prev) => [...prev, newMessage]);

        const fullMessagesList = [...messages, newMessage];

        // Preparing a request to the server
        const body = {
          account_id: user.account_id,
          assistant_id: assistantId,
          messages: fullMessagesList,
        };

        setInputValue("");

        const { error: errorApi, data: responseData } = await sendMessage(body);

        if (errorApi) {
          setIsModalVisible(true);
          setErrorText(errorApi?.data || "Error sending message");
        }

        if (step === 2) {
          await supabase
            .from("onboardings")
            .update({ step: checked ? 4 : 3 })
            .match({ account_id: user.account_id });
          dispatch(setOnboarding(checked ? 4 : 3));
        }

        // If a response is received, add it to messages.
        if (responseData) {
          setMessages((prev) => [...prev, ...responseData]);
        }
      }
    } catch (error) {
      setIsModalVisible(true);
      setErrorText("Failed to send message");
    } finally {
      setIsTyping(false);
    }
  };

  // Check subscription
  const fetchData = async () => {
    const { data: subscriptionData, error } = await supabase
      .from("subscriptions")
      .select("*")
      .eq("email", user.email);

    if (error) {
      console.error("Error fetching subscriptions:", error);
      setChecked(false);
    } else {
      setChecked(subscriptionData.length > 0);
    }
  };

  useEffect(() => {
    fetchData();
  }, [assistantId]);

  useEffect(() => {
    isUserScrolling.current = false;
  }, []);

  useEffect(() => {
    if (data && data.messages && step >= 2) {
      setMessages(data.messages);

      setTimeout(() => {
        isUserScrolling.current = false;
        bottomListRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 0);
    }
  }, [data, step]);

  useEffect(() => {
    if (!isUserScrolling.current) {
      bottomListRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages, isTyping]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;

      isUserScrolling.current = scrollHeight - (scrollTop + clientHeight) > 50;
    }
  };

  // Auto-change textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `scrollHeight > 300 ? "18rem" : ${scrollHeight}px`;
    }
  }, [inputValue]);

  // When step === 1 – demo message
  useEffect(() => {
    if (step === 1) {
      setMessages([
        { content: "Hi there, how can I assist you today?", role: "user" },
        { content: "Hello!", role: "assistant" },
      ]);
    }
  }, [step]);

  return (
    <div
      className={`${styles.testBot} ${
        step < 2 && step !== 6 ? styles.blurred : ""
      }`}
      style={{
        height:
          windowWidth >= 666
            ? step === 6
              ? "auto"
              : "calc(100vh - 18rem)"
            : "",
        maxHeight:
          windowWidth >= 666 ? (step === 6 ? "" : "calc(100vh - 18rem)") : "",
      }}
    >
      {/* Header */}
      <div className={styles.title}>
        <div className={styles.titleBot}>Test your assistant</div>
        <div
          onClick={clearChatHandle}
          className={styles.imageWrapper}
          style={step < 2 ? { cursor: "default" } : { cursor: "pointer" }}
        >
          <img className={styles.closeIcon} src={close} alt="Close" />
        </div>
      </div>

      {/* Message list */}
      <div
        className={styles.chatList}
        ref={chatContainerRef}
        onScroll={handleScroll}
      >
        {messages.map((message, index) => (
          <MessageRenderer
            key={index}
            message={message}
            index={index}
            collapseStates={collapseStates}
            onToggleCollapse={handleToggleCollapse}
            bottomListRef={bottomListRef}
          />
        ))}

        {isTyping && (
          <div className={styles.botResponse}>
            <Logo />
            <p>Bot is typing...</p>
          </div>
        )}
      </div>

      {/* Input field + send button */}
      <div className={styles.wrapper}>
        <textarea
          disabled={step < 2}
          ref={textareaRef}
          className={styles.textarea}
          rows={1}
          placeholder="Type your message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
        ></textarea>
        <div
          onClick={handleSend}
          className={`${styles.icon} ${
            isTyping || inputValue.trim() === "" ? styles.active : ""
          }`}
        >
          <Send />
        </div>
      </div>
      <div ref={bottomListRef} />

      <Modal
        title={"Error"}
        description={errorText}
        setIsShowModal={setIsModalVisible}
        isShowModal={isModalVisible}
      />
    </div>
  );
};
