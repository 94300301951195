export function processMessageContent(message) {
  let content = message.content || "";
  let images = [];
  let media = [];
  let instagramStoryLink = null;
  let postContent = null;
  let fileUrl = null;

  // Combining content and text with MultiContent
  if (message.MultiContent) {
    message.MultiContent.forEach((multiContentPart) => {
      if (multiContentPart.type === "text" && multiContentPart.text) {
        content += `\n${multiContentPart.text}`;
      } else if (
        multiContentPart.type === "image_url" &&
        multiContentPart.image_url?.url
      ) {
        // Add the image if it is not already added
        if (!images.includes(multiContentPart.image_url.url)) {
          images.push(multiContentPart.image_url.url);
        }
      }
    });
  }

  const fileUrlRegex = /https?:\/\/api\.mychatbot\.app\/client_files\/[^\s]+/;
  const fileUrlMatch = content.match(fileUrlRegex);
  if (fileUrlMatch) {
    fileUrl = fileUrlMatch[0];

    content = content.replace(fileUrlRegex, "").trim();
  }

  // Find all matches of 'referring to post with a caption: <<...>>'
  const postRefRegex = /referring to post with a caption:\s*<<([\s\S]*?)>>/gi;
  const postRefMatches = [...content.matchAll(postRefRegex)];
  if (postRefMatches.length > 0) {
    const lastMatch = postRefMatches[postRefMatches.length - 1];
    postContent = lastMatch[1].trim();
    // Remove all occurrences from content
    content = content.replace(postRefRegex, "");
  }

  // Remove any 'referring to post with a caption:' without '<<>>'
  content = content.replace(/referring to post with a caption: [^\n]+/gi, "");

  // Extract 'and media: URL' patterns
  const mediaRegex = /and media:\s*(\S+)/gi;
  let mediaMatch;
  while ((mediaMatch = mediaRegex.exec(content)) !== null) {
    media.push(mediaMatch[1]);
  }
  // Remove 'and media: URL' from content
  content = content.replace(mediaRegex, "");

  // Extract the 'replied to a story' link, but keep the phrase
  const storyReplyRegex = /(replied to a story: )(\S+)/i;
  const match = content.match(storyReplyRegex);

  if (match) {
    instagramStoryLink = match[2];
    // We do not delete from content to preserve the phrase
  }

  // Function to check if a URL is an image
  const isImageUrl = (url) => {
    if (url.includes("lookaside.fbsbx.com/")) {
      return true;
    }
    return url.match(/\.(jpeg|jpg|gif|png|bmp|webp|svg|heic|heif)(\?.*)?$/i);
  };

  // Convert individual image URLs to Markdown image syntax
  const standaloneUrlRegex = /(^|\s)(https?:\/\/[^\s]+)/g;

  content = content.replace(standaloneUrlRegex, (match, space, url) => {
    // Checking if the URL is part of Markdown or HTML
    const isInMarkdownImage = new RegExp(`!\\[.*?\\]\\(${url}\\)`).test(
      content
    );
    const isInMarkdownLink = new RegExp(`\\[.*?\\]\\(${url}\\)`).test(content);
    const isInHtmlTag = new RegExp(`src=["']${url}["']`).test(content);

    if (isInMarkdownImage || isInMarkdownLink || isInHtmlTag) {
      return match;
    }

    // If it is an image, replace with Markdown syntax for the image
    if (isImageUrl(url)) {
      return `${space}![](${url})`;
    } else {
      // Leave the URL unchanged.
      return match;
    }
  });

  // Remove duplicate images between content and images
  // Find all images in content
  const contentImageUrls = [];
  const markdownImageRegex = /!\[.*?\]\((https?:\/\/[^\s)]+)\)/g;
  let markdownImageMatch;
  while ((markdownImageMatch = markdownImageRegex.exec(content)) !== null) {
    contentImageUrls.push(markdownImageMatch[1]);
  }

  // Remove from images those URLs that are already in content
  images = images.filter((imgUrl) => !contentImageUrls.includes(imgUrl));

  content = content.trim();

  return { content, images, instagramStoryLink, media, postContent, fileUrl };
}

export function getGMTOffset(dateString) {
  const date = new Date(dateString);
  const timezoneOffset = date.getTimezoneOffset();
  const hours = Math.floor(Math.abs(timezoneOffset) / 60);
  const sign = timezoneOffset <= 0 ? "+" : "-";

  return `GMT ${sign}${hours}`;
}
