import React, { FC, PropsWithChildren, ButtonHTMLAttributes } from "react";
import { Button as MTButton } from "@material-tailwind/react";
import classNames from "classnames";

const styles = {
  primary: "bg-primary-gradient text-white",
  secondary:
    "border border-purple text-purple bg-white hover:border-purple-hover disabled:text-white disabled:border-disabled",
  rounded: "bg-white border border-gray-300 text-black rounded-md",
};

type Variant = "primary" | "secondary" | "rounded";

type Props = {
  variant?: Variant;
} & Required<PropsWithChildren> &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color">;

export const Button: FC<Props> = ({
  variant = "primary",
  type = "button",
  className = "",
  children,
  ...props
}) => {
  return (
    <MTButton
      type={type}
      className={classNames(
        "w-full py-3 text-base leading-[110%] font-medium rounded-[1.2rem] normal-case",
        styles[variant],
        className
      )}
      {...props}
    >
      <span>{children}</span>
    </MTButton>
  );
};
