import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import supabase from "../../supabase";
import { User } from "../../types/user";

interface Option {
  value: string;
  label: string;
}

export const fetchIntegrationData = async (id: number) => {
  try {
    const { data, error } = await supabase
      .from("integrations")
      .select()
      .eq("id", id);

    if (error) throw new Error(error.message);
    return data[0];
  } catch (error) {
    console.error("Error fetching integration data:", error);
    return null;
  }
};

/**
 * This hook can be used for a specific integration (e.g., Site link).
 * It handles connect/revoke logic.
 */
export const useIntegration = (
  id: number,
  title: string,
  storedAccessToken: string,
  storedRefreshToken: string,
  user: User,
  fetchIntegrations: () => void // function to refresh the integrations list
) => {
  const [isLoading, setIsLoading] = useState(false);

  // Example states for controlling domain, plugin name, siteLinks, etc.
  const [domain, setDomain] = useState("");
  const [pluginName, setPluginName] = useState("feedv1");
  const [accessToken, setAccessToken] = useState(storedAccessToken);
  const [refreshToken, setRefreshToken] = useState(storedRefreshToken);
  const [siteLinks, setSiteLinks] = useState<string[]>([""]);

  const [selectedLanguage, setSelectedLanguage] = useState<Option | null>(null);
  const [languagesOptions, setLanguagesOptions] = useState<Option[]>([]);

  // When mount or when storedAccessToken changes, sync local states
  useEffect(() => {
    if (storedAccessToken) setAccessToken(storedAccessToken);
    if (storedRefreshToken) setRefreshToken(storedRefreshToken);
  }, [storedAccessToken, storedRefreshToken]);

  const handleOnConnect = useCallback(async () => {
    setIsLoading(true);
    try {
      if (title === "Site link") {
        // Validate site links
        if (!siteLinks.length || siteLinks.length > 5) {
          toast.error("Please enter 1 to 5 valid URLs");
          return;
        }
        // Prepare the data to insert
        const joinedLinks = siteLinks.join("|");
        const { error } = await supabase.from("integrations").insert({
          account_id: user.account_id,
          integration: "Site link",
          access_token: joinedLinks,
          type: "cms",
          is_knowledge_base: true,
        });
        if (error) throw new Error(error.message);

        toast.success("Site link integration is enabled!");
      }
    } catch (error: any) {
      toast.error("Error in updateIntegrationAssets: " + error.message);
    } finally {
      setIsLoading(false);
      fetchIntegrations();
    }
  }, [siteLinks, title, user.account_id, fetchIntegrations]);

  const handleOnRevoke = useCallback(async () => {
    setIsLoading(true);
    try {
      await supabase.from("integrations").delete().eq("id", id);
      setAccessToken("");
      setRefreshToken("");
      toast.success("Integration revoked successfully");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      fetchIntegrations();
    }
  }, [id, fetchIntegrations]);

  return {
    isLoading,
    domain,
    pluginName,
    accessToken,
    refreshToken,
    siteLinks,
    selectedLanguage,
    languagesOptions,
    setDomain,
    setPluginName,
    setSiteLinks,
    setSelectedLanguage,
    handleOnConnect,
    handleOnRevoke,
  };
};
