import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  DoughnutControllerChartOptions,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  Plugin,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { useWindowSize } from "react-use";
import { useReduxStore } from "../../../../hooks";
import { screenSizes } from "../../../../constants";
import { AnyObject } from "chart.js/dist/types/basic";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { useMemo } from "react";

ChartJS.register(ArcElement, Legend, ChartDataLabels);
ChartJS.defaults.devicePixelRatio = 2;

const maxValueStyles = {
  color: "#384157",
  font: "700 40px Arial",
  lineHeight: 1.4,
};

const labelStyles = {
  color: "#384157",
  font: "400 16px Arial",
  lineHeight: 1.4,
};

const centerTextPlugin: Plugin<"doughnut", AnyObject> = {
  id: "centerText",
  beforeDraw: (chart) => {
    const { ctx, chartArea, data } = chart;

    const dataset = data.datasets[0];
    const maxValueIndex = dataset.data.indexOf(Math.max(...dataset.data));
    const maxValueLabel = data.labels ? data.labels[maxValueIndex] : "";
    const maxValue = dataset.data[maxValueIndex];

    ctx.save();

    const positionX = chartArea.width / 2;
    const positionYLabel = chartArea.height;

    ctx.font = maxValueStyles.font;
    ctx.fillStyle = maxValueStyles.color;
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";
    ctx.fillText(`${maxValue}%`, positionX, positionYLabel - 40);

    ctx.font = labelStyles.font;
    ctx.fillStyle = labelStyles.color;
    ctx.textBaseline = "top";
    ctx.fillText(maxValueLabel as string, positionX, positionYLabel - 30);

    ctx.restore();
  },
};

const data = {
  datasets: [
    {
      backgroundColor: ["#777EF5", "#A7ABF9", "#BCC0FF", "#E2DAFF", "#F0F2F4"],
      hoverBorderWidth: 0,
      borderRadius: 8,
      spacing: 6,
    },
  ],
};

const options: _DeepPartialObject<
  CoreChartOptions<"doughnut"> &
    ElementChartOptions<"doughnut"> &
    PluginChartOptions<"doughnut"> &
    DatasetChartOptions<"doughnut"> &
    ScaleChartOptions<"doughnut"> &
    DoughnutControllerChartOptions
> = {
  responsive: true,
  maintainAspectRatio: false,
  rotation: -90,
  circumference: 180,
  cutout: "70%",
  plugins: {
    legend: {
      position: "right",
      labels: {
        usePointStyle: true,
        pointStyleWidth: 17,
        generateLabels: (chart) => {
          const { data } = chart;
          return (data.labels || []).map((label, index) => ({
            text: label as string,
            fillStyle: Array.isArray(data.datasets[0].backgroundColor)
              ? data.datasets[0].backgroundColor[index]
              : undefined,
            borderRadius: 6,
            pointStyle: "rectRounded",
            strokeStyle: "transparent",
            lineWidth: 0,
          }));
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: "#384157",
      backgroundColor: "white",
      borderRadius: 8,
      font: {
        size: 14,
        weight: "normal",
        lineHeight: "140%",
      },
      formatter: (value) => `${value}%`,
      anchor: "end",
      align: "end",
      offset: -15,
    },
  },
};

const getChartLabels = (data: DashboardClientStatusInCrm[]) =>
  data.map(({ status }) => status);

const getChartDatasetsData = (data: DashboardClientStatusInCrm[]) =>
  data.map(({ percentage }) => percentage);

export const Chart = () => {
  const { dashboard } = useReduxStore();
  const { width: windowWidth } = useWindowSize();

  const chartLabels = useMemo(
    () => getChartLabels(dashboard.clientStatusInCrm || []),
    [dashboard.clientStatusInCrm]
  );

  const chartDatasetsData = useMemo(
    () => getChartDatasetsData(dashboard.clientStatusInCrm || []),
    [dashboard.clientStatusInCrm]
  );

  return (
    <div className="w-full h-[25rem] sm:h-[30rem] lg:w-[60rem] lg:h-[20rem] xl:w-[47rem] xl:h-[15.5rem]">
      <Doughnut
        data={{
          ...data,
          labels: chartLabels,
          datasets: [
            {
              ...data.datasets[0],
              data: chartDatasetsData,
            },
          ],
        }}
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            legend: {
              ...options.plugins?.legend,
              position: windowWidth > screenSizes.md ? "right" : "bottom",
            },
          },
        }}
        plugins={[centerTextPlugin]}
      />
    </div>
  );
};
