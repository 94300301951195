import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Logo } from "../../../../../assets/icons";
import { useReduxStore, useScrollDown } from "../../../../hooks";
import { useAIConfigurationState } from "../../context";
import { Message } from "./Message";
import { format } from "date-fns";
import classNames from "classnames";

const styles = {
  user: "self-end max-w-[80%]",
  assistant: "self-start max-w-[80%]",
  function: "w-full",
};

type Props = {
  refetch: () => void;
};

export const Chat: FC<Props> = memo(({ refetch }) => {
  const { onboarding, aiConfiguration } = useReduxStore();
  const { assistant, isTyping } = useAIConfigurationState();

  const [collapseStates, setCollapseStates] = useState({});

  const isUserScrolling = useRef(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    isUserScrolling.current = false;
  }, []);

  const memoTrigger = useMemo(
    () => aiConfiguration?.data?.messages?.length,
    [aiConfiguration?.data?.messages?.length]
  );

  const bottomListRef = useScrollDown(memoTrigger, {
    shouldScroll: !isUserScrolling.current,
  });

  useEffect(() => {
    const handleFocus = () => {
      if (assistant?.id && onboarding.step >= 2) {
        refetch();
      }
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [assistant?.id, onboarding.step, refetch]);

  const handleToggleCollapse = useCallback((key: string) => {
    setCollapseStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;

      isUserScrolling.current = scrollHeight - (scrollTop + clientHeight) > 50;
    }
  };

  return (
    <div
      className="p-6 overflow-y-auto overflow-x-hidden h-full"
      ref={chatContainerRef}
      onScroll={handleScroll}
    >
      <div className="column">
        {aiConfiguration?.data?.messages?.length > 0 &&
          aiConfiguration?.data?.messages?.map((message, index) => (
            <div
              key={message?.created_at || index}
              className={classNames(
                "column my-3 gap-2 w-fit",
                styles[message.role]
              )}
            >
              <Message
                message={message}
                index={index}
                collapseStates={collapseStates}
                onToggleCollapse={handleToggleCollapse}
              />
              <p className="text-light-black3 text-[1.1rem] leading-[120%] ml-4">
                {format(message.created_at, "hh:mm")}
              </p>
            </div>
          ))}
        {isTyping && (
          <div className="w-full flex gap-2 items-center sticky bottom-0 left-2">
            <Logo width={"2rem"} height={"2rem"} />
            <p className="body-1 h-full center">
              <span>Bot is typing...</span>
            </p>
          </div>
        )}
        <div ref={bottomListRef} className="w-0 h-0 m-0" />
      </div>
    </div>
  );
});
