import { useRef, useState, useEffect, FC } from "react";
import { TbFunction } from "react-icons/tb";
import classNames from "classnames";

import { MessageType } from "./types";

type Props = {
  message: MessageType;
  index: number;
  collapseStates: Record<string, any>;
  onToggleCollapse: (callCollapseKey: string) => void;
};

export const AssistantFunctionCallMessage: FC<Props> = ({
  message,
  index,
  collapseStates,
  onToggleCollapse,
}) => {
  const callCollapseKey = `msgIndex_${index}_function_call`;
  const isExpanded = Boolean(collapseStates[callCollapseKey]);
  const { name, arguments: args } = message.function_call || {};
  const bodyRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    if (isExpanded) {
      requestAnimationFrame(() => {
        if (bodyRef.current) {
          setMaxHeight(`${bodyRef.current.scrollHeight}px`);
        }
      });
    } else {
      setMaxHeight("0px");
    }
  }, [isExpanded]);

  if (!message.function_call) return null;

  return (
    <div className="mx-auto p-2 border-l-4 border-purple-500 bg-purple-100 rounded-md transition-colors hover:bg-purple-200 w-full">
      <div
        className="flex items-center gap-3 cursor-pointer"
        onClick={() => onToggleCollapse(callCollapseKey)}
      >
        <span className="flex items-center text-purple-600 font-semibold text-lg">
          <TbFunction size={18} className="mr-2" /> Call
        </span>
        <span className="italic text-lg">{name}</span>
        <button
          className={classNames(
            "text-lg transition-transform duration-500 ml-auto",
            { "rotate-180": isExpanded }
          )}
        >
          🔼
        </button>
      </div>
      <div
        ref={bodyRef}
        className="overflow-hidden transition-[height] duration-500 ease-in-out"
        style={{ height: maxHeight }}
      >
        <pre className="overflow-x-auto p-2 rounded-md bg-gray-200 text-sm">
          {JSON.stringify(JSON.parse(args), null, 2)}
        </pre>
      </div>
    </div>
  );
};
