import React from "react";

export const IconStatisticArrowUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.70711 4.79289L4.29289 2.20711C4.68342 1.81658 5.31658 1.81658 5.70711 2.20711L8.29289 4.79289C8.92286 5.42286 8.47669 6.5 7.58579 6.5H2.41421C1.52331 6.5 1.07714 5.42286 1.70711 4.79289Z"
        fill="#7AE575"
        stroke="#7AE575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
