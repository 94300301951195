import React, { FC, ReactNode } from "react";
import { HorizontalBar } from "../../../components";

type SocialItem = {
  id: string;
  value?: number;
  total?: number;
  icon: ReactNode;
  name: string;
  percent?: number;
};

type Props = {
  showValue?: boolean;
  data: SocialItem[];
  classNameList?: string;
};

export const SocialHorizontalBar: FC<Props> = ({
  showValue = false,
  data,
  classNameList = "",
}) => {
  return (
    <HorizontalBar data={data} classNameList={classNameList}>
      {(element) => {
        const percent =
          element.hasOwnProperty("percent") && element?.percent
            ? element.percent
            : element.hasOwnProperty("total") &&
              element.hasOwnProperty("value") &&
              Math.round((element?.value * 100) / element?.total);

        return (
          <div className="column gap-[0.6rem]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[0.6rem] [&>svg]:w-[1.5rem] [&>svg]:h-[1.5rem]">
                {element.icon}
                <span className="text-gray-500 text-[1.1rem] font-semibold leading-[140%]">
                  {element.name}
                </span>
              </div>
              <div className="flex items-center gap-[0.3rem] text-gray-500 font-normal text-[1.1rem] leading-[140%]">
                {showValue && (
                  <>
                    <span>{Math.round(element.value)}</span>
                    <span className="w-[0.3rem] h-[0.3rem] bg-gray-500 rounded-full" />
                  </>
                )}
                <span className="text-purple">({percent}%)</span>
              </div>
            </div>
            <div className="flex items-center gap-[0.3rem] [&>div]:rounded-[0.3rem] [&>div]:h-[1.2rem] [&>div]:transition-all duration-200">
              <div
                style={{ width: `calc(${percent}% - 0.3rem)` }}
                className="bg-purple hover:bg-purple-hover"
              />
              <div
                style={{ width: `calc(${100 - percent}% - 0.3rem)` }}
                className="bg-gray hover:bg-gray-hover"
              />
            </div>
          </div>
        );
      }}
    </HorizontalBar>
  );
};
