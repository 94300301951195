import React from "react";
import { FormikValues } from "formik";

export const handleChangeCheckboxHelper =
  (dispatcher: React.Dispatch<React.SetStateAction<string[]>>) =>
  ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      dispatcher((prev) => [...prev, target.value]);
    } else {
      dispatcher((prev) => prev.filter((value) => value !== target.value));
    }
  };

export const handleCleanAllHelper =
  (dispatcher: React.Dispatch<React.SetStateAction<string[]>>) => () => {
    dispatcher([]);
  };

export const getDisabledFromikRules = (formik: FormikValues) =>
  Object.values(formik.values).some((value) => value === "") ||
  Object.values(formik.errors).length > 0;

export const deepCloneJSON = <T extends Record<string, any>>(obj: T): T =>
  JSON.parse(JSON.stringify(obj));

export const removeKey = <T extends Record<string, any>>(
  obj: T,
  key: keyof T
): T => {
  const newObj = Boolean(window.structuredClone)
    ? structuredClone(obj)
    : deepCloneJSON(obj);

  delete newObj[key];

  return newObj;
};
