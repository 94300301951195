import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconActiveDialogs: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 13.9424H5.48063C5.17684 13.9424 4.88953 14.0799 4.69976 14.3161L1.89043 17.8122C1.59518 18.1796 1 17.9718 1 17.5012V2.99115C1 1.89147 1.89543 1 3 1H17C18.1046 1 19 1.89147 19 2.99115V8M12.5 11.5H17.5C18.3284 11.5 19 12.1657 19 12.9868V18.5036C19 18.8994 18.5549 19.1355 18.2227 18.9159L16.5038 17.7801C16.1753 17.5631 15.7893 17.4472 15.3944 17.4472H12.5C11.6716 17.4472 11 16.7815 11 15.9604V12.9868C11 12.1657 11.6716 11.5 12.5 11.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
