import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const ThunkWrapper = <T, U = void>(
  typePrefix: string,
  callback: (arg?: U) => Promise<T>
) => {
  return createAsyncThunk<T, U>(
    typePrefix,
    async (arg, { rejectWithValue }) => {
      try {
        return await callback(arg);
      } catch (error) {
        toast.error(error?.response?.data || "Something went wrong");
        return rejectWithValue(error);
      }
    }
  );
};
