import React, { ReactNode } from "react";
import classNames from "classnames";

type Props<T> = {
  data: T[];
  children: (item: T, index: number, parrentArray: T[]) => ReactNode;
  classNameList?: string;
};

export const HorizontalBar = <T extends { id: string }>({
  data,
  children,
  classNameList = "",
}: Props<T>) => {
  return (
    <ul className={classNames("column gap-10", classNameList)}>
      {data.map((item, index, parrentArray) => (
        <li
          className={classNames(
            "relative overflow-hidden w-[10%] animate-expandWidth before:hidden m-0"
          )}
          key={item.id}
        >
          {children(item, index, parrentArray)}
        </li>
      ))}
    </ul>
  );
};
