import classNames from "classnames";

export const Skeleton = ({
  length = 8,
  classNameWrapper = "",
  classNameItem = "",
}) => {
  return (
    <div
      className={classNames(
        "flex flex-wrap gap-[1.5rem] h-fit",
        classNameWrapper
      )}
    >
      {Array.from({ length }).map((_, index) => (
        <div
          key={index}
          className={classNames(
            `h-full w-full animate-pulse rounded-lg bg-gray-300 basis-[100%] sm:basis-[calc(50%-1.5rem/2)] md:basis-[calc((100%-1.5rem*3)/4)]`,
            classNameItem
          )}
        />
      ))}
    </div>
  );
};
