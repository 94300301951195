import React, { memo } from "react";
import { RoundedBox, Title } from "../../../../components";
import { FollowUps } from "./FollowUps";
import { KnowledgeBase } from "./KnowledgeBase";
import { SetBotTime } from "./SetBotTime";
import { SetNeedOperator } from "./SetNeedOperator";
import { SwitchToOperator } from "./SwitchToOperator";
import { ReplyFormat } from "./ReplyFormat";

export const AssistantToolkit = memo(() => {
  return (
    <RoundedBox>
      <Title text="Assistant toolkit" className="mb-7" component="h2" />
      <div className="column md:flex-row md:items-center gap-6 [&>div]:w-full md:[&>div]:w-1/2 md:h-[5.3rem] mb-6">
        <KnowledgeBase />
        <FollowUps />
      </div>
      <div className="column md:flex-row md:items-center gap-6">
        <SetBotTime />
        <SetNeedOperator />
        <SwitchToOperator />
        <ReplyFormat />
      </div>
    </RoundedBox>
  );
});
