import React from "react";

export const IconStatisticArrowDown = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.70711 2.70711L4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L8.29289 2.70711C8.92286 2.07714 8.47669 1 7.58579 1H2.41421C1.52331 1 1.07714 2.07714 1.70711 2.70711Z"
        fill="#F5777D"
        stroke="#F5777D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
