import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconChannels: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 8.33333V5.83333M10 8.33333C11.3807 8.33333 12.5 9.45262 12.5 10.8333C12.5 11.4547 12.2733 12.0231 11.8982 12.4603M10 8.33333C8.61929 8.33333 7.5 9.45262 7.5 10.8333C7.5 11.4547 7.72667 12.0231 8.10182 12.4603M10 5.83333C10.9205 5.83333 11.6667 5.08714 11.6667 4.16667C11.6667 3.24619 10.9205 2.5 10 2.5C9.07952 2.5 8.33333 3.24619 8.33333 4.16667C8.33333 5.08714 9.07952 5.83333 10 5.83333ZM11.8982 12.4603L14.5679 14.7487M11.8982 12.4603C11.4397 12.9948 10.7594 13.3333 10 13.3333C9.24062 13.3333 8.56033 12.9948 8.10182 12.4603M14.5679 14.7487C14.8736 14.3924 15.3271 14.1667 15.8333 14.1667C16.7538 14.1667 17.5 14.9129 17.5 15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5C14.9129 17.5 14.1667 16.7538 14.1667 15.8333C14.1667 15.4191 14.3178 15.0402 14.5679 14.7487ZM8.10182 12.4603L5.43212 14.7487M5.43212 14.7487C5.68222 15.0402 5.83333 15.4191 5.83333 15.8333C5.83333 16.7538 5.08714 17.5 4.16667 17.5C3.24619 17.5 2.5 16.7538 2.5 15.8333C2.5 14.9129 3.24619 14.1667 4.16667 14.1667C4.67292 14.1667 5.12645 14.3924 5.43212 14.7487Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
