import React from "react";

export const Icons = () => {
  return (
    <svg
      aria-hidden="true"
      style={{ position: "absolute", width: 0, height: 0, overflow: "hidden" }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <symbol id="icon-img-placeholder" viewBox="0 0 32 32">
          <path
            fill="none"
            stroke="#384157"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="1.8824"
            d="M12.021 29.219h7.843c6.536 0 9.15-2.614 9.15-9.15v-7.843c0-6.536-2.614-9.15-9.15-9.15h-7.843c-6.536 0-9.15 2.614-9.15 9.15v7.843c0 6.536 2.614 9.15 9.15 9.15z"
          ></path>
          <path
            fill="none"
            stroke="#384157"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="1.8824"
            d="M12.021 13.533c1.444 0 2.614-1.171 2.614-2.614s-1.171-2.614-2.614-2.614c-1.444 0-2.614 1.171-2.614 2.614s1.17 2.614 2.614 2.614z"
          ></path>
          <path
            fill="none"
            stroke="#384157"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth="1.8824"
            d="M3.747 25.232l6.444-4.327c1.033-0.693 2.523-0.614 3.451 0.183l0.431 0.379c1.020 0.876 2.667 0.876 3.686 0l5.438-4.667c1.020-0.876 2.667-0.876 3.686 0l2.131 1.83"
          ></path>
        </symbol>
        <symbol id="icon-target" viewBox="0 0 33 32">
          <path
            fill="#384157"
            d="M27.899 11.158c0.984 1.945 1.539 4.141 1.539 6.463 0 7.917-6.458 14.379-14.379 14.379-7.917 0-14.379-6.461-14.379-14.379 0-7.921 6.461-14.379 14.379-14.379 2.339 0 4.55 0.563 6.505 1.56l4.53-4.533c0.117-0.117 0.264-0.201 0.424-0.241s0.329-0.038 0.488 0.009c0.159 0.047 0.302 0.136 0.415 0.257s0.191 0.271 0.225 0.433l0.538 2.496 0.185-0.185c0.36-0.36 0.943-0.36 1.303 0s0.36 0.943 0 1.303l-0.185 0.185 2.496 0.538c0.162 0.035 0.311 0.113 0.433 0.225s0.21 0.256 0.257 0.415c0.047 0.159 0.050 0.327 0.009 0.488s-0.124 0.307-0.241 0.424l-4.542 4.542zM20.475 6.319c-1.641-0.791-3.478-1.234-5.416-1.234-6.903 0-12.536 5.631-12.536 12.536 0 6.903 5.633 12.536 12.536 12.536 6.906 0 12.536-5.633 12.536-12.536 0-1.926-0.438-3.754-1.22-5.387-0.044-0.003-0.087-0.009-0.13-0.018l-3.659-0.789-0.132 0.132c1.358 1.652 2.174 3.765 2.174 6.062 0 5.268-4.298 9.569-9.569 9.569-5.268 0-9.569-4.302-9.569-9.569 0-5.271 4.302-9.569 9.569-9.569 2.313 0 4.438 0.827 6.095 2.201l0.129-0.129-0.788-3.655c-0.011-0.049-0.017-0.099-0.020-0.15zM26.634 4.774l-0.432-2.005-3.8 3.802 0.432 2.003 3.801-3.8zM24.137 9.877l2.006 0.432 3.8-3.8-2.006-0.432-3.8 3.8zM19.846 11.562c-1.318-1.044-2.982-1.668-4.787-1.668-4.254 0-7.727 3.471-7.727 7.727 0 4.253 3.474 7.727 7.727 7.727 4.256 0 7.727-3.474 7.727-7.727 0-1.79-0.614-3.44-1.641-4.753l-2.12 2.12c0.519 0.78 0.795 1.696 0.794 2.633 0 2.629-2.131 4.757-4.76 4.757s-4.757-2.128-4.757-4.757c0-2.629 2.128-4.76 4.757-4.76 0.952-0.002 1.882 0.284 2.669 0.818l2.117-2.117zM16.386 15.022c-0.411-0.21-0.866-0.319-1.327-0.319-1.611 0-2.915 1.307-2.915 2.918s1.304 2.915 2.915 2.915c1.611 0 2.918-1.303 2.918-2.915 0-0.462-0.107-0.898-0.298-1.286l-1.953 1.953c-0.36 0.359-0.943 0.359-1.303 0s-0.36-0.943 0-1.303l1.963-1.963z"
          ></path>
        </symbol>
      </defs>
    </svg>
  );
};
