import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { HiOutlineTrash } from "react-icons/hi2";
import { Typography } from "../../../../../components";
import { Button } from "../../../../../components/Button";
import { PersonalInfoBlock } from "../../PersonalInfoBlock";
import { DeletePopup } from "../../../../../components/popups/DeletePopup";
import styles from "../Operators.module.scss";
import profileStyles from "../../../Profile.module.scss";
import {
  fetchOperators,
  setIsEditMode,
  setSelectedOperatorIndex,
  updateOperator,
  deleteOperator,
  setEditFormField,
} from "../../../../../store/slices/operatorsSlice";
import { NoOperators } from "../../../../../components/NoOperators";
import { DetailAvatarBlock } from "./DetailAvatarBlock";
import { OperatorMainInfo } from "./OperatorMainInfo";

export const SelectedOperatorInfo = ({ userId }) => {
  const dispatch = useDispatch();
  const { operators, selectedOperatorIndex, isEditMode, editForm } =
    useSelector((state) => state.operators);

  const selectedOperator =
    selectedOperatorIndex !== null ? operators[selectedOperatorIndex] : null;

  const [isDeleteOperatorPopupOpen, setIsDeleteOperatorPopupOpen] =
    useState(false);

  useEffect(() => {
    if (isEditMode && selectedOperator) {
      dispatch(
        setEditFormField({
          field: "full_name",
          value: selectedOperator?.full_name || "",
        })
      );
      dispatch(
        setEditFormField({
          field: "phone",
          value: selectedOperator?.phone || "",
        })
      );
      dispatch(
        setEditFormField({
          field: "email",
          value: selectedOperator?.email || "",
        })
      );
    }
  }, [isEditMode, selectedOperator, dispatch]);

  // Обробник редагування
  const handleEditOperator = () => {
    dispatch(setIsEditMode(true));
  };

  const handleCancelEdit = () => {
    dispatch(setIsEditMode(false));
  };

  const handleSaveChanges = async () => {
    if (!selectedOperator) return;

    try {
      await dispatch(
        updateOperator({
          accountId: selectedOperator?.account_id,
          moderator_id: selectedOperator?.id,
          full_name: editForm.full_name,
          phone: editForm.phone,
          userId: userId,
        })
      ).unwrap();

      toast.success("Changes saved successfully!");
      dispatch(setIsEditMode(false));
      dispatch(
        fetchOperators({
          accountId: selectedOperator.account_id,
          adminUserId: userId,
        })
      );

      const updatedIndex = operators.findIndex(
        (op) => op.id === selectedOperator.id
      );
      if (updatedIndex !== -1) {
        dispatch(setSelectedOperatorIndex(updatedIndex));
      }
    } catch (err) {
      toast.error(`Failed to save changes: ${err}`);
    }
  };

  const handleDeleteOperator = async () => {
    if (!selectedOperator) return;

    try {
      await dispatch(
        deleteOperator({
          accountId: selectedOperator?.account_id,
          moderator_id: selectedOperator?.id,
          userId: userId,
        })
      ).unwrap();

      toast.info("Operator has been deleted.");
      dispatch(setSelectedOperatorIndex(null));
      dispatch(
        fetchOperators({
          accountId: selectedOperator.account_id,
          adminUserId: userId,
        })
      );
    } catch (error) {
      toast.error(`Failed to delete operator: ${error}`);
    }
  };

  const handleInputChange = (field, value) => {
    dispatch(setEditFormField({ field, value }));
  };

  return (
    <div className={styles.selectedOperator}>
      {!selectedOperator ? (
        <div className={styles.noOperatorSelected}>
          <NoOperators
            title="No Operator Selected"
            description="Select an operator from the list to view details"
            needAddOperatorBtn={false}
          />
        </div>
      ) : (
        <>
          <div className={profileStyles.titleWrapper}>
            <Typography variant="h2">
              {!isEditMode ? "Operator Details" : "Edit Operator"}
            </Typography>
          </div>

          <DetailAvatarBlock
            selectedOperator={selectedOperator}
            onEditOperator={handleEditOperator}
            isEditMode={isEditMode}
          />
          {isEditMode ? (
            <PersonalInfoBlock
              fullName={editForm.full_name}
              setFullName={(val) => handleInputChange("full_name", val)}
              phoneValue={editForm.phone}
              setPhoneValue={(val) => handleInputChange("phone", val)}
              emailValue={editForm.email}
              setEmailValue={(val) => handleInputChange("email", val)}
              isEditMode={isEditMode}
              isChangePassword={false}
              isOperator={true}
              role={selectedOperator?.role}
              isAdding={false}
            />
          ) : (
            <OperatorMainInfo selectedOperator={selectedOperator} />
          )}

          {/* Save and cancel buttons */}
          {isEditMode && (
            <div className={styles.btnWrapper}>
              <Button
                title="Cancel"
                onClick={handleCancelEdit}
                className={styles.borderBtn}
              />
              <Button
                variant="contained"
                title="Save"
                onClick={handleSaveChanges}
              />
            </div>
          )}

          {/* Operator delete button */}
          {!isEditMode && (
            <button
              className={styles.btnDelete}
              onClick={() => setIsDeleteOperatorPopupOpen(true)}
            >
              Delete <HiOutlineTrash className={styles.icon} />
            </button>
          )}

          <DeletePopup
            isOpen={isDeleteOperatorPopupOpen}
            setIsOpen={setIsDeleteOperatorPopupOpen}
            onDelete={handleDeleteOperator}
            title="Are you sure you want to delete account?"
          />
        </>
      )}
    </div>
  );
};
