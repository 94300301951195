export const ROUTES = {
  dashboardPage: "/",
  loginPage: "/login",
  repeatPasswordPage: "/repeat-password",
  chatsPage: "/chats",
  messagesPage: "/chats/:clientId/:pageId?",
  aiConfigurationPage: "/configuration",
  aiConfigurationPageRedesign: "/redesign-configuration",
  channelsPage: "/channels",
  integrationPage: "/integration",
  integrationPage2: "/integration2",
  subscriptionPage: "/subscription",
  userChatPage: "/user-chat",
  profilePage: "/profile",
  checkMessengerPage: "/oauth/messenger",
  checkInstagramPage: "/oauth/instagram",
  CheckWhatsAppPage: "/oauth/whatsapp",
  checkOLXPage: "/oauth/olx",
  knowledgeBasePage: "/knowledge-base",
  followUpPage: "/follow-up",
  notifications: "/notifications",
  referralProgramPage: "/referral-program",
};
