import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useReduxStore } from "../../../../../hooks";
import { MessageType } from "./types";
import { FunctionResultMessage } from "./FunctionResultMessage";
import { CollapsibleMessage } from "./CollapsibleMessage";
import { MdToHTML } from "./MdToHTML";
import { AssistantFunctionCallMessage } from "./AssistantFunctionCallMessage";
import classNames from "classnames";

const styles = {
  user: "bg-purple text-light",
  assistant: "bg-gray",
};

type Props = {
  message: MessageType;
  index: number;
  collapseStates: Record<string, any>;
  onToggleCollapse: any;
};

export const Message: FC<Props> = ({
  message,
  index,
  collapseStates,
  onToggleCollapse,
}) => {
  const { debug } = useReduxStore();

  const location = useLocation();
  // const isConfigurationPage = location.pathname === "/configuration";
  // TODO: remove this after redesign is done
  const isConfigurationPage = location.pathname === "/redesign-configuration";
  const isChatsPage = location.pathname.startsWith("/chats");

  const isLongMessage = message.content.length > 400;

  const showFunctionMessages =
    isConfigurationPage || (isChatsPage && debug.isDebugMode);

  // 1) if message has function_call
  if (message.role === "assistant" && message.function_call) {
    if (!showFunctionMessages) return null;

    return (
      <div key={index}>
        <AssistantFunctionCallMessage
          message={message}
          index={index}
          collapseStates={collapseStates}
          onToggleCollapse={onToggleCollapse}
        />
      </div>
    );
  }

  if (message.role === "function") {
    if (!showFunctionMessages) return null;

    return (
      <div key={index}>
        <FunctionResultMessage
          message={message}
          index={index}
          collapseStates={collapseStates}
          onToggleCollapse={onToggleCollapse}
        />
      </div>
    );
  }

  return message.content.length > 0 ? (
    <div
      key={index}
      className={classNames("p-5 body-1 rounded-[1rem]", styles[message.role])}
    >
      {isLongMessage ? (
        <CollapsibleMessage content={message.content} maxLength={450} />
      ) : (
        <MdToHTML content={message?.content} />
      )}
    </div>
  ) : null;
};
