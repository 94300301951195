import React, { useEffect, useMemo, useState, FC } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useReduxStore, useAppDispatch } from "../../redesign/hooks";
import { useSelector } from "react-redux";
import {
  fetchKnowledgeBase,
  addNewKnowledgeBase,
  _listOfKnowledgeBase,
} from "../../store/slices/knowledgeBase";
import { Loader } from "../../components";
import { Title } from "../../components/Title";
import { Button } from "../../redesign/components";
import { KnowledgeBaseCard } from "./KnowledgeBaseCard";
import {
  IntegrationGoogleAuth,
  IntegrationWithKeys,
} from "../../components/Integration";

import { Icons } from "../../assets/icons/knowledgeBaseIcons/Icons";
import { AddKnowledgeBaseName } from "../../components/popups/AddKnowledgeBaseName";
import { useSubscription } from "../SubscriptionPage/useSubscription";

import { TOOL_TYPE } from "../../constants/general";
import { DOCUMENT_TYPES } from "../../constants/knowledgeBaseItems";
import { normalizeSubscriptionPlan } from "../../helper";
import { User } from "../../types/user";
import { KnowledgeBaseItem } from "../../types/knowledgeBase";

export const KnowledgeBasePage: FC = () => {
  // subscription upload
  const [subscriptionLoaded, setSubscriptionLoaded] = useState<boolean>(false);

  const [integrationInProgressId, setIntegrationInProgressId] = useState<
    number | null
  >(null);

  const dispatch = useAppDispatch();
  // Retrieve user from Redux
  const { auth } = useReduxStore();
  const user: User = auth.user;

  const listOfKnowledgeBase = useSelector(
    _listOfKnowledgeBase
  ) as KnowledgeBaseItem[];

  const { subscriptionPlan, isFetchingSubscription } = useSubscription(user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  const [isLoadingRefreshData, setIsLoadingRefreshData] =
    useState<boolean>(false);
  const [selectedIdKnowledgeBase, setSelectedIdKnowledgeBase] = useState<
    string | null
  >(null);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] =
    useState<KnowledgeBaseItem | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // fetch subscription
  useEffect(() => {
    if (!isFetchingSubscription && normalizedPlan !== undefined) {
      setSubscriptionLoaded(true);
    }
  }, [normalizedPlan, isFetchingSubscription]);

  const saveKnowledgeBase = (value): void => {
    if (!value) return;

    setIsOpen(false);
    if (!value) return;
    dispatch(
      addNewKnowledgeBase({
        id: parseInt(value, 10),
        knowledge_base: value,
        account_id: user.account_id,
        integration: "",
        created_at: new Date().toISOString(),
        access_token: null,
        refresh_token: null,
        expires_at: null,
        is_knowledge_base: true,
        type: null,
        outbound_channels: null,
        sheet_id: null,
        index_type: null,
        metadata: {},
        is_namespace: false,
      })
    );
    setSelectedIdKnowledgeBase(value);
  };

  useEffect(() => {
    if (listOfKnowledgeBase.length === 0) {
      setSelectedIdKnowledgeBase(null);
      setSelectedKnowledgeBase(null);
      return;
    }

    const currentKnowledgeBase = listOfKnowledgeBase.find(
      (el) => el.id === selectedIdKnowledgeBase
    );

    if (!currentKnowledgeBase) {
      // If the selected element does not exist, we automatically select the first one
      setSelectedIdKnowledgeBase(listOfKnowledgeBase[0].id);
      setSelectedKnowledgeBase(listOfKnowledgeBase[0]);
    } else {
      setSelectedKnowledgeBase(currentKnowledgeBase);
    }
  }, [listOfKnowledgeBase, selectedIdKnowledgeBase]);

  const onClickAddCard = (): void => {
    setIsOpen(true);
  };

  const onClickRefreshData = async (): Promise<void> => {
    try {
      setIsLoadingRefreshData(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/refresh-knowledge-integration-data?account=${user.account_id}&knowledge_base=${selectedIdKnowledgeBase}`
      );
      if (response.status === 200) {
        setTimeout(() => {
          setIsLoadingRefreshData(false);
          toast.success(
            "Knowledge refresh initiated, will take up to 5 minutes to propagate"
          );
        }, 250);
      }
    } catch (error: any) {
      toast.error(error.message);
      setIsLoadingRefreshData(false);
    }
  };

  const onClickSave = (): void => {
    setSelectedIdKnowledgeBase(null);
    setSelectedKnowledgeBase(null);
  };


  const getLink = (
    integration: string,
    id: string | undefined,
    accessToken: string | undefined
  ): string => {
    if (
      accessToken &&
      [
        "OpenCart CMS",
        "WordPress CMS",
        "XML Product Feed",
        "Site link",
      ].includes(integration)
    ) {
      return accessToken;
    }
    if (!id) return "";
    if (integration === "Spreadsheet Inventory") {
      return `https://docs.google.com/spreadsheets/d/${id}/edit`;
    }
    return `https://docs.google.com/document/d/${id}/edit`;
  };

  const fetchIntegrations = (value?: any): void => {
    dispatch(fetchKnowledgeBase(user.account_id));
  };

  useEffect(() => {
    fetchIntegrations();
  }, [user, dispatch]);

  useEffect(() => {
    if (!selectedIdKnowledgeBase) return;
    const result = listOfKnowledgeBase.find(
      (el) => el.id === selectedIdKnowledgeBase
    );
    if (!result) return;
    setSelectedKnowledgeBase(result);
  }, [listOfKnowledgeBase, selectedIdKnowledgeBase]);

  useEffect(() => {
    if (listOfKnowledgeBase.length) return;
    onClickSave();
  }, [listOfKnowledgeBase]);

  const isAnyCMSConnected = useMemo((): boolean => {
    return (
      selectedKnowledgeBase?.items?.some(
        (el) => el.type === TOOL_TYPE.CMS && el.connected
      ) ?? false
    );
  }, [selectedKnowledgeBase]);

  const isAnyDocumentConnected = useMemo((): boolean => {
    return (
      selectedKnowledgeBase?.items?.some((el) => {
        return (
          DOCUMENT_TYPES.includes(el.integration.toLowerCase()) && el.connected
        );
      }) ?? false
    );
  }, [selectedKnowledgeBase]);

  if (!subscriptionLoaded || isFetchingSubscription) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader height={40} width={40} />
      </div>
    );
  }

  return (
    <div
      className="relative flex flex-col items-center h-full p-8 rounded-xl bg-gray-300
                 md:flex-row md:items-end md:justify-between md:mt-10 md:ml-10 md:pt-8 md:pr-8 md:pb-0 md:pl-8
                 md:rounded-none md:rounded-tl-xl md:rounded-bl-xl customScroll"
    >
      <Icons />
      <AddKnowledgeBaseName
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        saveKnowledgeBase={saveKnowledgeBase}
      />

      <div className="flex flex-col items-start w-full h-full">
        <div className="flex flex-col items-center justify-between w-full gap-8 mb-8 sm:flex-row sm:mb-12">
          <Title title="Knowledge Base" />
          <Button
            className="m-0 w-auto px-10 py-3 rounded-[0.5rem] bg-gradient-blue-to-purple-angled text-white uppercase font-semibold text-xl leading-[1.7rem] shadow-md transition-all duration-200 hover:shadow-lg"
            onClick={onClickAddCard}
            disabled={normalizedPlan === null}
          >
            add new knowledge Base
          </Button>
        </div>

        {/* Gallery */}
        <div className="grid justify-center w-full mb-8 pb-10 gap-x-10 gap-y-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
          {listOfKnowledgeBase &&
            listOfKnowledgeBase.map((el) => (
              <KnowledgeBaseCard
                btnText="EDIT"
                title={el.title}
                key={el.id}
                id={el.id}
                isLoading={isLoadingRefreshData}
                selectedId={selectedIdKnowledgeBase}
                clickHandler={setSelectedIdKnowledgeBase}
                subscriptionPlan={normalizedPlan}
              >
                <svg width="50" height="50">
                  <use href="#icon-target"></use>
                </svg>
              </KnowledgeBaseCard>
            ))}
        </div>

        {selectedKnowledgeBase && (
          <div className="flex flex-col items-start w-full h-full">
            <div className="flex flex-col items-center justify-between w-full gap-8 mb-8 sm:flex-row sm:mb-12">
              <Title title={selectedKnowledgeBase.title} />
              <div className="flex gap-[0.9rem]">
                <Button
                  className="m-0 w-auto px-10 py-3 rounded-[0.5rem] bg-gradient-blue-to-purple-angled text-white uppercase font-semibold text-xl leading-[1.7rem] shadow-md transition-all duration-200 hover:shadow-lg"
                  onClick={onClickSave}
                >
                  SAVE
                </Button>
                <Button
                  className="m-0 w-auto px-10 py-3 rounded-[0.5rem] bg-gradient-blue-to-purple-angled text-white uppercase font-semibold text-xl leading-[1.7rem] shadow-md transition-all duration-200 hover:shadow-lg"
                  onClick={onClickRefreshData}
                >
                  REFRESH DATA
                </Button>
              </div>
            </div>

            <div
              className="grid justify-center w-full mb-8 pb-10 gap-x-10 gap-y-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5"
              style={{ gridTemplateColumns: "repeat(5, 1fr)" }}
            >
              {selectedKnowledgeBase.items?.map((el) => {
                const sharedProps = {
                  metadata: el.metadata,
                  title: el.integration,
                  sheetId: el.sheet_id,
                  accessToken: el.access_token,
                  refreshToken: el.refresh_token,
                  docLink: getLink(
                    el.integration,
                    el.sheet_id,
                    el.access_token
                  ),
                  knowledgeBase: selectedIdKnowledgeBase,
                  description: el.description,
                  icon: el.icon,
                  key: el.id,
                  id: el.id,
                  scope: el.scope,
                  connected: el.connected,
                  isWorking: el.isWorking,
                  fetchIntegrations: fetchIntegrations,
                  isLoadingRefreshData: isLoadingRefreshData,
                  isAnyDocumentConnected: isAnyDocumentConnected,
                  integrationInProgressId,
                  setIntegrationInProgressId,
                };
                const isGoogleAuthIntegration =
                  el.isGoogleAuth ||
                  ("type" in el && el.type !== TOOL_TYPE.CMS);

                return isGoogleAuthIntegration ? (
                  <IntegrationGoogleAuth {...sharedProps}>
                    <svg width="50" height="50">
                      <use href="#icon-img-placeholder"></use>
                    </svg>
                  </IntegrationGoogleAuth>
                ) : (
                  <IntegrationWithKeys
                    {...sharedProps}
                    isAnyCMSConnected={isAnyCMSConnected}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowledgeBasePage;
