import { FC, useCallback, useMemo, useState } from "react";

import { AssistantProfileInner } from "./AssistantProfileInner";
import { BotAssistant } from "./BotAssistant";
import { useDispatch } from "react-redux";
import { useReduxStore } from "../../hooks";
import { AIConfigurationContext } from "./context";
import { toast } from "react-toastify";

import { getData, updateData, addData } from "../../../service/supabase";

import { useOptimizeInstructions } from "../../../hooks/useOptimizeInstructions";
import { createABot, createAssistant } from "../../../helper/create-data";
import { fetchInstructions } from "../../../store/slices/assistantSlice";
import { setOnboarding } from "../../../store/slices/onboardingSlice";
import { removeKey } from "../../utils";

export const AIConfigurationPageRedesign: FC = () => {
  const { auth } = useReduxStore();
  const dispatch = useDispatch();

  const [isTyping, setIsTyping] = useState(false);
  const [assistant, setAssistant] = useState<Assistant | null>(null);
  const [checked, setChecked] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: 1,
    label: "English",
  });
  const [originalData, setOriginalData] = useState<OriginalData>({
    botName: "",
    roleAndInstructions: "",
    selectedLanguage: { value: 1, label: "English" },
    selectedCommunicationStyle: { value: 0, label: "" },
    welcomeMessage: "",
    followUps: [],
    metadata: {},
  });

  const { roleAndInstructions, setIsOptimizeInstructions } =
    useOptimizeInstructions(auth?.user.account_id, assistant?.id);

  const [selectedCommunicationStyle, setSelectedCommunicationStyle] =
    useState<Option | null>(null);

  const handleSaveChanges = useCallback(
    async (updatedData?: UpdatedAssistantConfigurationsData) => {
      if (!auth?.user?.account_id) return;

      const {
        bot_name,
        welcome_message,
        follow_ups,
        knowledge_base,
        bot_time = {},
        operator_tg,
        operator_viber,
        metadata,
        communication_style,
      } = updatedData ?? {};

      try {
        const { data: instruction } = await getData(
          "assistants",
          "*",
          "account_id",
          auth?.user?.account_id
        );

        const createdAssistant = createAssistant(
          assistant?.id,
          auth?.user?.account_id,
          bot_name || assistant?.bot_name,
          selectedLanguage?.value,
          communication_style ?? assistant?.communication_style,
          welcome_message || assistant?.welcome_message,
          roleAndInstructions,
          knowledge_base || assistant?.knowledge_base,
          follow_ups || assistant?.follow_ups,
          bot_time || assistant?.bot_time,
          operator_tg || assistant?.operator_tg || "",
          operator_viber || assistant?.operator_viber || "",
          metadata || assistant?.metadata
        );

        const bot = createABot(
          bot_name || assistant?.bot_name,
          roleAndInstructions,
          selectedLanguage,
          selectedCommunicationStyle,
          welcome_message || assistant?.welcome_message,
          follow_ups || assistant?.follow_ups
        );

        setIsOptimizeInstructions(false);
        setOriginalData(bot);

        if (instruction.length) {
          await addData("assistants", [createdAssistant]);
          dispatch(fetchInstructions(auth?.user?.account_id));
          toast.success(`Assistant ${assistant?.bot_name} was saved!`);
        }

        const updatedAssistant = removeKey(createdAssistant, "id");

        await updateData("assistants", updatedAssistant, {
          account_id: auth?.user?.account_id,
          id: assistant?.id,
        });

        await updateData(
          "onboardings",
          { step: 6 },
          { account_id: auth?.user?.account_id }
        );
        dispatch(fetchInstructions(auth?.user?.account_id));
        dispatch(setOnboarding(6));
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message);
        } else {
          toast.error("An unknown error occurred");
        }
      }
    },
    [
      assistant?.bot_name,
      assistant?.bot_time,
      assistant?.communication_style,
      assistant?.follow_ups,
      assistant?.id,
      assistant?.knowledge_base,
      assistant?.metadata,
      assistant?.operator_tg,
      assistant?.operator_viber,
      assistant?.welcome_message,
      auth?.user?.account_id,
      dispatch,
      roleAndInstructions,
      selectedCommunicationStyle,
      selectedLanguage,
      setIsOptimizeInstructions,
    ]
  );

  const AIConfigurationState = useMemo(
    () => ({
      checked,
      setChecked,
      assistant,
      setAssistant,
      selectedLanguage,
      setSelectedLanguage,
      selectedCommunicationStyle,
      setSelectedCommunicationStyle,
      originalData,
      setOriginalData,
      handleSaveChanges,
      isTyping,
      setIsTyping,
    }),
    [
      assistant,
      checked,
      handleSaveChanges,
      isTyping,
      originalData,
      selectedCommunicationStyle,
      selectedLanguage,
    ]
  );

  return (
    <AIConfigurationContext.Provider value={AIConfigurationState}>
      <div className="py-10 px-2 md:px-6 flex gap-[1.5rem] relative">
        <div className="w-full md:w-[63%]">
          <AssistantProfileInner />
        </div>
        <div className="w-0 h-0 md:w-[30%] hidden md:block md:h-[calc(100vh-5rem)] md:fixed top-10 right-6">
          <BotAssistant />
        </div>
      </div>
    </AIConfigurationContext.Provider>
  );
};
