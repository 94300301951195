import React, { FC, ChangeEvent } from "react";
import { Button, Modal } from "../../../redesign/components";

interface DefaultIntegrationModalProps {
  open: boolean;
  toggleOpen: () => void;
  title: string;
  domain: string;
  onDomainChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isDomainValid: boolean;
  domainErrorText: string;
  pluginName: string;
  onPluginNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isPluginNameValid: boolean;
  inventoryName?: string;
  onConnect: () => void;
  isButtonDisabled: boolean;
}

export const DefaultIntegrationModal: FC<DefaultIntegrationModalProps> = ({
  open,
  toggleOpen,
  title,
  domain,
  onDomainChange,
  isDomainValid,
  domainErrorText,
  pluginName,
  onPluginNameChange,
  isPluginNameValid,
  inventoryName,
  onConnect,
  isButtonDisabled,
}) => {
  return (
    <Modal
      open={open}
      toggleOpen={toggleOpen}
      title={`Connect ${title}`}
      actions={
        <div className="flex justify-center">
          <Button
            variant="primary"
            onClick={onConnect}
            disabled={
              !domain ||
              !isDomainValid ||
              (inventoryName && !pluginName) ||
              isButtonDisabled
            }
            className="w-1/3  mx-auto"
          >
            Connect
          </Button>
        </div>
      }
    >
      <div className="flex flex-col w-full gap-4">
        <label className="text-xl font-semibold">
          {inventoryName === "xml-inventory"
            ? "XML file URL"
            : inventoryName === "yml-inventory"
            ? "YML file URL"
            : "Website URL"}
        </label>
        <input
          type="url"
          value={domain}
          onChange={onDomainChange}
          className="w-full border border-gray-300 rounded-lg p-2 text-lg"
          placeholder={
            inventoryName === "yml-inventory"
              ? "https://yourwebsite.com/var/files/1/yml/prices.yml"
              : title === "Site link"
              ? "https://yourwebsite.com"
              : "https://example.com/feed.xml"
          }
        />
        {!isDomainValid && (
          <span className="text-red-500 text-sm mt-2">{domainErrorText}</span>
        )}
        {inventoryName &&
          inventoryName !== "xml-inventory" &&
          inventoryName !== "yml-inventory" && (
            <>
              <label className="text-xl mt-6 font-semibold">
                Plugin Name (Alphanumeric characters and spaces/hyphens only)
              </label>
              <input
                type="text"
                value={pluginName}
                onChange={
                  inventoryName !== "xml-inventory"
                    ? onPluginNameChange
                    : () => {}
                }
                className="w-full border border-gray-300 rounded-lg p-2 text-lg"
                placeholder="Plugin for My Site"
              />
              {!isPluginNameValid && (
                <span className="text-red-500 text-sm mt-1">
                  Plugin name is required
                </span>
              )}
            </>
          )}
      </div>
    </Modal>
  );
};
