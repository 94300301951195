import { time } from "../constants/configuration.js";

export const createABot = (
  botName,
  roleAndInstructions,
  selectedLanguage,
  selectedCommunicationStyle,
  welcomeMessage,
  followUps,
  knowledge_base
) => ({
  botName,
  roleAndInstructions,
  selectedLanguage,
  selectedCommunicationStyle,
  welcomeMessage,
  followUps,
  knowledge_base,
});

export const createAssistant = (
  id,
  account_id,
  bot_name,
  language,
  communication_style,
  welcome_message,
  instructions,
  knowledge_base,
  follow_ups,
  bot_time,
  operator_tg,
  operator_viber,
  metadata
) => {
  var obj = {
    account_id,
    bot_name,
    language,
    communication_style,
    welcome_message,
    knowledge_base,
    instructions,
    follow_ups,
    bot_time,
    operator_tg,
    operator_viber,
    metadata,
  };

  if (id) {
    obj.id = id;
  }

  return obj;
};
