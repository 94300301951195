import { useCallback, useEffect, useRef } from "react";

type Options = {
  shouldScroll?: boolean;
} & ScrollIntoViewOptions;

export const useScrollDown = (
  trigger?: boolean,
  { shouldScroll = true, ...options }: Options = {}
) => {
  const bottomListRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (!shouldScroll) return;

    const element = bottomListRef.current;

    if (!element) return;

    requestAnimationFrame(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
        ...options,
      });

      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);

      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 200);
    });
  }, [options, shouldScroll]);

  useEffect(() => {
    if (!trigger) return;

    scrollToBottom();
  }, [scrollToBottom, trigger]);

  return bottomListRef;
};
