import React, { FC, ChangeEvent } from "react";
import { Button, Modal } from "../../../redesign/components";

interface AddWhatsAppTemplateModalProps {
  open: boolean;
  toggleOpen: () => void;
  editMode: boolean;
  templateName: string;
  onTemplateNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  messageContent: string;
  onMessageContentChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSave: () => void;
}

export const AddWhatsAppTemplateModal: FC<AddWhatsAppTemplateModalProps> = ({
  open,
  toggleOpen,
  editMode,
  templateName,
  onTemplateNameChange,
  messageContent,
  onMessageContentChange,
  onSave,
}) => {
  return (
    <Modal
      open={open}
      toggleOpen={toggleOpen}
      title={editMode ? "Edit Template" : "Create New Template"}
    >
      <div className="flex flex-col gap-4 mt-4">
        <label>Template Name</label>
        <input
          type="text"
          placeholder="Enter template name"
          value={templateName}
          onChange={onTemplateNameChange}
          className="border border-gray-300 rounded-xl p-2"
        />

        <label className="mt-6">Message Content</label>
        <textarea
          rows={3}
          placeholder="Type your message here"
          value={messageContent}
          onChange={onMessageContentChange}
          className="border border-gray-300 rounded-xl p-2"
        />
      </div>

      <div className="flex justify-end gap-4 mt-6">
        <Button variant="secondary" onClick={toggleOpen}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};
