import { Keys, Values } from "../types";

export const colors = {
  primary: "#000000",
  secondary: "#7e7e7e",
  accent: "#2ca6e7",
  light: "#ffffff",
  danger: "#f5777d",
  purple: "#777ef5",
  "purple-hover": "#5257b0",
  "purple-light": "#a7abf9",
  "light-black2": "#7d8aab",
  gray: "#f0f2f4",
  "gray-hover": "#dcdede",
  "light-gray": "#d9d9db",
  "gray-300": "#e3e6ee",
  "gray-400": "#4c5876",
  "gray-500": "#384157",
  "blue-800": "#1f384c",
  "gray-medium": "#727272",
  disabled: "#9e9e9e",
  "light-red": "#ffe5e7",
  "light-green": "#e8ffeb",
  green: "#7ae575",
} as const;

export type ColorsKeys = Keys<typeof colors>;
export type ColorsValues = Values<typeof colors>;
