import React, { useRef } from "react";
import { toast } from "react-toastify";
import { allowedFileTypes } from "../../constants/chats";
import { GrAttachment } from "react-icons/gr";

interface FileUploaderProps {
  onFileSelected: (file: File) => void;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileSelected,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 7 * 1024 * 1024) {
      toast.error("File size should be less than 7MB");
      return;
    }

    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    // Checking the MIME type
    let isAllowed = allowedFileTypes.includes(file.type);

    // If the MIME type is not allowed or missing, check the extension
    if (!isAllowed && fileExtension) {
      const allowedArchiveExtensions = ["zip", "rar", "tar", "gz", "7z"];
      if (allowedArchiveExtensions.includes(fileExtension)) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      toast.error(
        "Invalid file format. Allowed formats: images, documents, archives."
      );
      return;
    }

    onFileSelected(file);
    toast.success("File attached!");
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileUploadChange}
      />
      <button
        type="button"
        onClick={handleAttachClick}
        className="absolute right-3 top-1/2 transform -translate-y-1/2 z-20 text-gray-600 hover:text-gray-800"
      >
        <GrAttachment className="w-8 h-8" color="#7d8aab" />
      </button>
    </>
  );
};
