import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import {
  CheckboxProps,
  Checkbox as MTCheckbox,
} from "@material-tailwind/react";
import classNames from "classnames";
import { Dropdown } from "../Dropdown";
import { FormikValues } from "formik";

type Props = {
  label: string;
  dropdown?: ReactNode | null;
  classNameMenu?: string;
  formik?: FormikValues;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "color"> &
  Omit<CheckboxProps, "ref">;

export const Checkbox: FC<Props> = ({
  className = "",
  classNameMenu = "",
  onChange,
  dropdown = null,
  formik = {},
  ...props
}) => {
  const [checked, setChecked] = useState(false);

  const hableToggleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked((prev) => !prev);

    if (onChange) {
      onChange(event);
    }

    if (formik?.handleChange) {
      formik.handleChange(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(event);
    }

    if (formik?.handleBlur) {
      formik.handleBlur(event);
    }
  };

  return (
    <div
      className={classNames({
        "flex items-center justify-between": Boolean(dropdown),
      })}
    >
      <MTCheckbox
        onChange={hableToggleCheckbox}
        onBlur={handleBlur}
        checked={checked}
        className={classNames(
          checked || props.checked
            ? "checked:bg-purple checked:border-purple"
            : "",
          className
        )}
        ripple
        color="purple"
        {...props}
      />
      {dropdown && (
        <Dropdown
          variant="default"
          body={dropdown}
          classNameMenu={classNames(
            "min-w-[7rem] left-auto right-0",
            classNameMenu
          )}
        />
      )}
    </div>
  );
};
