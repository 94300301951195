import { useCallback, useState } from "react";
import Switch from "react-switch";
import { useSelector } from "react-redux";
import supabase from "../../../../supabase";
import { useRemoveChannelMutation } from "../../../../store/api";
import { useSetupWebhookMutation } from "../../../../store/api";
import { Loader } from "../../../../components/Loader";
import styles from "./WhatsApp.module.scss";
import { Title } from "../../../../components/Title";
import { Button } from "../../../../components/Button";

import { ReplayDelayInput } from "../ReplyDelayInput";
import { EnableBotForm } from "../EnableBotForm";
import { toast } from "react-toastify";
import { Input } from "../../../../components";
import { voiceOptions } from "../../../../constants";
import { getLabelVoice } from "../../helpers";
import { WhatsAppTemplates } from "./WhatsAppTemplates";

export const WhatsApp = ({
  channelData,
  fetchChannels,
  assistantId,
  setChannelData,
}) => {
  const channel = channelData[0];

  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateChannelLoading, setIsUpdateChannelLoading] = useState(false);

  const [removeChannel] = useRemoveChannelMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  const openWhatsAppAuth = () => {
    const state = `${user.account_id}|${assistantId}`;
    const redirectUri = encodeURIComponent(
      `${process.env.REACT_APP_DOMAIN}/oauth/whatsapp`
    );
    const clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const scope = encodeURIComponent(
      "whatsapp_business_management whatsapp_business_messaging"
    );

    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${clientId}&display=popup&redirect_uri=${redirectUri}&response_type=code&config_id=1356984542402259&state=${encodeURIComponent(
      state
    )}&scope=${scope}&response_type=code`;
  };

  const updateChannelData = useCallback(
    async (updatedChannel) => {
      setIsUpdateChannelLoading(true);
      try {
        if (!channel) throw new Error("Failed to update channel");

        const { data, error } = await supabase
          .from("channels")
          .update(updatedChannel)
          .eq("access_token", channel.access_token)
          .eq("account_id", channel.account_id)
          .eq("communication_channel", "WhatsApp")
          .select();

        if (error) {
          throw new Error("Failed to update channel");
        }

        const updatedChannelData = data[0];

        if (!updatedChannelData) throw new Error("Failed to update channel");

        const setUpdatedChannels = channelData.map((channel) => {
          if (channel.access_token === updatedChannelData.access_token) {
            return updatedChannelData;
          }
          return channel;
        });

        setChannelData(setUpdatedChannels);

        return updatedChannelData;
      } catch (error) {
        return null;
      } finally {
        setIsUpdateChannelLoading(false);
      }
    },
    [channel, channelData, setChannelData]
  );

  const handleUpdateChannelMetadata = useCallback(
    async (metadata) => {
      try {
        const data = await updateChannelData({ metadata });

        if (data) {
          toast.success("Voice control updated");
        }
      } catch (error) {
        toast.error("Failed to update channel");
        throw new Error("Failed to update channel");
      }
    },
    [updateChannelData]
  );

  const setupChannelWebhook = async (channelName) => {
    const { error } = await setupWebhook({
      channel: channelName,
      account_id: user.account_id,
      assistant_id: assistantId,
      page_id: channel.page_id,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const revokeToken = async () => {
    try {
      setIsLoading(true);
      const { error: supabaseError } = await supabase
        .from("channels")
        .delete()
        .eq("account_id", user.account_id)
        .eq("communication_channel", "WhatsApp")
        .eq("assistant_id", assistantId);

      if (supabaseError) {
        throw new Error("Failed to remove channel");
      }
      await fetchChannels();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title title={"WhatsApp"} />

      <EnableBotForm
        disabled={!channel?.access_token}
        channel={channel}
        matchConfig={{
          account_id: user.account_id,
          communication_channel: "WhatsApp",
          assistant_id: assistantId,
        }}
        setupChannelWebhook={async () => await setupChannelWebhook("WhatsApp")}
        setIsLoading={setIsLoading}
        fetchChannels={fetchChannels}
      >
        <ReplayDelayInput
          disabled={!channel?.access_token}
          channelName="WhatsApp"
          channel={channel}
          user={user}
          fetchChannels={fetchChannels}
          assistantId={assistantId}
        />
      </EnableBotForm>

      <div className={styles.connectionBot}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader height={40} width={40} />
          </div>
        ) : (
          <>
            {channel?.access_token && (
              <WhatsAppTemplates
                channel={channel}
                onSave={async (updatedMetadata) => {
                  await updateChannelData({ metadata: updatedMetadata });
                }}
              />
            )}
            {channel && (
              <div className={"mt-auto column items-start gap-4"}>
                <p className="text-[1.7rem] font-semibold">
                  Voice reply to voice message
                </p>
                <div className={styles.channelVoiceControl}>
                  <Switch
                    disabled={!channel || isUpdateChannelLoading}
                    onChange={() =>
                      handleUpdateChannelMetadata({
                        ...channel?.metadata,
                        voice_reply: !Boolean(channel?.metadata?.voice_reply),
                      })
                    }
                    checked={Boolean(channel?.metadata?.voice_reply)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={27}
                    offColor={"#E3E6EE"}
                    onColor={"#3588E9"}
                  />
                  <Input
                    disabled={
                      !channel?.metadata?.voice_reply || isUpdateChannelLoading
                    }
                    typeInput="select"
                    placeholder={
                      getLabelVoice(channel?.metadata?.voice) || "Select voice"
                    }
                    options={voiceOptions}
                    onChange={({ value: voice }) =>
                      handleUpdateChannelMetadata({
                        ...channel?.metadata,
                        voice,
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className={"flex justify-end"}>
              {channel?.access_token && (
                <p className={styles.pageDescription}>
                  WABA: {channel.page_description}
                </p>
              )}
              {channel?.access_token ? (
                <Button
                  onClick={revokeToken}
                  className={styles.saveOptimize}
                  title={"REVOKE TOKEN"}
                />
              ) : (
                <Button
                  onClick={openWhatsAppAuth}
                  className={styles.saveOptimize}
                  title={"CONNECT WHATSAPP"}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
