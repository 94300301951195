import React, { useState, useRef, useEffect } from "react";
import styles from "./CollapsibleMessage.module.scss";
import { MdToHTML } from "../MdToHTML";
import { PiArrowLineDownRightLight } from "react-icons/pi";

export const CollapsibleMessage = ({ content, maxLength = 450 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const contentRef = useRef(null);
  const expandedContentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const needsTruncate = content.length > maxLength;
  const displayedContent = isExpanded
    ? content
    : `${content.substring(0, maxLength)}...`;

  useEffect(() => {
    if (isExpanded && expandedContentRef.current) {
      // Set max height to the full content height when expanded
      setMaxHeight(`${expandedContentRef.current.scrollHeight + 50}px`);
    } else if (!isExpanded && contentRef.current) {
      // Set max height to truncated content height when collapsed
      setMaxHeight(`${contentRef.current.scrollHeight + 50}px`);
    }
  }, [isExpanded, content]);

  return (
    <div className={styles.collapsibleMessage}>
      <div
        className={`${styles.contentWrapper} ${
          isExpanded ? styles.expanded : ""
        }`}
        style={{ maxHeight }}
      >
        {isExpanded ? (
          <div ref={expandedContentRef}>
            <MdToHTML content={content} />
          </div>
        ) : (
          <div ref={contentRef}>
            <MdToHTML content={displayedContent} />
          </div>
        )}
      </div>

      {needsTruncate && (
        <button onClick={toggleExpand} className={styles.toggleButton}>
          {isExpanded ? "Show less 🔼" : "Show more 🔽"}
        </button>
      )}
    </div>
  );
};
