import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconSend: FC<IconProps> = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 12L4.83771 17.7661C4.52632 18.5965 5.36976 19.3912 6.18018 19.031L19.9439 12.9138C20.7357 12.5619 20.7357 11.4381 19.9439 11.0862L6.18018 4.96897C5.36976 4.60878 4.52632 5.40351 4.83771 6.2339L7 12ZM7 12H13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
