import { useEffect } from "react";

export function useRestoreFilters({
  didRestore,
  setDidRestore,
  searchParams,

  setAppliedAssistants,
  setSelectedAssistants,
  setAppliedChannels,
  setSelectedChannels,
  setAppliedFunnelStatuses,
  setSelectedFunnelStatuses,
  setAppliedTimeRange,
  setSelectedTimeRange,
  setAppliedManagers,
  setSelectedManagers,
  setSearchValue,
  setAppliedSearchValue,
  isRestoringRef,
  channelOptions,
  dispatch,
}) {
  useEffect(() => {
    if (didRestore) return;

    try {
      const assistantsParam = localStorage.getItem("appliedAssistants");

      if (assistantsParam) {
        const parsedAssistants = JSON.parse(assistantsParam);
        if (Array.isArray(parsedAssistants)) {
          setAppliedAssistants(parsedAssistants);
          dispatch(setSelectedAssistants(parsedAssistants));
        } else {
          console.error("Parsed assistants is not an array:", parsedAssistants);
          setAppliedAssistants([]);
          dispatch(setSelectedAssistants([]));
        }
      } else {
        setAppliedAssistants([]);
        dispatch(setSelectedAssistants([]));
      }

      const channelsParam = searchParams.get("channels");

      if (channelsParam) {
        const cVals = channelsParam.split(",");
        const selected = channelOptions.filter((ch) =>
          cVals.includes(ch.value)
        );
        setAppliedChannels(selected);
        setSelectedChannels(selected);
      } else {
        setAppliedChannels([]);
        setSelectedChannels([]);
      }

      const funnelParam = searchParams.get("funnel");
      if (funnelParam) {
        const fVals = funnelParam.split(",");
        const mapped = fVals.map((fv) => ({ value: fv, label: fv }));
        setAppliedFunnelStatuses(mapped);
        setSelectedFunnelStatuses(mapped);
      } else {
        setAppliedFunnelStatuses([]);
        setSelectedFunnelStatuses([]);
      }

      const managersParam = searchParams.get("managers");
      if (managersParam) {
        const mVals = managersParam.split(",");
        const mapped = mVals.map((mv) => ({ value: mv, label: mv }));
        setAppliedManagers(mapped);
        setSelectedManagers(mapped);
      } else {
        setAppliedManagers([]);
        setSelectedManagers([]);
      }

      const timeRangeParam = searchParams.get("timeRange");
      if (timeRangeParam) {
        if (timeRangeParam.startsWith("custom:")) {
          const [, range] = timeRangeParam.split(":");
          const [startDate, endDate] = range.split("_");
          const newTimeRange = { value: "custom", startDate, endDate };
          setAppliedTimeRange(newTimeRange);
          setSelectedTimeRange(newTimeRange);
        } else if (timeRangeParam.endsWith("days")) {
          const days = timeRangeParam.replace("days", "");
          const newTimeRange = { value: days, type: "active" };
          setAppliedTimeRange(newTimeRange);
          setSelectedTimeRange(newTimeRange);
        }
      } else {
        setAppliedTimeRange(null);
        setSelectedTimeRange(null);
      }

      const searchParam = searchParams.get("search");
      if (searchParam) {
        setSearchValue(searchParam);
        setAppliedSearchValue(searchParam);
      } else {
        setSearchValue("");
        setAppliedSearchValue("");
      }
    } finally {
      isRestoringRef.current = false;
      setDidRestore(true);
    }
  }, []);
}
