import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconFunnelCRM: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 2.5H4.16667M4.16667 2.5V6.7396C4.16667 7.38229 4.47204 7.9943 5.00576 8.42127L7.35727 10.3025C7.71309 10.5871 7.91667 10.9951 7.91667 11.4236V17.5L10.4167 16V11.4236C10.4167 10.9951 10.6202 10.5871 10.9761 10.3025L13.3276 8.42127C13.8613 7.9943 14.1667 7.38229 14.1667 6.7396V5.5M4.16667 2.5H14.1667M14.1667 2.5H15.8333M14.1667 2.5V5.5M14.1667 5.5H15.8333C16.7538 5.5 17.5 6.24619 17.5 7.16667V8.5"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
