import { RoundedBox } from "../../../components";
import { StatisticDropdowns } from "./StatisticDropdowns";
import { StatisticList } from "./StatisticList";
import { GeneralStatisticHeader } from "./GeneralStatisticHeader";

export const GeneralStatistic = () => {
  return (
    <RoundedBox className="column gap-[1.5rem]">
      <GeneralStatisticHeader />
      <StatisticDropdowns />
      <StatisticList />
    </RoundedBox>
  );
};
