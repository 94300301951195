import React, { useState } from "react";

import { Button, Checkbox, Modal, Title } from "../../../../../components";
import { useAIConfigurationState } from "../../../context";
import { useFormik } from "formik";

type InitialValues = {
  remove_links: boolean;
  split_reply_by_paragraphs: boolean;
  allow_ai_postpone_messages: boolean;
};

const initialValues: InitialValues = {
  remove_links: false,
  split_reply_by_paragraphs: false,
  allow_ai_postpone_messages: false,
};

export const ReplyFormat = () => {
  const [open, setOpen] = useState(false);

  const { assistant, handleSaveChanges } = useAIConfigurationState();

  const onSubmit = (values: InitialValues) => {
    handleSaveChanges({
      ...assistant,
      metadata: { ...assistant?.metadata, ...values },
    });

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      remove_links: assistant?.metadata?.remove_links || false,
      split_reply_by_paragraphs:
        assistant?.metadata?.split_reply_by_paragraphs || false,
      allow_ai_postpone_messages:
        assistant?.metadata?.allow_ai_postpone_messages || false,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const disabled =
    formik.values.remove_links === assistant?.metadata?.remove_links &&
    formik.values.split_reply_by_paragraphs ===
      assistant?.metadata?.split_reply_by_paragraphs &&
    formik.values.allow_ai_postpone_messages ===
      assistant?.metadata?.allow_ai_postpone_messages;

  return (
    <>
      <Button onClick={() => setOpen(true)}>Reply format</Button>
      <Modal
        title={<Title text="Reply format" />}
        open={open}
        size="sm"
        toggleOpen={() => setOpen(false)}
        classNameWrapper="p-9"
      >
        <div>
          <p className="text-gray-500 text-[1.2rem] font-medium mb-6">
            You can adjust the assistant's reply format by removing links and
            splitting replies into paragraphs
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="column items-start gap-6 mb-10">
              <Checkbox
                label={"Remove links"}
                className="w-[1.4rem] h-[1.4rem]"
                labelProps={{
                  className:
                    "text-gray-500 font-medium text-[1.2rem] mt-0 ml-3",
                }}
                formik={formik}
                checked={formik.values.remove_links}
                name="remove_links"
              />

              <Checkbox
                label={"Split reply by paragraphs"}
                className="w-[1.4rem] h-[1.4rem]"
                labelProps={{
                  className:
                    "text-gray-500 font-medium text-[1.2rem] mt-0 ml-3",
                }}
                formik={formik}
                checked={formik.values.split_reply_by_paragraphs}
                name="split_reply_by_paragraphs"
              />

              <Checkbox
                label={"Allow AI to postpone messages"}
                className="w-[1.4rem] h-[1.4rem]"
                labelProps={{
                  className:
                    "text-gray-500 font-medium text-[1.2rem] mt-0 ml-3",
                }}
                formik={formik}
                checked={formik.values.allow_ai_postpone_messages}
                name="allow_ai_postpone_messages"
              />
            </div>
            <div className="flex justify-end gap-6">
              <Button
                className="font-semibold text-[1.35rem]"
                variant="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="font-semibold text-[1.35rem]"
                disabled={disabled}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
