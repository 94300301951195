import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  Modal,
  Switcher,
  Title,
} from "../../../../../components";
import { useAIConfigurationState } from "../../../context";
import { DAY_OF_THE_WEEK } from "../../../../../../constants/configuration";
import classNames from "classnames";
import { useFormik } from "formik";

import { TimeBody } from "./TimeBody";

import {
  getSelectedDays,
  getFormattedDays,
  FormattedDays,
  getRestDays,
  getDisabledSetBotTime,
  InitialValues,
} from "./utils";
import { TimezoneBody } from "./TimezoneBody";
import { validationSchema } from "./validationSchema";
import { allTimezones } from "react-timezone-select";
import { useTimezoneSelect } from "react-timezone-select";

const initialValues: InitialValues = {
  days: [],
  timezone: "",
  timeFrom: "",
  timeTo: "",
};

const daysOfTheWeek = DAY_OF_THE_WEEK as { label: Days; value: number }[];

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Kiev": "Kiev",
};

export const SetBotTime = memo(() => {
  const { assistant, handleSaveChanges } = useAIConfigurationState();
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  const [open, setOpen] = useState(false);
  const [selectedWeekDays, setSelectedWeekDays] = useState<FormattedDays[]>([]);

  const [isOpenTimeFrom, setIsOpenTimeFrom] = useState(false);
  const [isOpenTimeTo, setIsOpenTimeTo] = useState(false);
  const [isOpenTimezone, setIsOpenTimezone] = useState(false);

  const [timeonesLength, setTimeonesLength] = useState(0);

  const formattedDays = useMemo(
    () => getFormattedDays(assistant?.bot_time?.days),
    [assistant?.bot_time?.days]
  );
  const selectedDays = useMemo(
    () => getSelectedDays((formattedDays as FormattedDays[]) || []),
    [formattedDays]
  );

  const onSubmit = useCallback(
    (values: InitialValues) => {
      const restDays = getRestDays(values);

      const bot_time = {
        days: {
          ...assistant?.bot_time?.days,
          ...restDays,
        },
        timezone: values.timezone || assistant?.bot_time?.timezone,
      };

      handleSaveChanges({ bot_time });
      setOpen(false);
    },
    [
      assistant?.bot_time?.days,
      assistant?.bot_time?.timezone,
      handleSaveChanges,
    ]
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      timezone: assistant?.bot_time?.timezone || "",
      timeFrom: selectedWeekDays[0]?.value?.start || "",
      timeTo: selectedWeekDays[0]?.value?.end || "",
      days: selectedDays,
    },
    onSubmit,
    validationSchema: validationSchema(options.map((option) => option.value)),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!assistant?.bot_time?.days) return;

    setSelectedWeekDays(selectedDays);
  }, [assistant?.bot_time?.days, selectedDays]);

  const handleDayClick = useCallback(
    (day: Days) => {
      const isExistsDay = formik.values.days.some(
        (selectedDay) => selectedDay.label === day
      );

      if (isExistsDay) {
        if (formik.values.days.length <= 1 && selectedWeekDays.length > 0)
          return;

        formik.setFieldValue(
          "days",
          formik.values.days.filter((selectedDay) => selectedDay.label !== day)
        );
      } else {
        formik.setFieldValue("days", [
          ...formik.values.days,
          { label: day, value: { start: null, end: null } },
        ]);
      }
    },
    [formik, selectedWeekDays.length]
  );

  const handleClickTimeFrom = useCallback(
    (hour: string) => {
      formik.setFieldValue("timeFrom", hour);
      setIsOpenTimeFrom(false);
    },
    [formik]
  );

  const handleClickTimeTo = useCallback(
    (hour: string) => {
      formik.setFieldValue("timeTo", hour);
      setIsOpenTimeTo(false);
    },
    [formik]
  );

  const handleClickTimezone = useCallback(
    (timezone: string) => {
      formik.setFieldValue("timezone", timezone);
      setIsOpenTimezone(false);
    },
    [formik]
  );

  const handleSwitch = useCallback(() => {
    if (formik.values.days.length < 7) {
      const formattedDays = daysOfTheWeek.map((day) => ({
        ...day,
        value: {
          start: formik.values.timeFrom,
          end: formik.values.timeTo,
        },
      }));

      formik.setFieldValue("days", formattedDays);
    } else {
      formik.setFieldValue(
        "days",
        formik.values.days.filter((day) => day.label !== "Sunday")
      );
    }
  }, [formik]);

  const isErrorDays = formik.errors.days && formik.touched.days;

  const disabled = getDisabledSetBotTime({
    formik,
    assistant,
    selectedWeekDays,
  });

  return (
    <>
      <Button onClick={() => setOpen(true)}>Set bot time</Button>
      <Modal
        classNameBody="mb-8"
        title={<Title text="Set bot time" />}
        open={open}
        toggleOpen={() => setOpen(false)}
        size="sm"
        classNameWrapper="p-9"
        actions={
          <div className="flex justify-end gap-6">
            <Button
              className="font-semibold text-[1.35rem]"
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className="font-semibold text-[1.35rem]"
              disabled={disabled}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className="column gap-8">
          {formik.values.days.length < 7 && (
            <div className="column gap-3 pb-5 border-b border-gray-300">
              <p className="body-1 font-medium">Select the days of the week:</p>
              <div className="flex flex-nowrap gap-2">
                {daysOfTheWeek.map((day) => (
                  <div key={day.value} className="m-0 before:hidden basis-full">
                    <Button
                      onClick={() => handleDayClick(day.label)}
                      onBlur={() => formik.setFieldTouched("days", true)}
                      variant="rounded"
                      className={classNames(
                        "w-full rounded-[1rem] py-2 shadow-none",
                        {
                          "border border-purple": formik.values.days.some(
                            (selectedDay) => selectedDay.label === day.label
                          ),
                        }
                      )}
                    >
                      <span
                        className={classNames(
                          "body-2 font-normal text-[1.2rem]",
                          {
                            "text-purple": formik.values.days.some(
                              (selectedDay) => selectedDay.label === day.label
                            ),
                          }
                        )}
                      >
                        {day.label.slice(0, 3)}
                      </span>
                    </Button>
                  </div>
                ))}
              </div>
              {isErrorDays && (
                <p className="error-text">{formik.errors.days as string}</p>
              )}
            </div>
          )}
          <div className="flex items-start gap-4 [&>div]:w-1/2">
            <Dropdown
              className="[&>button>span>span]:text-gray-400 [&>button>span>span]:text-[1.2rem] [&>button]:border-light-black2"
              label="Time-from"
              placeholder={formik.values.timeFrom || "Select time from"}
              open={isOpenTimeFrom}
              onToggle={setIsOpenTimeFrom}
              body={<TimeBody onClick={handleClickTimeFrom} />}
              classNameMenu="px-2"
              name="timeFrom"
              formik={formik}
            />
            <Dropdown
              className="[&>button>span>span]:text-gray-400 [&>button>span>span]:text-[1.2rem] [&>button]:border-light-black2"
              label="Time-to"
              placeholder={formik.values.timeTo || "Select time to"}
              open={isOpenTimeTo}
              onToggle={setIsOpenTimeTo}
              body={<TimeBody onClick={handleClickTimeTo} />}
              classNameMenu="!px-2"
              name="timeTo"
              formik={formik}
            />
          </div>
          <div className="pb-7 border-b border-gray-300">
            <Dropdown
              search
              showBody={timeonesLength > 0}
              open={isOpenTimezone}
              onToggle={setIsOpenTimezone}
              onChangeSearchText={(text) =>
                formik.setFieldValue("timezone", text)
              }
              label="Time zone"
              placeholder={"Select time zone"}
              value={formik.values.timezone}
              className="w-full [&>input]:text-gray-400 [&>input]:placeholder:text-gray-400 [&>input]:border-light-black2"
              body={
                <TimezoneBody
                  onClick={handleClickTimezone}
                  searchValue={formik.values.timezone}
                  setTimeonesLength={setTimeonesLength}
                  options={options}
                />
              }
              name="timezone"
              formik={formik}
            />
          </div>
          <div className="flex items-center justify-between">
            <p className="body-1 font-medium">
              Apply this schedule to all days
            </p>
            <Switcher
              checked={formik.values.days.length === 7}
              onChange={handleSwitch}
            />
          </div>
        </div>
      </Modal>
    </>
  );
});
