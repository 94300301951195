export const Send = ({ width, height }) => {
  return (
    <svg
      className="w-7 h-7"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="#ffffff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3393 7.32013L4.33927 0.320128C3.78676 0.0451374 3.16289 -0.0527612 2.55271 0.03978C1.94252 0.132321 1.37573 0.410798 0.929602 0.837244C0.483474 1.26369 0.179724 1.81735 0.0597636 2.42274C-0.0601964 3.02813 0.00947219 3.65578 0.259271 4.22013L2.65927 9.59013C2.71373 9.71996 2.74177 9.85934 2.74177 10.0001C2.74177 10.1409 2.71373 10.2803 2.65927 10.4101L0.259271 15.7801C0.055971 16.2368 -0.0299735 16.7371 0.00924794 17.2355C0.0484693 17.7339 0.211613 18.2145 0.483853 18.6338C0.756092 19.0531 1.1288 19.3977 1.56809 19.6363C2.00739 19.875 2.49935 20 2.99927 20.0001C3.4675 19.9955 3.92876 19.8861 4.34927 19.6801L18.3493 12.6801C18.8459 12.4303 19.2633 12.0474 19.555 11.5742C19.8466 11.101 20.0011 10.556 20.0011 10.0001C20.0011 9.44424 19.8466 8.89928 19.555 8.42605C19.2633 7.95282 18.8459 7.56994 18.3493 7.32013H18.3393ZM17.4493 10.8901L3.44927 17.8901C3.26543 17.9784 3.059 18.0084 2.85766 17.976C2.65631 17.9436 2.46968 17.8504 2.32278 17.709C2.17589 17.5675 2.07575 17.3845 2.0358 17.1846C1.99585 16.9846 2.018 16.7772 2.09927 16.5901L4.48927 11.2201C4.52021 11.1484 4.54692 11.075 4.56927 11.0001H11.4593C11.7245 11.0001 11.9788 10.8948 12.1664 10.7072C12.3539 10.5197 12.4593 10.2653 12.4593 10.0001C12.4593 9.73491 12.3539 9.48056 12.1664 9.29302C11.9788 9.10549 11.7245 9.00013 11.4593 9.00013H4.56927C4.54692 8.9253 4.52021 8.85184 4.48927 8.78013L2.09927 3.41013C2.018 3.22309 1.99585 3.01568 2.0358 2.8157C2.07575 2.61572 2.17589 2.43273 2.32278 2.29128C2.46968 2.14982 2.65631 2.05666 2.85766 2.02428C3.059 1.9919 3.26543 2.02186 3.44927 2.11013L17.4493 9.11013C17.6131 9.19405 17.7505 9.32154 17.8465 9.47857C17.9425 9.63561 17.9933 9.81608 17.9933 10.0001C17.9933 10.1842 17.9425 10.3647 17.8465 10.5217C17.7505 10.6787 17.6131 10.8062 17.4493 10.8901Z"
        fill="#ffffff"
      />
    </svg>
  );
};
