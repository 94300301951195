import { useSelector } from "react-redux";
import { AccountState, SubscriptionState } from "../types";
import { aiConfigurationApi } from "../../store/api";
import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";

const aiConfigurationSelector = (id: string, assistantId: string) =>
  createSelector(
    (state: any) => state,
    (state) =>
      aiConfigurationApi.endpoints.messages.select({
        id,
        assistantId,
      })(state)
  );

export const useReduxStore = () => {
  const auth = useSelector((state: any) => state.auth);
  const assistant = useSelector((state: any) => state.assistant);
  const dashboard = useSelector((state: any) => state.dashboard);
  const subscription = useSelector(
    (state: any) => state.subscription as SubscriptionState
  );
  const account = useSelector((state: any) => state.account as AccountState);
  const onboarding = useSelector((state: any) => state.onboarding);
  const debug = useSelector((state: any) => state.debug);

  const memoizedAiConfigurationSelector = useMemo(
    () =>
      aiConfigurationSelector(
        auth.user?.account_id,
        assistant?.selectedAssistant?.value
      ),
    [assistant?.selectedAssistant?.value, auth.user?.account_id]
  );

  const aiConfiguration = useSelector(memoizedAiConfigurationSelector);

  return {
    auth,
    assistant,
    dashboard,
    subscription,
    account,
    onboarding,
    debug,
    aiConfiguration,
  };
};
