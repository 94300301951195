import { ThunkWrapper } from "../../helper";
import supabase from "../../../supabase";
import { Subscription } from "../../../redesign/types";

export const fetchSubscriptionByEmail = ThunkWrapper<Subscription, string>(
  "fetch/subscription",
  async (email) => {
    const { data } = await supabase
      .from("subscriptions")
      .select("*")
      .eq("email", email);

    return (data[0] as Subscription) || null;
  }
);
