import React, { useState } from "react";
import styles from "./Chat.styles.module.scss";
import ChatContextProvider from "./context/ChatContextProvider";
import { Outlet } from "react-router-dom";
import ChatList from "./components/ChatList";
import ClientCard from "./components/ClientCard";

const DesktopChatV2 = () => {
  const [isShowClientCard, setIsShowClientCard] = useState(false);

  const [isCurrentChatInList, setIsCurrentChatInList] = useState(false);

  return (
    <ChatContextProvider>
      <div className={styles.chatsContainer}>
        <ChatList
          isShowClientCard={isShowClientCard}
          setIsShowClientCard={setIsShowClientCard}
          onIsCurrentChatInListChange={setIsCurrentChatInList}
        />
        <Outlet />
        <ClientCard
          isShowClientCard={isShowClientCard}
          setIsShowClientCard={setIsShowClientCard}
        />
      </div>
    </ChatContextProvider>
  );
};

export default DesktopChatV2;
