import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { SubscriptionState } from "../../../redesign/types";
import { fetchSubscriptionByEmail } from "./thunks";

const initialState: SubscriptionState = {
  fetching: false,
  data: null,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptionByEmail.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchSubscriptionByEmail.fulfilled, (state, action) => {
      state.error = null;
      state.data = action.payload;
    });
    builder
      .addCase(fetchSubscriptionByEmail.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addMatcher(
        isAnyOf(
          fetchSubscriptionByEmail.fulfilled,
          fetchSubscriptionByEmail.rejected
        ),
        (state) => {
          state.fetching = false;
        }
      );
  },
});

export const subscriptionReducer = subscriptionSlice.reducer;
