import { FC, useEffect, useRef, useState } from "react";
import { MdToHTML } from "./MdToHTML";
import classNames from "classnames";

type Props = {
  content: string;
  maxLength?: number;
};

export const CollapsibleMessage: FC<Props> = ({ content, maxLength = 450 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const contentRef = useRef(null);
  const expandedContentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const needsTruncate = content.length > maxLength;
  const displayedContent = isExpanded
    ? content
    : `${content.substring(0, maxLength)}...`;

  useEffect(() => {
    if (isExpanded && expandedContentRef.current) {
      // Set max height to the full content height when expanded
      setMaxHeight(`${expandedContentRef.current.scrollHeight + 50}px`);
    } else if (!isExpanded && contentRef.current) {
      // Set max height to truncated content height when collapsed
      setMaxHeight(`${contentRef.current.scrollHeight + 50}px`);
    }
  }, [isExpanded, content]);

  return (
    <div className="relative">
      <div
        className={classNames(
          "overflow-hidden transition-all duration-300 max-h-0",
          {}
        )}
        style={{ maxHeight }}
      >
        {isExpanded ? (
          <div ref={expandedContentRef}>
            <MdToHTML content={content} />
          </div>
        ) : (
          <div ref={contentRef}>
            <MdToHTML content={displayedContent} />
          </div>
        )}
      </div>

      {needsTruncate && (
        <button
          onClick={toggleExpand}
          className="my-[0.5rem] border-none bg-none text-accent text-[1.2rem] font-semibold cursor-pointer transition-all duration-300 hover:text-purple"
        >
          {isExpanded ? "Show less 🔼" : "Show more 🔽"}
        </button>
      )}
    </div>
  );
};
