import React, { FC, PropsWithChildren } from "react";
import classNames from "classnames";

const styles = {
  bordered: "border border-gray-300",
  default: "border-none",
};

type Variant = "bordered" | "default";

type Props = {
  variant?: Variant;
} & Required<PropsWithChildren> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const RoundedBox: FC<Props> = ({
  children,
  className,
  variant = "bordered",
  ...props
}) => {
  return (
    <div
      className={classNames(
        "rounded-[1.8rem] w-full h-fit p-6 bg-white",
        styles[variant],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
