import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { toggleDebugMode } from "../../../../../../../store/slices/debugModeSlice";
import { BsStars } from "react-icons/bs";
import Switch from "react-switch";
import { Typography } from "../../../../../../../components";
import userAvatar from "../../../../../../../assets/img/user.png";
import { getChannelIcon } from "../../../../../../../utils";

export const HeaderChatSection = ({
  chatPartner,
  botName,
  pageContext,
  isDebugMode,
  isLoading,
  isSmallScreen,
  handleToggleSwitch,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        "sticky top-0 z-10 bg-light pb-[1rem] mb-4 border-b border-gray-300 flex justify-between items-center gap-[3rem] max-w-[666px]:gap-[1.5rem]",
        pageContext && "flex-wrap"
      )}
    >
      <div className="flex flex-wrap items-center justify-between gap-[1.25rem] w-full max-w-[666px]:gap-[1rem]">
        {/* LEFT PART: client avatar and info */}
        <div className="flex flex-wrap items-center gap-[1.25rem] max-w-[666px]:gap-4 flex-1">
          <img
            src={chatPartner?.client_avatar_url || userAvatar}
            alt="avatar"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = userAvatar;
            }}
            className="w-[5rem] h-[5rem] rounded-[1.2rem] mr-[0.5rem] object-cover max-w-[666px]:w-[3rem] max-w-[666px]:h-[3rem] max-w-[666px]:mr-[1rem]"
          />
          <div className="flex items-center justify-between w-full flex-1 gap-3 max-w-[666px]:gap-[0.8rem]">
            {/* Client info */}
            <div className="flex">
              <div className="flex flex-col gap-2 max-w-[666px]:max-w-[40vw]">
                <div className="flex items-center gap-2 font-medium text-2xl leading-[1.2] max-w-[666px]:text-[1.05rem] max-w-[666px]:leading-[1.4]">
                  {chatPartner?.client_name ||
                    chatPartner?.client_username ||
                    chatPartner?.client_id}
                  {getChannelIcon(chatPartner?.communication_channel)}
                </div>

                {chatPartner?.page_description && (
                  <div className="text-gray-500 font-normal text-[1.3rem] leading-[1.25] max-w-[666px]:text-[1rem]">
                    {chatPartner?.page_description}
                  </div>
                )}
              </div>
            </div>

            {/* Talking with AI */}
            <div className="flex items-center justify-center mr-[1rem] p-[8px] gap-[.5rem] rounded-[12px] bg-gray text-purple text-[1.3rem] leading-[1.25] md:text-[1.25rem] md:gap-[5px] max-w-[666px]:px-[9px] max-w-[666px]:py-[5px] max-w-[666px]:rounded-[8px] max-w-[666px]:text-[1.05rem] max-w-[666px]:gap-[5px]">
              {chatPartner?.needs_operator ? (
                <>
                  <BsStars color="#777ef5" /> <span>AI {botName} is off</span>
                </>
              ) : (
                <>
                  <BsStars color="#777ef5" />{" "}
                  <span>Talking with AI {botName}</span>
                </>
              )}
            </div>
          </div>
        </div>

        {/* RIGHT PART: toggles */}
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Typography variant="caption2">Debug mode</Typography>
            <Switch
              checked={isDebugMode}
              onChange={(checked) => dispatch(toggleDebugMode())}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#777ef5"
              handleDiameter={isSmallScreen ? 14 : 20}
              height={isSmallScreen ? 20 : 24}
              width={isSmallScreen ? 40 : 50}
            />
          </div>
          <div className="flex items-center gap-2">
            <Typography variant="caption2">AI</Typography>
            <Switch
              disabled={isLoading}
              onChange={handleToggleSwitch}
              checked={!chatPartner?.needs_operator}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#777ef5"
              handleDiameter={isSmallScreen ? 14 : 20}
              height={isSmallScreen ? 20 : 24}
              width={isSmallScreen ? 40 : 50}
            />
          </div>
        </div>
      </div>
      {pageContext && <div className="text-[1.25rem]">{pageContext}</div>}
    </div>
  );
};
