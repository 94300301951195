import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

const DashboardEndpoints = {
  base: "/dashboard",
  generalStatistic() {
    return `${this.base}/general-statistic`;
  },
  revenueByChannel() {
    return `${this.base}/revenue-by-channel`;
  },
  clientStatusInCrm() {
    return `${this.base}/client-status-in-crm`;
  },
  ordersByChannel() {
    return `${this.base}/orders-by-channel`;
  },
  messagesByChannel() {
    return `${this.base}/messages-by-channel`;
  },
  messageUsage() {
    return `${this.base}/message-usage`;
  },
  platformUsage() {
    return `${this.base}/platform-usage`;
  },
};

export const fetchGeneralStatistic =
  createAsyncThunk<DashboardGeneralStatistic>(
    "fetch/general-statistic",
    async (_, { rejectWithValue }) => {
      try {
        const response: AxiosResponse<DashboardGeneralStatistic> =
          await axios.get(DashboardEndpoints.generalStatistic());

        return response.data;
      } catch (error) {
        toast.error(error?.response?.data || "Something went wrong");
        return rejectWithValue(error);
      }
    }
  );

export const fetchRevenueByChannel =
  createAsyncThunk<DashboardRevenueByChannel>(
    "fetch/revenue-by-channel",
    async (_, thunkAPI) => {
      try {
        const response = await axios.get(DashboardEndpoints.revenueByChannel());

        return response.data;
      } catch (error) {
        toast.error(error?.response?.data || "Something went wrong");
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

export const fetchClientStatusInCrm = createAsyncThunk<
  DashboardClientStatusInCrm[]
>("fetch/client-status-in-crm", async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<{
      client_statuses: DashboardClientStatusInCrm[];
    }> = await axios.get(DashboardEndpoints.clientStatusInCrm());

    return response.data?.client_statuses || [];
  } catch (error) {
    toast.error(error?.response?.data || "Something went wrong");
    return rejectWithValue(error);
  }
});

export const fetchOrdersByChannel = createAsyncThunk<
  DashboardOrdersByChannel[]
>("fetch/orders-by-channel", async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<{
      orders_by_channel: DashboardOrdersByChannel[];
    }> = await axios.get(DashboardEndpoints.ordersByChannel());

    return response.data?.orders_by_channel || [];
  } catch (error) {
    toast.error(error?.response?.data || "Something went wrong");
    return rejectWithValue(error);
  }
});

export const fetchMessagesByChannel = createAsyncThunk<
  DashboardMessagesByChannel[]
>("fetch/messages-by-channel", async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<{
      messages_by_channel: DashboardMessagesByChannel[];
    }> = await axios.get(DashboardEndpoints.messagesByChannel());

    return response.data?.messages_by_channel || [];
  } catch (error) {
    toast.error(error?.response?.data || "Something went wrong");
    return rejectWithValue(error);
  }
});

export const fetchMessageUsage = createAsyncThunk<DashboardMessageUsage[]>(
  "fetch/message-usage",
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<{
        message_usage: DashboardMessageUsage[];
      }> = await axios.get(DashboardEndpoints.messageUsage());

      return response.data?.message_usage || [];
    } catch (error) {
      toast.error(error?.response?.data || "Something went wrong");
      return rejectWithValue(error);
    }
  }
);

export const fetchPlatformUsage = createAsyncThunk<DashboardPlatformUsage[]>(
  "fetch/platform-usage",
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<{
        platform_usage: DashboardPlatformUsage[];
      }> = await axios.get(DashboardEndpoints.platformUsage());

      return response.data?.platform_usage || [];
    } catch (error) {
      toast.error(error?.response?.data || "Something went wrong");
      return rejectWithValue(error);
    }
  }
);
