import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconArrowUpRight: FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33325 13H5.88898C8.91916 13 11.6893 11.288 13.0444 8.57771L13.8333 7M13.8333 7L15.3333 9.5M13.8333 7H10.8333M5.33325 1H15.3333C17.5424 1 19.3333 2.79086 19.3333 5V15C19.3333 17.2091 17.5424 19 15.3333 19H5.33325C3.12411 19 1.33325 17.2091 1.33325 15V5C1.33325 2.79086 3.12411 1 5.33325 1Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
