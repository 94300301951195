import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { fetchKnowledgeBase } from "./thunks";
import { KNOWLEDGEBASE } from "../../../constants/knowledgeBaseItems";
import { KnowledgeBaseIntegrationDB } from "../../../types/knowledgeBase";

interface KnowledgeBaseState {
  listOfKnowledgeBase: KnowledgeBaseIntegrationDB[];
  status: string;
  error: string | null;
}

const initialState: KnowledgeBaseState = {
  listOfKnowledgeBase: [],
  status: "",
  error: null,
};

export const knowledgeBaseSlice = createSlice({
  name: "knowledgeBase",
  initialState,
  reducers: {
    addNewKnowledgeBase: (
      state,
      action: PayloadAction<KnowledgeBaseIntegrationDB>
    ) => {
      state.listOfKnowledgeBase = [
        ...state.listOfKnowledgeBase,
        action.payload,
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKnowledgeBase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchKnowledgeBase.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.listOfKnowledgeBase = action.payload;
      })
      .addCase(fetchKnowledgeBase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectKnowledgeBaseState = (state: any) => state.knowledgeBase;

export const _listOfKnowledgeBase = createSelector(
  [selectKnowledgeBaseState],
  (state) => {
    try {
      if (!state.listOfKnowledgeBase.length) return [];

      const items = state.listOfKnowledgeBase?.map(
        ({ knowledge_base }) => knowledge_base
      );

      const result = [...new Set(items)].map((el) => {
        const title = Number.isFinite(el) ? `Knowledge base №${el}` : el;
        const item = {
          id: el,
          value: el,
          title,
          items: [...KNOWLEDGEBASE],
        };

        if (
          state.listOfKnowledgeBase.find(
            ({ knowledge_base }) => knowledge_base === el
          )?.access_token
        ) {
          item.items = [
            ...state.listOfKnowledgeBase
              ?.filter(({ knowledge_base }) => knowledge_base === el)
              .map((el) => {
                const obj = KNOWLEDGEBASE.find(
                  (item) => el.integration === item.integration
                );
                if (obj?.icon)
                  return { ...el, connected: true, icon: obj?.icon };
                return { ...el, connected: true };
              }),
            ...KNOWLEDGEBASE,
          ];
        }

        return item;
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const knowledgeBaseReducer = knowledgeBaseSlice.reducer;

export const { addNewKnowledgeBase } = knowledgeBaseSlice.actions;
