import React, { FC } from "react";
import { IconProps } from "../../types";

export const IconNumberOfSales: FC<IconProps> = ({
  width = 18,
  height = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 5.5C6.5 9 11.5 9 12 5.5M15.8036 3.09011L16.9468 10.4988C17.3085 12.8426 15.7803 15 13.7585 15H4.24145C2.21968 15 0.691538 12.8426 1.0532 10.4988L2.19644 3.09011C2.38321 1.87977 3.27787 1 4.32194 1H13.6781C14.7221 1 15.6168 1.87977 15.8036 3.09011Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
