import { Keys, Values } from "../types";

export const screenSizes = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export type ScreenSizesKeys = Keys<typeof screenSizes>;
export type ScreenSizesValues = Values<typeof screenSizes>;
