import React, { FC } from "react";
import { Modal, Button, Dropdown } from "../../../redesign/components";
import { Loader } from "../../../components/Loader";

interface OpenCartLanguageModalProps {
  open: boolean;
  toggleOpen: () => void;
  languagesOptions: any[];
  loadingLanguages: boolean;
  selectedLanguage: any;
  setSelectedLanguage: (lang: any) => void;
  onConfirm: () => void;
}

export const OpenCartLanguageModal: FC<OpenCartLanguageModalProps> = ({
  open,
  toggleOpen,
  languagesOptions,
  loadingLanguages,
  selectedLanguage,
  setSelectedLanguage,
  onConfirm,
}) => {
  const closeDropdownHack = () => {
    setTimeout(() => {
      const outside = document.body;
      if (outside) {
        const evt = new MouseEvent("mousedown", {
          bubbles: true,
          cancelable: true,
        });
        outside.dispatchEvent(evt);
      }
    }, 50);
  };

  return (
    <Modal
      open={open}
      toggleOpen={toggleOpen}
      title="Select language"
      actions={
        <div className="flex justify-center">
          <Button
            variant="primary"
            onClick={onConfirm}
            className="w-1/3 mx-auto"
            disabled={!selectedLanguage}
          >
            Ok
          </Button>
        </div>
      }
    >
      {loadingLanguages ? (
        <div className="flex justify-center items-center w-full h-20">
          <Loader height={40} width={40} />
        </div>
      ) : languagesOptions.length ? (
        <div className="flex flex-col items-center w-full p-4">
          <Dropdown
            placeholder={
              selectedLanguage ? selectedLanguage.label : "Select language"
            }
            body={languagesOptions.map((option: any) => (
              <div
                key={option.value}
                onClick={() => {
                  setSelectedLanguage(option);

                  closeDropdownHack();
                }}
                className="cursor-pointer p-2 text-xl hover:bg-purple-light hover:text-white rounded-lg w-full text-center"
              >
                {option.label}
              </div>
            ))}
            actions={<></>}
            className="w-full border border-gray-300 rounded-lg p-2 text-xl bg-white"
          />
        </div>
      ) : (
        <div className="flex justify-center items-center w-full p-4">
          <p className="text-gray-500 text-lg">Languages cannot be displayed</p>
        </div>
      )}
    </Modal>
  );
};
