import * as Yup from "yup";

export const validationSchema = (timezoneOptions: string[]) => {
  return Yup.object().shape({
    days: Yup.array()
      .min(1, "At least one day must be selected")
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.object().shape({}),
        })
      ),
    timezone: Yup.string()
      .required("Timezone is required")
      .oneOf(timezoneOptions, "Timezone is invalid"),
    timeFrom: Yup.string().required("Time from is required"),
    timeTo: Yup.string().required("Time to is required"),
  });
};
