import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getData } from "../../../../../service/supabase";
import { useReduxStore } from "../../../../hooks";
import { Button, Dropdown } from "../../../../components";
import { useAIConfigurationState } from "../../context";

type ToolkitOption = {
  value: string;
  label: string;
};

export const KnowledgeBase = () => {
  const [assistantToolkit, setAssistantToolkit] = useState<ToolkitOption[]>([]);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] =
    useState<ToolkitOption | null>(null);
  const [open, setOpen] = useState(false);

  const { assistant, handleSaveChanges } = useAIConfigurationState();

  const { auth } = useReduxStore();

  useEffect(() => {
    if (!assistant) return;

    setSelectedKnowledgeBase({
      value: assistant.knowledge_base,
      label: assistant.knowledge_base,
    });
  }, [assistant]);

  const getAssistantToolkit = useCallback(async () => {
    try {
      const { data } = await getData(
        "integrations",
        "*",
        "account_id",
        auth?.user?.account_id
      );

      if (!data) return;

      const items = data
        ?.filter(
          ({ is_knowledge_base }: { is_knowledge_base: boolean }) =>
            is_knowledge_base
        )
        .map(
          ({ knowledge_base }: { knowledge_base: string }) => knowledge_base
        );
      const result = [...new Set(items)].map((el) => {
        const label = Number.isFinite(el) ? `Knowledge base №${el}` : el;
        return {
          value: el,
          label,
        };
      });

      setAssistantToolkit(result as ToolkitOption[]);
    } catch ({ message }: any) {
      toast.error(message as string);
    }
  }, [auth?.user?.account_id]);

  useEffect(() => {
    if (!auth?.user?.account_id) return;

    getAssistantToolkit();
  }, [auth?.user?.account_id, getAssistantToolkit]);

  const handleSaveKnowledgeBase = (knowledgeBase: ToolkitOption) => {
    if (knowledgeBase.value === assistant?.knowledge_base) return;

    handleSaveChanges({ knowledge_base: knowledgeBase.value });
    setOpen(false);
  };

  return (
    <Dropdown
      open={open}
      onToggle={setOpen}
      placeholder={selectedKnowledgeBase?.label || "Default"}
      label="Knowledge base"
      className="min-h-full grow [&>button]:px-7 [&>button]:!border-light-black2 [&>button]:min-h-full [&>button]:grow [&>button]:leading-[170%] [&>button]:hover:!border-purple [&>button]:hover:!text-purple"
      body={
        assistantToolkit.length > 0 ? (
          <div className="column gap-2">
            {assistantToolkit.map((communication) => (
              <Button
                key={communication.value}
                className="rounded-[0.7rem] hover:!bg-purple hover:!text-white"
                variant="rounded"
                onClick={() => handleSaveKnowledgeBase(communication)}
              >
                {communication.label}
              </Button>
            ))}
          </div>
        ) : (
          <div className="center">
            <p>No data</p>
          </div>
        )
      }
    />
  );
};
