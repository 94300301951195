import React, { useState } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import styles from "./ChannelDropdown.module.scss";
import makeAnimated from "react-select/animated";
import { customSelectStyles } from "../../../../../../constants/general";
import { Channels } from "../../../../../../assets/icons/Channels";

import { CHANNELS } from "../../../../../../constants/channels";

// Custom ValueContainer to display the number of selected channels
const CustomValueContainer = ({ children, ...props }) => {
  const { hasValue, getValue } = props;
  const selectedChannels = getValue();
  const count = selectedChannels.length;

  return (
    <components.ValueContainer {...props}>
      {hasValue ? (
        <div className={styles.selectedCount}>
          Channels <span>{count}</span>
        </div>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

// Custom MenuList with Apply and Clear all buttons
const CustomMenuList = (props) => {
  const { children, selectProps } = props;

  return (
    <components.MenuList {...props}>
      <div className={styles.menuList}>
        {children}
        <div className={styles.buttonContainer}>
          <button
            className={styles.applyButton}
            onClick={selectProps.onApply} // Calls handleApplyAndClose
            disabled={!selectProps.value || selectProps.value.length === 0}
          >
            Apply
          </button>
          <button
            className={styles.cleanAllButton}
            onClick={selectProps.onCleanAll} // Calls handleCleanAllAndClose
            disabled={!selectProps.value || selectProps.value.length === 0}
          >
            Clear all
          </button>
        </div>
      </div>
    </components.MenuList>
  );
};

// Custom Option to display channel icons only
const IconOption = (props) => {
  const { isSelected, data } = props;
  return (
    <components.Option {...props}>
      <div
        className={classNames(styles.iconWrapper, {
          [styles.selected]: isSelected,
        })}
      >
        {React.cloneElement(data.label, {
          size: 24,
          width: 24,
          height: 24,
        })}
      </div>
    </components.Option>
  );
};

// Custom MultiValue to display selected channels as icons
const IconMultiValue = (props) => (
  <components.MultiValue {...props}>
    <div className={styles.iconWrapper}>
      {React.cloneElement(props.data.label, {
        size: 24,
        width: 24,
        height: 24,
      })}
    </div>
  </components.MultiValue>
);

export const ChannelDropdown = ({
  selectedChannels,
  onSelectChannel,
  onApply,
  onCleanAll,
  customComponents,
}) => {
  const animatedComponents = makeAnimated();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleApplyAndClose = () => {
    if (selectedChannels.some((channel) => channel.value === "all")) {
      onApply("all");
    } else {
      onApply();
    }
    setMenuIsOpen(false);
  };

  const handleCleanAllAndClose = () => {
    onCleanAll();
    setMenuIsOpen(false);
  };

  // Add an "All" option with text without an icon
  const allOption = {
    value: "all",
    label: <div className={`${styles.allOption}`}>All</div>,
  };

  // Add "All" to the channel list without an icon
  const optionsWithIcons = [
    allOption,
    ...CHANNELS.map((channel) => ({
      value: channel.value,
      label: (
        <div className={styles.channelOption}>
          {React.cloneElement(channel.icon, { size: 24 })}
        </div>
      ),
      isDisabled: channel.isDisabled || false,
    })),
  ];

  const handleSelect = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      // If "All" is selected, set only "All" as selected
      onSelectChannel([allOption]);
    } else {
      // If other channels are selected, make sure "All" is not selected
      onSelectChannel(selectedOptions);
    }
  };

  return (
    <div className={styles.filterItem}>
      <Channels width="2.5rem" height="2.5rem" />
      {selectedChannels.length === 0 && <h4>Channels</h4>}
      <Select
        isMulti
        options={optionsWithIcons}
        value={selectedChannels}
        isSearchable={false}
        onChange={handleSelect}
        closeMenuOnSelect={false} // Leave the menu open after selection
        hideSelectedOptions={false}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        components={{
          ...customComponents,
          ...animatedComponents,
          Option: IconOption,
          MultiValue: IconMultiValue,
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer,
        }}
        styles={customSelectStyles}
        placeholder="Channels"
        isClearable={false} // Disallow clearing all selections via the `x` button
        onApply={handleApplyAndClose}
        onCleanAll={handleCleanAllAndClose}
      />
    </div>
  );
};
