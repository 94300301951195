import React, { FC } from "react";
import { Modal } from "../../../redesign/components";
import { Button } from "../../../redesign/components";

export interface ConsentModalProps {
  open: boolean;
  toggleOpen: () => void;
  onConsent: (consent: boolean) => void;
}

export const ConsentModalKnowledgeBase: FC<ConsentModalProps> = ({
  open,
  toggleOpen,
  onConsent,
}) => {
  return (
    <Modal
      open={open}
      toggleOpen={toggleOpen}
      title="User Consent Required"
      classNameBody="text-2xl leading-relaxed text-gray-700"
      actions={
        <div className="flex w-full gap-3">
          <Button
            className="w-full mt-3 px-12 py-4 rounded-[0.5rem] bg-gradient-blue-to-purple-angled text-white uppercase font-semibold text-2xl leading-[1.7rem] shadow-md transition-all duration-200 hover:shadow-lg"
            onClick={() => onConsent(true)}
          >
            Yes
          </Button>
          <Button
            className="w-full mt-3 px-12 py-4 rounded-[0.5rem] bg-gradient-blue-to-purple-angled text-white uppercase font-semibold text-2xl leading-[1.7rem] shadow-md transition-all duration-200 hover:shadow-lg"
            onClick={() => onConsent(false)}
          >
            No
          </Button>
        </div>
      }
    >
      <div className="text-2xl leading-[1.6] text-gray-700">
        <p>
          In order to integrate with our services, we require access to certain
          documents in your Google Drive. We use advanced third-party AI models
          to process this data exclusively for reading purposes and to enhance
          conversations with your clients.
        </p>
        <p className="mt-4 font-semibold">
          Before proceeding, we need your explicit consent for the following:
        </p>
        <p>
          1. Allow us to access specific Google Drive documents that you
          designate as necessary for the integration.
        </p>
        <p>
          2. Permit the use of this data by third-party AI models exclusively
          for reading and facilitating conversations with your clients, and for
          no other purpose.
        </p>
        <p className="mt-4 font-semibold">We assure you that:</p>
        <p>- Only the data in the documents you specify will be accessed.</p>
        <p>
          - Your data will be used solely for the purposes of enhancing client
          interactions and not for any other reason.
        </p>
        <p>- You can withdraw your consent and restrict access at any time.</p>
        <p className="mt-4 font-bold">
          Do you agree to these terms and grant us permission to access and use
          your data as described above?
        </p>
      </div>
    </Modal>
  );
};
